import Modal from "@/styles/Modal"
import { getLocalStorage, setLocalStorage } from "@/utils/localStorage"
import { createContext, useContext, useEffect, useState } from "react"

const Context = createContext({
  speechRecognitionIsSupported: false,
  willShowMicPermissionsModal: false,
  setHasInteractedWithMic: (hasInteractedWithMic: boolean) => {},
  startSpeechRecognition: (langCode: string) => {},
  stopSpeechRecognition: () => {},
  clearSpeechRecognition: () => {},
  interimTranscript: "",
  finalTranscript: "",
  recognitionState: "waiting",
})

// https://www.google.com/intl/en/chrome/demos/speech.html
export default ({ children }) => {
  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
  const speechRecognitionIsSupported = !!SpeechRecognition

  const [recognitionObject, setRecognitionObject] = useState<SpeechRecognition>()
  const [recognitionState, setRecognitionState] = useState("waiting")
  const [finalTranscript, setFinalTranscript] = useState("")
  const [interimTranscript, setInterimTranscript] = useState("")

  // show permissions modal based on localhost flag
  // this is the Firefox way (even though Firefox doesn't support speech recognition yet)
  // because unfortunately it's the only way to guarantee it works in all browsers, incl Safari
  // https://github.com/mozilla/standards-positions/issues/19#issuecomment-370158947
  // if we have not asked for permissions in this browser before,
  // ask once, and remember that we asked in localStorage
  const [hasPromptedForMicPermissions, setHasPromptedForMicPermissions] = useState(
    getLocalStorage("protolang-has-prompted-for-mic-permissions")
  )

  // TODO - progressive enhancement to hide the permissions modal if we don't need it
  // would only work on chrome, but that's OK
  // before we build it, we need to handle the safari case of working on page load
  // but not receiving any onchange events to keep it updated
  // this will be one of: prompt, granted, denied
  // if this state stays null, our browser doesn't support mic permissions
  // const [knownMicPermissionState, setKnownMicPermissionState] = useState(null)
  // console.log("🎙️ knownMicPermissionState:", knownMicPermissionState)

  // either way, regardless of whether we know the permission or we're guessing,
  // we shouldn't show the mic permissions modal until
  // the user has interacted with the mic in some way
  // this is purely a UX nice-thing-to-do, rather than spam people on page load
  // these are exported to child components to set how they see fit
  const [hasInteractedWithMic, setHasInteractedWithMic] = useState(false)

  const willShowMicPermissionsModal = !hasPromptedForMicPermissions && !hasInteractedWithMic
  const showMicPermissionsModal = !hasPromptedForMicPermissions && hasInteractedWithMic

  const setUpRecognition = () => {
    if (!speechRecognitionIsSupported) return

    var recognition = new SpeechRecognition()
    recognition.continuous = true
    recognition.interimResults = true

    recognition.onstart = () => {
      setRecognitionState("listening")
    }

    recognition.onresult = event => {
      setInterimTranscript("")
      if (typeof event.results == "undefined") {
        recognition.onend = null
        recognition.stop()
        alert("Sorry, your browser does not support speech recognition. Try Chrome?")
        return
      }
      for (var i = event.resultIndex; i < event.results.length; ++i) {
        const newResult = event.results[i]
        if (newResult.isFinal) {
          const text = newResult[0].transcript
          setFinalTranscript(ft => ft + " " + text)
        } else {
          const text = newResult[0].transcript
          setInterimTranscript(it => it + " " + text)
        }
      }
    }

    recognition.onerror = event => {
      if (event.error == "no-speech") {
        setRecognitionState("error_no_speech")
      }
      if (event.error == "audio-capture") {
        setRecognitionState("error_no_microphone")
      }
      if (event.error == "not-allowed") {
        setRecognitionState("error_not_allowed")
      }
    }

    recognition.onend = () => {
      setRecognitionState("waiting")
    }

    setRecognitionObject(recognition)
  }

  // useEffect( () => {
  //   let permissionStatus
  //   const checkForKnownMicPermissions = async () => {
  //     try {
  //       permissionStatus = await navigator?.permissions?.query({ name: 'microphone' })
  //       console.log("🎙️ Known mic permission state is " + permissionStatus.state)
  //       setKnownMicPermissionState(permissionStatus.state)
  //       permissionStatus.onchange = function () {
  //         // this seems to only work in Chrome, not Safari?
  //         // https://stackoverflow.com/questions/72002932/why-onchange-function-not-working-in-safari
  //         // This means permissions state is not really known at all, is it...
  //         console.log("🎙️ Known mic permission state changed to " + this.state)
  //         setKnownMicPermissionState(this.state)
  //       }
  //     } catch (error) {
  //       console.warn(`Could not read mic permission state (probably Firefox or maybe Safari). That's OK PassThrough, we have our fallback`, error)
  //     }
  //   }
  //   checkForKnownMicPermissions()
  //   return () => permissionStatus = null
  // }, [])

  // when the user grants mic permissions, make them click the mic again
  // useEffect(() => {
  //   console.log('🎙️ mic permissions changed', knownMicPermissionState)
  //   stopSpeechRecognition()
  // }, [knownMicPermissionState])

  useEffect(setUpRecognition, [])

  // trigger browser permissions prompt alongside ours
  useEffect(() => {
    if (!showMicPermissionsModal) return
    navigator.mediaDevices.getUserMedia({ audio: true })
  }, [showMicPermissionsModal])

  useEffect(() => {
    console.log("🎙️ mic permissions state:", {
      hasPromptedForMicPermissions,
      hasInteractedWithMic,
    })
  }, [hasPromptedForMicPermissions, hasInteractedWithMic])

  const startSpeechRecognition = langCode => {
    if (!speechRecognitionIsSupported) return
    if (willShowMicPermissionsModal) return
    if (recognitionState !== "listening") {
      recognitionObject.lang = langCode
      console.log("👂 STARTING speech recognition")
      setFinalTranscript("")
      setInterimTranscript("")
      recognitionObject.start()
    }
  }

  const stopSpeechRecognition = () => {
    if (recognitionState === "listening") {
      console.log("👂 STOPPING speech recognition")
      recognitionObject.stop()
    }
  }

  const clearSpeechRecognition = () => {
    setFinalTranscript("")
    setInterimTranscript("")
  }

  const exposed = {
    speechRecognitionIsSupported,
    willShowMicPermissionsModal,
    setHasInteractedWithMic,
    startSpeechRecognition,
    stopSpeechRecognition,
    clearSpeechRecognition,
    interimTranscript: interimTranscript.trim(),
    finalTranscript: finalTranscript.trim(),
    recognitionState,
  }

  return (
    <Context.Provider value={exposed}>
      <Modal
        isOpen={showMicPermissionsModal}
        title="Grant microphone access"
        icon="mic"
        width="400px"
        hideCloseButton
      >
        {/* TODO - image that shows what the browser prompt looks like, and where to click? */}
        <p>Protolang needs to record your voice to test your pronunciation.</p>
        <button
          className="button"
          data-testid="acknowledge-microphone-permission-request"
          onClick={() => {
            // close right now
            setHasPromptedForMicPermissions(true)
            // set for next time
            setLocalStorage("protolang-has-prompted-for-mic-permissions", true)
          }}
        >
          OK
        </button>
      </Modal>

      {children}
    </Context.Provider>
  )
}

export const useSpeechRecognition = () => useContext(Context)
