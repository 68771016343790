import { Helmet } from "react-helmet-async"

import { MainWrapper, OneCol } from "@/styles/Layout"

export default () => {
  return (
    <MainWrapper>
      <Helmet>
        <title>Contact | Protolang</title>
      </Helmet>
      <OneCol>
        <h1>Contact us</h1>

        {/* <h2>Support</h2>
      <p>Need help using Protolang? Try our <Link to="/support">support page (work in progress)</Link>.</p>

      <h2>Other questions</h2> */}
        <p>
          Please email <a href="mailto:sam@directedworks.com">sam@directedworks.com</a>.
        </p>
      </OneCol>
    </MainWrapper>
  )
}
