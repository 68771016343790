import { useState } from "react"
import ReactMarkdown from "react-markdown"
import { Link, useNavigate } from "react-router-dom"
import styled from "styled-components/macro"

import { useLanguage } from "@/context/language"
import { useUser } from "@/context/user"
import { AvatarCharacter } from "@/styles/Avatars"
import { TwoColumns } from "@/styles/Layout"
import OnboardingStep from "@/views/_components/OnboardingStep"
import { ConversationMessages, ConversationSituations, Conversations } from "@/types/schema"
import { createConversation, getResponse } from "@/utils/conversationHelpers"
import { fromNow } from "@/utils/date"
import { logError } from "@/utils/error"
import { getLocalStorage } from "@/utils/localStorage"
import Definable from "@/views/_components/Definable"
import SoundToggle from "@/views/_layout/components/SoundToggle"
import JoinWaitlist from "@/views/account/JoinWaitlist"
import ConversationBanner from "@/views/conversations/ConversationBanner"
import ConversationInput from "@/views/conversations/ConversationInput"
import { default as ConversationMessagesComponent } from "@/views/conversations/ConversationMessages"
import EmotionFeedback from "@/views/feedback/EmotionFeedback"

const CONVO_MESSAGE_LIMIT = 10

interface ConversationItemProps {
  situation: ConversationSituations
  conversation?: Conversations
  conversationMessages?: ConversationMessages[]
}

export default ({ conversation, conversationMessages, situation }: ConversationItemProps) => {
  const { userId, userProfile } = useUser()
  const { startingLanguage, currentLanguage, currentUserLanguage, contentStrings } = useLanguage()

  const navigate = useNavigate()

  const [isSendingMessage, setIsSendingMessage] = useState(false)
  const [responderIsTyping, setResponderIsTyping] = useState(false)
  const [convoLoadedAt, _] = useState(new Date())
  const [showSoundOptions, setShowSoundOptions] = useState(false)
  const [isCreatingConversation, setIsCreatingConversation] = useState(false)

  const override = getLocalStorage("protolang-temporary-conversation-limit-override")
  const hitConvoMessageLimit =
    conversationMessages && conversationMessages.length >= CONVO_MESSAGE_LIMIT && !override
  // TODO - don't just limit to this conversation, limit to all conversations for this user today

  const isConvoCreator = conversation?.created_by === userId
  // const shareUrl = window.location.href
  // const shareTitle = `${situation?.name_eng || 'Check this out'}: my ${currentLanguage.name_eng} conversation with AI`

  const startConversation = async () => {
    if (!userId) return navigate("/signup")
    try {
      setIsCreatingConversation(true)
      const proficiencyLevel = currentUserLanguage?.proficiency_level || 0
      const conversation = await createConversation({
        currentLanguage,
        proficiencyLevel,
        situation,
      })
      getResponse({
        startingLanguage,
        currentLanguage,
        currentUserLanguage,
        conversation,
        situation,
        userGender: userProfile?.refer_to_as_gender,
      })
      navigate(`/${currentLanguage.slug_eng}/conversations/${conversation.random_slug}`)
    } catch (error) {
      setIsCreatingConversation(false)
      logError("start a conversation", error)
    }
  }

  const friendWord =
    (userProfile?.genderPreference === "male" && contentStrings?.friend?.male) ||
    contentStrings?.friend?.female ||
    "friend"

  const onboardingModalContent = (
    <>
      <h1>
        Welcome to your first challenge, <Definable wordString={friendWord} />!
      </h1>
      <p>
        I have a strange request...{" "}
        <span style={{ fontWeight: "bold" }}>
          I want you to speak some bad {currentLanguage.name_eng}
        </span>
        .
      </p>
      <p>Why "bad"? Because the only way to get good is to practice.</p>
      <p>There's no punishment for making mistakes here. Just have fun!</p>
      {/* <p>(Nervous? Don't worry, I'll show you how to cheat later)</p> */}
      {/* <p>But first, your assignment...</p> */}
      <br />
    </>
  )

  return (
    <ConvoWrapper>
      <OnboardingStep
        delaySeconds={0}
        popoverContent={onboardingModalContent}
        buttonText={"Sono pronto"}
        modal={true}
        flagName="has_completed_chat_welcome_onboarding"
      />

      <ConversationBanner situation={situation} />
      <TwoColumns gapForce="5vw" cols="3fr 2fr" $tall $maxWidth="1200px">
        <ConversationMessageBox>
          <ConversationMessageBoxInner>
            <ConversationMessageHeaderWrapper>
              Chat with {situation?.character_id?.name_default || "AI"}
              {" · "}
              started {fromNow(conversation?.created_at)}
            </ConversationMessageHeaderWrapper>
            <ConversationMessagesComponent
              conversation={conversation}
              conversationMessages={conversationMessages}
              isSendingMessage={isSendingMessage}
              responderIsTyping={responderIsTyping}
              situation={situation}
              convoLoadedAt={convoLoadedAt}
            />
            {hitConvoMessageLimit ? (
              <ConversationUpgradeWrapper>
                {/* <Icon name="hourglass_bottom" size="large" /> */}
                <div style={{ height: ".5rem" }} />
                <h3>You've hit your message limit for this conversation</h3>
                <p>
                  Sorry about that—we can only support so many chats during our free preview phrase.
                  We'll email you when we launch our unlimited plan!
                </p>
                <br />
                {/* <p><Upgrade cta="Sign up for the pro plan waitlist" /> to hear when we launch</p> */}
                {/* <ShareLink title={shareTitle} url={shareUrl} /> */}
              </ConversationUpgradeWrapper>
            ) : (
              <ConversationInput
                conversationMessages={conversationMessages}
                isSendingMessage={isSendingMessage}
                setIsSendingMessage={setIsSendingMessage}
                responderIsTyping={responderIsTyping}
                setResponderIsTyping={setResponderIsTyping}
                conversation={conversation}
                situation={situation}
                isConvoCreator={isConvoCreator}
              />
            )}
          </ConversationMessageBoxInner>
          {!conversation && (
            <PromoWrapper>
              <PromoInner>
                <AvatarCharacter $width="8rem">
                  <img
                    src={`/images/conversation_avatars/victoruler/${situation?.character_id?.avatar_file}`}
                    alt={situation?.character_id?.name}
                  />
                </AvatarCharacter>
                <h3>Ready to chat with {situation?.character_id?.name_default || "AI"}?</h3>
                <br />
                {!userId ? (
                  <div>
                    <hr />
                    <h3>Create an account to start this conversation</h3>
                    <p style={{ fontSize: "var(--s)" }}>We require accounts to prevent abuse</p>
                    <JoinWaitlist sayContinue />
                  </div>
                ) : (
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <button
                      type="button"
                      className="button button-large button-primary"
                      onClick={startConversation}
                      disabled={isCreatingConversation}
                      data-testid="conversation-situation-create"
                    >
                      {isCreatingConversation ? "Creating conversation..." : "Start conversation →"}
                    </button>
                    <p style={{ fontSize: "var(--s)", margin: "0.5rem 0 1rem" }}>
                      Free preview · Speak or type your answers
                    </p>
                  </div>
                )}
              </PromoInner>
            </PromoWrapper>
          )}
        </ConversationMessageBox>
        <div>
          <br />
          <h2>{situation?.name_eng}</h2>
          <br />

          <OnboardingStep
            popoverContent={
              <>
                <h3>Here's your mission</h3>
                <p>Don't be afraid to use your imagination!</p>
              </>
            }
            delaySeconds={0}
            buttonText={"OK"}
            overlay={true}
            side={"left"}
            flagName="has_completed_situation_onboarding"
            dependsOn={["has_completed_chat_welcome_onboarding"]}
          >
            <div>
              <h4>The scene</h4>
              <ReactMarkdown>
                {situation?.user_summary_eng ||
                  `Try having a real conversation in ${currentLanguage.name_eng}!`}
              </ReactMarkdown>
              <br />
              <h4>Your goal</h4>
              <p style={{ fontWeight: "bold" }}>{situation?.user_goal_eng}</p>
            </div>
          </OnboardingStep>

          {userId && isConvoCreator && (
            <>
              <br />
              <button onClick={() => setShowSoundOptions(!showSoundOptions)}>
                {showSoundOptions ? "▼" : "▶"} Sound options
              </button>
              {showSoundOptions && (
                <div style={{ margin: ".5rem" }}>
                  <SoundToggle />
                </div>
              )}
            </>
          )}

          {/* TODO - allow passing a conversation id to ReportIssue */}
          {/* anon query doesn't return this data yet */}
          {/* by { (conversation?.created_by?.username || conversation?.created_by?.first_name) ? <Link to={`/u/${conversation.created_by.username}`}>{conversation?.created_by?.username || conversation?.created_by?.first_name}</Link> : 'Anonymous' } */}
          {/* <div>
          <div style={{display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'center'}}>
            <div>
              <div style={{fontSize: 'var(--s)'}}>
                <ProfileFollow userProfile={conversation?.created_by} />
                {' · '}
                <ReportIssue conversation={conversation.id} />
              </div>
            </div>
          </div>
        </div>
        <br />
        <br /> */}

          {!isConvoCreator && !userId && conversationMessages?.length && (
            <>
              <div
                style={{
                  padding: "1.5rem",
                  border: "1px solid",
                  boxShadow: "2px 2px",
                  margin: "2rem 0",
                }}
              >
                <h2>Want to try your own {currentLanguage.name_eng} conversation?</h2>
                <p>Try our AI language chat for free. Options for every skill level.</p>
                <Link className="button button-primary" to={`/${currentLanguage.slug_eng}`}>
                  Choose a prompt →
                </Link>
              </div>
            </>
          )}

          {/* <div style={{ margin: '2rem 0 0' }}>
            <h4>Share this prompt</h4>
            <ShareLink title={shareTitle} url={shareUrl} />
          </div> */}
          {conversationMessages?.length > 1 && <EmotionFeedback conversation={conversation} />}
        </div>
      </TwoColumns>
    </ConvoWrapper>
  )
}

const ConvoWrapper = styled.div``
const ConversationMessageBox = styled.div`
  flex: 1;
  margin: -1rem 0rem -1rem 0;
  border: 1px solid;
  box-shadow: 2px 2px var(--shadow);
  background: white;
  z-index: 100;
  min-height: 600px;
  position: relative;

  @media (max-width: 800px) {
    margin: 0;
    border: none;
    box-shadow: none;
    background: none;
    min-height: 0;
  }
`
const ConversationMessageBoxInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`
const PromoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: hsla(0, 0%, 100%, 0.4);
  backdrop-filter: blur(2px);
`
const PromoInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 auto;
  background: radial-gradient(
    closest-corner,
    hsla(0, 0%, 100%, 0.99),
    hsla(0, 0%, 100%, 0.99) 30%,
    hsla(0, 0%, 100%, 0.6)
  );
  z-index: 100;
`
const ConversationMessageHeaderWrapper = styled.div`
  padding: 0.5rem 1rem;
  border-bottom: 1px solid;
  display: flex;
  font-size: var(--s);
  align-items: center;
  gap: 0.25rem;

  @media (max-width: 800px) {
    padding: 1rem 0;
    border-bottom: none;
  }
`
const ConversationUpgradeWrapper = styled.div`
  text-align: center;
`
