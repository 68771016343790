import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"

import Accordion from "@/styles/Accordion"
import { MainWrapper, OneCol } from "@/styles/Layout"
import LoomVideo from "@/views/_components/LoomVideo"
import { HeroWrapper, StepBlock, StepBlockWrapper } from "@/styles/Marketing"
import ArrowCallout from "@/views/_components/ArrowCallout"

export default () => {
  return (
    <MainWrapper>
      <Helmet>
        <title>Contribute | Protolang</title>
      </Helmet>
      <OneCol $cool $maxWidth="40rem">
        <HeroWrapper $small>
          <div>
            {/* <div style={{margin: '0 0 -1.5rem'}}>
            <Badge>For educators</Badge>
          </div> */}
            <h1>
              {/* Build effective, engaging courses for your students */}
              Powerful new tools to get students to fluency faster
            </h1>
            <p
              style={{
                fontSize: "var(--l)",
                maxWidth: "700px",
                margin: "0 0 1rem",
              }}
            >
              {/* Now you can create interactive courses with built-in AI and give your language students a powerful new advantage */}
              Protolang lets educators and content creators build{" "}
              <strong>interactive, AI‑powered lessons</strong> that accelerate students to practical
              proficiency.
            </p>
            {/* <p style={{padding: '1rem', background: 'var(--badge)', margin: '0 0 1rem'}}>
            <Link to="/contact">Contact us</Link> to request early access
          </p> */}
            <Link to="/contact" className="button">
              Request early access
            </Link>
          </div>
        </HeroWrapper>

        <hr />

        {/* <LoomVideo id="ae393b1448524717bce13e1e13fa653c" /> */}

        {/* <p>Are you proficient in another language? Are you interested in language education or preservation?</p>

      <p>If so, <strong>we'd love to have you contribute new lessons to Protolang</strong>. Your contribution could be adding a single small lesson, helping us create a course in a new language, or anywhere in between.</p>

      <p>Examples of people who write lessons:</p>

      <ul>
        <li>Teachers making lessons for their students</li>
        <li>Content creators making lessons for their audience</li>
        <li>Preservationists making lessons to preserve their cultural heritage</li>
      </ul>

      <p style={{padding: '1rem', background: 'var(--badge)', margin: '0 0 1rem'}}>When you contribute to the Protolang project, you're helping create an open library of educational material that anyone in the world can use to learn for free. <Link to="/open-content">Browse and download our open content</Link>.</p> */}

        <h2>How it works</h2>

        <StepBlockWrapper>
          <StepBlock>
            <h3>Write lessons</h3>
            <p>
              Use our powerful lesson creator to build lessons that suit your students and pedagogy
            </p>
          </StepBlock>
          <StepBlock>
            <h3>Embed rich content</h3>
            <p>Videos, audio, dialogs, mnemonics, alternate meanings and more</p>
          </StepBlock>
          <StepBlock>
            <h3>Publish and share</h3>
            <p>Students can access your lessons and improve their fluency on their own time</p>
          </StepBlock>
        </StepBlockWrapper>

        <ArrowCallout text="See it in action" />
        <LoomVideo id="ae393b1448524717bce13e1e13fa653c" />

        <hr />

        {/* <p>You can edit your language course directly in Protolang. See our <Link to="/support">support page</Link> for detailed instructions.</p>

      <p>Once published, your lesson will be freely available to the millions searching for online language help. Your lesson will also contain a link back to your personal profile, blog, institution, etc for others to learn more about your other work.</p> */}

        <h2>Use the latest in learning science</h2>

        <p>
          Protolang is built on the latest research in language learning. Our lessons are designed
          to help students learn faster and retain more.
        </p>

        <p>Our platform is informed by the following learning concepts:</p>

        <ul>
          <li>
            <strong>Spaced repetition</strong> - Reviewing material at increasing intervals to
            maximize retention
          </li>
          {/* <li><strong>Active recall</strong> - Testing yourself on material to improve retention</li> */}
          {/* <li><strong>Interleaving</strong> - Mixing up different types of material to improve retention</li> */}
          <li>
            <strong>Mnemonics</strong> - Using memory techniques to improve retention
          </li>
          {/* <li><strong>Pronunciation</strong> - Using speech recognition to improve pronunciation</li> */}
          {/* <li><strong>Cognates</strong> - Using cognates to accelerate vocabulary acquisition</li> */}
        </ul>

        <h2>Pricing and packages</h2>
        <p style={{ fontStyle: "italic" }}>Note: these packages are likely to change</p>
        <div style={{ height: "1rem" }} />

        <table className="table">
          <thead>
            <tr>
              <th
                style={{
                  width: "50%",
                  background: "var(--badge)",
                  padding: ".5rem",
                }}
              >
                Creators
              </th>
              <th
                style={{
                  width: "50%",
                  background: "var(--badge)",
                  padding: ".5rem",
                }}
              >
                Institutions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ fontWeight: "normal" }}>Unlimited viewers</td>
              <td style={{ fontWeight: "normal" }}>Unlimited viewers</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "normal" }}>Unlimited lessons</td>
              <td style={{ fontWeight: "normal" }}>Unlimited lessons</td>
            </tr>
            <tr>
              <td>Lessons are free and open to all*</td>
              <td style={{ fontWeight: "normal" }}>License + access control</td>
            </tr>
            <tr>
              <td>No student management</td>
              <td style={{ fontWeight: "normal" }}>Track student progress</td>
            </tr>
            <tr>
              <td>Basic pronunciation</td>
              <td style={{ fontWeight: "normal" }}>Premium pronunciation</td>
            </tr>
            <tr>
              <td>Free</td>
              <td>
                <Link to="contact">Contact for pricing</Link>
              </td>
            </tr>
          </tbody>
        </table>

        <p style={{ fontSize: "var(--s)" }}>
          * Free tier contributions are licensed under a Creative Commons license
        </p>

        <br />

        <h2>FAQs</h2>
        <Accordion items={faqs} />

        <p>
          Have a question that's not answered here? Questions/comments/suggestions/other ideas?{" "}
          <Link to="/contact">Contact us</Link>.
        </p>

        <br />

        <h2>Apply now</h2>
        <p>
          We're taking on a limited number of educators as early beta partners. Future invites will
          be offered on a first-come first-served basis.
        </p>
        <Link to="/contact" className="button">
          Request early access
        </Link>
      </OneCol>
    </MainWrapper>
  )
}

const faqs = [
  {
    title: "Which languages do you support?",
    content: (
      <>
        <p>
          Currently we support Italian only, but we are actively looking for beta partners to help
          us test new languages.
        </p>
        <p>
          <Link to="/contact">Contact us</Link> and tell us what language you want to teach—we want
          to hear from you!
        </p>
      </>
    ),
  },
  {
    title: "What is open content?",
    content: (
      <>
        <p>
          Educators can publish lesson content under a under a permissive{" "}
          <a href="https://en.wikipedia.org/wiki/Creative-Commons">
            Creative Commons open content license
          </a>
          . That means students and other teachers can download, print, and share these lessons for
          free.
        </p>
        <p>
          Creators on paid plans can control who has access to their lessons and can choose to
          publish lessons under a more restrictive license.
        </p>
        {/* <p>
        Learn more about the <a href="/open-content">open content licenses we use</a>.
      </p> */}
      </>
    ),
  },
  {
    title: "Why is the website black and white?",
    content: (
      <>
        <p>
          We're focusing on making Protolang useful before we make it pretty.
          Stay&nbsp;tuned&nbsp;:)
        </p>
      </>
    ),
  },
]
