import { createContext, useContext, useEffect } from "react"

import { useUser } from "@/context/user"
import { supabase } from "@/db/supabase"
import { logError } from "@/utils/error"
import { getLocalStorage, removeLocalStorage } from "@/utils/localStorage"

const ONBOARDING_STEPS = [
  "has_completed_dashboard_onboarding",
  "has_completed_chat_welcome_onboarding",
  "has_completed_convo_input_onboarding",
  "has_completed_convo_message_onboarding",
  "has_completed_situation_onboarding",
]

export type OnboardingFlagName = (typeof ONBOARDING_STEPS)[number]

const Context = createContext({
  resetUserOnboarding: () => {},
  flagsObject: {},
})

export default ({ children }) => {
  const { userId, userProfile, isAlphaTester } = useUser()

  const localFlags = getLocalStorage("protolang-onboarding-flags")
  const flagsObject = (userId ? userProfile : localFlags) || {}

  async function resetUserOnboarding() {
    if (!isAlphaTester) return
    if (
      !confirm(
        "Are you sure you want to reset onboarding? This will delete all language history too."
      )
    )
      return

    try {
      if (!userId) return

      removeLocalStorage("protolang-onboarding-flags")

      const updateItems = {
        has_agreed_to_editor_terms: false,
      }
      ONBOARDING_STEPS.forEach(step => {
        updateItems[step] = false
      })
      const { error: errorUser } = await supabase
        .from("user_profiles")
        .update(updateItems)
        .eq("id", userId)
      if (errorUser) throw errorUser

      const { error: errorUserLang } = await supabase
        .from("user_languages")
        .delete()
        .eq("created_by", userId)
      if (errorUserLang) throw errorUserLang

      location.reload()
    } catch (error) {
      logError("reset admin onboarding flags", error)
    }
  }

  // when a new signup loads the app for the first time,
  // we want to check if they have any local onboarding flags
  // and if so, save them to the database and delete them locally
  // so they don't have to see them again
  useEffect(() => {
    const saveLocalFlagsToDatabase = async () => {
      try {
        if (!userId) return
        const localFlags = getLocalStorage("protolang-onboarding-flags")
        if (!localFlags) return
        console.log("saving local onboarding flags to database", localFlags)
        const { error } = await supabase.from("user_profiles").update(localFlags).eq("id", userId)
        if (error) throw error
        removeLocalStorage("protolang-onboarding-flags")
      } catch (error) {
        logError("import local onboarding flags to db", error, true)
      }
    }
    saveLocalFlagsToDatabase()
  }, [userId])

  const exposed = {
    resetUserOnboarding,
    flagsObject,
  }

  return <Context.Provider value={exposed}>{children}</Context.Provider>
}

export const useOnboarding = () => useContext(Context)
