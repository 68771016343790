import remarkDirective from "remark-directive"
import remarkParse from "remark-parse"
import remarkStringify from "remark-stringify"
import { unified } from "unified"
import { visit } from "unist-util-visit"

// ******************************************************************* //
// ******************************************************************* //
// KEEP THIS IN SYNC WITH THE IMPORT FUNCTION THAT DOES THE SAME THING //
// ******************************************************************* //
// ******************************************************************* //

export const parsePhrasesFromLessonContent = async (content, onlyBigBlocks?: boolean) => {
  let phraseStringObjects
  await unified()
    .use(remarkParse)
    .use(remarkDirective)
    .use(() => tree => {
      const directives = []
      visit(tree, "textDirective", node => {
        directives.push(node)
      })
      visit(tree, "leafDirective", node => {
        directives.push(node)
      })
      phraseStringObjects = directives
        .map(node => {
          if (onlyBigBlocks && node.type !== "leafDirective") {
            return {}
          }
          return {
            ita: node.attributes?.ita,
            eng: node.attributes?.eng,
            itaAlt: node.attributes?.itaAlt,
            itaAlts: node.attributes?.itaAlts,
            engAlt: node.attributes?.engAlt,
            engAlts: node.attributes?.engAlts,
            engContext: node.attributes?.engContext,
          }
        })
        .filter(obj => JSON.stringify(obj) !== "{}")
    })
    .use(remarkStringify)
    .process(content)

  // TODO - parse alts
  const phrases = phraseStringObjects.reduce((acc, curr) => {
    if (!curr.ita && !curr.eng) {
      return
    }
    acc.push({
      ita: curr.ita,
      eng: curr.eng,
      itaAlt: curr.itaAlt,
      itaAlts: curr.itaAlts,
      engAlt: curr.engAlt,
      engAlts: curr.engAlts,
      engContext: curr.engContext,
    })
    return acc
  }, [])

  // TODO someday - instead of making phrases an array of strings, make it an array of phrase_ids
  const phrase_slugs_l2 = phraseStringObjects.map(pso => pso.ita).filter(p => p)
  const phrase_slugs_l1 = phraseStringObjects.map(pso => pso.eng).filter(p => p)
  const num_phrases = phraseStringObjects.filter(p => p.ita || p.eng).length

  // TODO - save alternate phrases to lessons too? This would mean we test all alternates, for ex...
  // const phrase_slugs_l2 = phraseStringObjects.map(pso => {
  //   const alts = pso.L2Alts ? pso.L2Alts.split(',').map(s => s.trim()) : []
  //   return [pso.ita, pso.L2Alt, ...alts]
  // }).flat().filter(Boolean)
  // const phrase_slugs_l1 = phraseStringObjects.map(pso => {
  //   const alts = pso.L1Alts ? pso.L1Alts.split(',').map(s => s.trim()) : []
  //   return [pso.eng, pso.L1Alt, ...alts]
  // }).flat().filter(Boolean)

  return {
    phrases,
    phrase_slugs_l2,
    phrase_slugs_l1,
    num_phrases,
  }
}
