import { useParams } from "react-router-dom"

import { useLanguage } from "@/context/language"
import { useUser } from "@/context/user"
import { supabase, useSupabaseQuery, useSupabaseRealtimeQuery } from "@/db/supabase"
import ConversationItem from "@/views/conversations/ConversationItem"

export default () => {
  const { conversationSlug } = useParams()
  const { currentLanguage } = useLanguage()

  const { userId } = useUser()

  const conversationQuery = supabase
    .from("conversations")
    .select()
    .eq("random_slug", conversationSlug)
    .single()
  const [conversation, conversationLoading, conversationError] = useSupabaseQuery(
    conversationQuery,
    [currentLanguage.id, userId],
    !userId || !currentLanguage.id
  )

  const conversationMessagesQuery = supabase
    .from("conversation_messages")
    .select()
    .eq("created_by", userId)
    .eq("conversation_id", conversation?.id)
    .order("created_at", { ascending: true })
  // https://supabase.com/docs/reference/javascript/rangegt
  // operator is not unique: timestamp with time zone <@ unknown
  // .containedBy('created_at', `${formattedDate} 00:00, ${formattedDate} 23:59)`)

  const [conversationMessages, conversationMessagesLoading, conversationMessagesError] =
    useSupabaseRealtimeQuery(
      conversationMessagesQuery,
      [currentLanguage.id, userId, conversation],
      {},
      {
        event: "INSERT",
        schema: "public",
        table: "conversation_messages",
        filter: `created_by=eq.${userId}`,
      }
    )

  const situationQuery = supabase
    .from("conversation_situations")
    .select("*, character_id(*)")
    .eq("id", conversation?.situation_id)
    .single()
  const [situation, situationLoading, situationError] = useSupabaseQuery(situationQuery, [
    conversation,
  ])

  if (conversationMessagesError) return <div>error: {conversationMessagesError.message}</div>
  if (conversationError) return <div>error: {conversationError.message}</div>
  if (situationError) return <div>error: {situationError.message}</div>
  if (conversationMessagesLoading || conversationLoading || situationLoading)
    return <div>Loading...</div>

  return (
    <ConversationItem
      conversation={conversation}
      conversationMessages={conversationMessages}
      situation={situation}
    />
  )
}
