import styled from "styled-components/macro"

import { Badge } from "@/styles/Badge"
import { MainWrapper } from "@/styles/Layout"
import ContentTypes from "@/views/dashboard/ContentTypes"
import Heatmap from "@/views/dashboard/Heatmap"
import ProblemPhrases from "@/views/dashboard/ProblemPhrases"
import TopicProgress from "@/views/dashboard/TopicProgress"
import { Link } from "react-router-dom"
// import DailyProgress from '@/views/practice/DailyProgress'

const ALWAYS_HIDE_ANALYTICS = true

export default ({ lessons, lessonProgressData }) => {
  const hideAnalytics = ALWAYS_HIDE_ANALYTICS
  return (
    <MainWrapper>
      <div style={{ position: "relative" }}>
        {hideAnalytics && (
          <AnalyticsPromoWrapper>
            <AnalyticsPromoBox>
              <Badge>Coming soon</Badge>
              <h1>Coming soon: powerful analytics to help you track your progress</h1>
              <Link to="/subscription">Upgrade to Pro</Link>
            </AnalyticsPromoBox>
          </AnalyticsPromoWrapper>
        )}

        <AnalyticsWrapper $hidden={hideAnalytics}>
          {/* <div>
          <h4>Daily practice</h4>
          <DailyProgress />
        </div> */}
          <TopicProgress lessonProgressData={lessonProgressData} lessons={lessons} />
          <Heatmap />
          <ContentTypes />
          <ProblemPhrases />
        </AnalyticsWrapper>
      </div>
    </MainWrapper>
  )
}

const AnalyticsPromoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
const AnalyticsPromoBox = styled.div`
  padding: 2rem;
  border: 1px solid;
  box-shadow: 2px 2px var(--shadow);
  background: var(--bg);
  z-index: 500;
  max-width: 600px;
`
const AnalyticsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  filter: ${({ $hidden }) => $hidden && "blur(5px)"};
`
