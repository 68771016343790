import { useParams } from "react-router-dom"

import { supabase, useSupabaseQuery } from "@/db/supabase"
import ConversationItem from "@/views/conversations/ConversationItem"

export default () => {
  const { situationSlug } = useParams()

  const situationQuery = supabase
    .from("conversation_situations")
    .select("*, character_id(*), created_by(*)")
    .eq("random_slug", situationSlug)
    .single()
  const [situation, situationLoading, situationError] = useSupabaseQuery(situationQuery, [
    situationSlug,
  ])

  if (situationError) return <div>error: {situationError.message}</div>
  if (situationLoading) return <div>Loading...</div>

  return <ConversationItem conversation={null} conversationMessages={null} situation={situation} />
}
