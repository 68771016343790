import { supabase, useSupabaseQuery } from "@/db/supabase"
import ErrorComponent from "@/views/errors/ErrorComponent"
import { Link } from "react-router-dom"

export default () => {
  const query = supabase.from("languages").select()
  const [languages, loading, error] = useSupabaseQuery(query)

  const liveLanguages = languages?.filter(
    l => l.publish_status === "general_availability" || l.publish_status === "beta_testing"
  )
  const soonLanguages = languages?.filter(
    l => l.publish_status === "not_started" || l.publish_status === "in_development"
  )

  if (loading) return "loading..."
  if (error) return <ErrorComponent error={error} />

  return (
    <>
      <h4>Available in</h4>

      {liveLanguages?.map(language => {
        return (
          <span key={language.id}>
            <Link to={`/${language.slug_eng}`} className="button button-full-width">
              {language.name_eng}
              {language.publish_status === "beta_testing" && " (beta)"}
            </Link>
            <div style={{ height: ".5rem" }} />
          </span>
        )
      })}

      <br />
      <br />

      <p>Coming soon...</p>

      {soonLanguages?.map((language, index) => {
        return (
          <span key={language.id}>
            {index > 0 && " · "}
            <Link to={`/${language.slug_eng}`}>{language.name_eng}</Link>
          </span>
        )
      })}
    </>
  )
}
