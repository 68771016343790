import { useState } from "react"
import { Link, Navigate } from "react-router-dom"

import { useUser } from "@/context/user"
import {
  LeftPanelInner,
  LeftPanelWrapper,
  PanelsWrapper,
  RightPanelInner,
  RightPanelWrapper,
} from "@/styles/CreateAccount"
import Icon from "@/styles/Icon"
import { FeatureBlock, FeatureBlockIconWrapper } from "@/styles/Marketing"
import OrBar from "@/styles/OrBar"
import { logError } from "@/utils/error.js"
import { Helmet } from "react-helmet-async"

export default () => {
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)

  const { userId, signInWithOtp, signInWithGoogle } = useUser()

  const onSubmit = async event => {
    event.preventDefault()
    try {
      setLoading(true)
      await signInWithOtp(email)
    } catch (error) {
      logError("sign up", error)
    } finally {
      setLoading(false)
    }
  }

  if (userId) {
    return <Navigate to="/" />
  }

  return (
    <>
      <Helmet>
        <title>Sign up | Protolang</title>
      </Helmet>
      <PanelsWrapper>
        <LeftPanelWrapper>
          <LeftPanelInner>
            <h1>Create your free account</h1>
            <p>We're excited to have you!</p>
            <p style={{ fontSize: "var(--s)" }}>
              You're in full control of your data and notification choices.{" "}
              <Link to="/privacy">Read our privacy policy</Link> for details.
            </p>
            <br />
            <button
              className="button button-primary button-full-width"
              onClick={signInWithGoogle}
              disabled={loading}
            >
              <img
                src="/images/social_icons/google.svg"
                alt="Google"
                style={{ height: "1rem", marginRight: ".25rem" }}
              />
              Sign up with Google
            </button>
            <OrBar />
            <form onSubmit={onSubmit}>
              <div className="input-button-set">
                <input
                  type="email"
                  placeholder="Your email"
                  value={email}
                  disabled={loading}
                  onChange={e => setEmail(e.target.value)}
                  required
                />
                <button className="button" disabled={loading} type="submit">
                  {loading ? "Sending..." : "Send me a link"}
                </button>
              </div>
            </form>
            <p style={{ fontSize: "var(--s)" }}>
              By signing up, you agree to our <Link to="/terms">Terms of Service</Link>
            </p>
            <p style={{ marginBottom: "-.5rem", fontSize: "var(--s)" }}>
              <Link to="/support#log-in-trouble">Trouble signing up?</Link>
            </p>
            <hr />
            <br />
            <p style={{}}>
              Already have an account? <Link to="/login">Log in</Link>
            </p>
          </LeftPanelInner>
        </LeftPanelWrapper>
        <RightPanelWrapper>
          <RightPanelInner>
            <p>Your account gives you access to:</p>
            <br />
            <FeatureBlock>
              <FeatureBlockIconWrapper>
                <Icon name="sms" size="large" />
              </FeatureBlockIconWrapper>
              <div>
                <h3>Saved role-play conversations</h3>
                <p>Save and share your AI chats</p>
              </div>
            </FeatureBlock>
            <FeatureBlock>
              <FeatureBlockIconWrapper>
                <Icon name="volume_up" size="large" />
              </FeatureBlockIconWrapper>
              <div>
                <h3>Pronunciation</h3>
                <p>Listen to how words should really sound</p>
              </div>
            </FeatureBlock>

            <FeatureBlock>
              <FeatureBlockIconWrapper>
                <Icon name="swap_horiz" size="large" />
              </FeatureBlockIconWrapper>
              <div>
                <h3>Conjugations</h3>
                <p>Instant conjugations for any verb</p>
              </div>
            </FeatureBlock>

            <FeatureBlock>
              <FeatureBlockIconWrapper>
                <Icon name="search" size="large" />
              </FeatureBlockIconWrapper>
              <div>
                <h3>Definitions</h3>
                <p>Look up words without loosing your place</p>
              </div>
            </FeatureBlock>

            <FeatureBlock>
              <FeatureBlockIconWrapper>
                <Icon name="emoji_objects" size="large" />
              </FeatureBlockIconWrapper>
              <div>
                <h3>Mnemonics</h3>
                <p>Easy memorization hints to retain vocabulary</p>
              </div>
            </FeatureBlock>
            <FeatureBlock>
              <FeatureBlockIconWrapper>
                <Icon name="shapes" size="large" />
              </FeatureBlockIconWrapper>
              <div>
                <h3>Examples</h3>
                <p>See any word or phrase used in a sentence</p>
              </div>
            </FeatureBlock>
          </RightPanelInner>
        </RightPanelWrapper>
      </PanelsWrapper>
    </>
  )
}
