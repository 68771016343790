import { Link } from "react-router-dom"

import { useLanguage } from "@/context/language"
import { useUser } from "@/context/user"
import FakeContentBg from "@/views/_components/FakeContentBg"
import { MainWrapper } from "@/styles/Layout"
import LoomVideo from "@/views/_components/LoomVideo"
import ToggleAlphaAccess from "@/views/_layout/components/ToggleAlphaAccess"
import JoinWaitlist from "@/views/account/JoinWaitlist"

export default () => {
  const { currentLanguage } = useLanguage()
  const { userId } = useUser()

  return (
    <MainWrapper style={{ position: "relative" }}>
      <FakeContentBg>
        <div style={{ textAlign: "center" }}>
          {/* <Badge>Coming soon</Badge> */}
          <h1 style={{ marginTop: "0.5rem" }}>
            The {currentLanguage.name_eng} course is coming soon
          </h1>
          <div style={{ height: ".5rem" }} />
          <p>Sign up to get an email when the course launches</p>
          <div
            style={{
              margin: ".5rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <JoinWaitlist />
          </div>
          <p style={{ fontSize: "var(--s)" }}>
            {userId ? (
              <ToggleAlphaAccess>
                <span className="link">I have an invite code</span>
              </ToggleAlphaAccess>
            ) : (
              <Link to={`/login?redirectTo=${encodeURIComponent(window.location.pathname)}`}>
                I have an invite code
              </Link>
            )}
          </p>
          <br />
          <LoomVideo id="ae393b1448524717bce13e1e13fa653c" />
          <br />
          <p>
            Do you speak {currentLanguage?.name_eng}? Learn how you can{" "}
            <Link to="/contribute">publish your own lessons in&nbsp;Protolang</Link>.
          </p>
        </div>
      </FakeContentBg>
    </MainWrapper>
  )
}
