import styled from "styled-components/macro"

import { useLanguage } from "@/context/language"
import { supabase, useSupabaseQuery } from "@/db/supabase"
import ConversationSituationsListItem from "@/views/conversations/ConversationSituationsListItem"

interface ConversationSituationsListParams {
  proficiencyLevel?: number
  topic?: string
  small?: boolean
}

export default ({ proficiencyLevel, topic, small }: ConversationSituationsListParams) => {
  const { currentLanguage } = useLanguage()

  let situationsQuery = supabase
    .from("conversation_situations")
    .select("*, character_id(*)")
    .eq("language_id", currentLanguage.id)
  if (proficiencyLevel || proficiencyLevel === 0)
    situationsQuery = situationsQuery.eq("proficiency_level", proficiencyLevel)

  // TODO
  const [situations, situationsLoading, situationsError] = useSupabaseQuery(situationsQuery, [
    proficiencyLevel,
  ])

  // TODO - check all prev conversations, and if there's not one complete, lock the advanced levels

  if (situationsLoading) return <div>Loading...</div>
  if (situationsError) return <div>Error: {situationsError.message}</div>
  if (!situations.length) return <div>No situations for this category</div>

  const onTopicSituations = !topic
    ? situations
    : situations.filter(situation => situation.topics.includes(topic))
  const situationsToShow = onTopicSituations.sort(
    (a, b) => a.proficiency_level - b.proficiency_level
  )

  return (
    <>
      <ConversationOptionListWrapper>
        {situationsToShow.map(situation => {
          return (
            <ConversationSituationsListItem
              key={situation.id}
              situation={situation}
              small={small}
            />
          )
        })}
      </ConversationOptionListWrapper>
    </>
  )
}

const ConversationOptionListWrapper = styled.div`
  display: grid;

  // https://css-tricks.com/auto-sizing-columns-css-grid-auto-fill-vs-auto-fit/
  grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));

  gap: 1.5rem;
  margin: 0 0 1rem;
  max-width: 100%;
  /* overflow-x: hidden; */
`
