import { useEffect } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components/macro"

import { useLanguage } from "@/context/language"
import { useReferencePanel } from "@/context/reference"
import { useUser } from "@/context/user"
import useMediaQuery from "@/hooks/useMediaQuery"
import Icon from "@/styles/Icon"
import Definable from "@/views/_components/Definable"
import ReferencePanelResult from "@/views/reference/ReferencePanelResult"
import SearchBox from "@/views/reference/SearchBox"
import SearchHistory from "@/views/reference/SearchHistory"

export default () => {
  const { queryText, referenceIsOpen, setReferenceIsOpen, setQuery, searchHistory } =
    useReferencePanel()

  const { userId } = useUser()
  const { currentLanguage, startingLanguage } = useLanguage()
  const isDesktop = useMediaQuery("(min-width: 800px)")

  useEffect(() => {
    setQuery({ text: "", language: currentLanguage })
  }, [currentLanguage, startingLanguage])

  // This would be good in theory when we have multiple languages,
  // but it makes you click a word twice to open the panel
  // because the first click sets the query language, which resets the text.
  // Even if we set the query language immediately on load,
  // any time you queried in a new language it would change the text to blank again.
  // Do we really need to keep the query language up to date if we don't have tabs for it?
  // useEffect(() => {
  //   setQuery({text: '', language: queryLanguage})
  // }, [queryLanguage])

  return (
    <>
      {referenceIsOpen && !isDesktop && (
        <ReferencePanelOverlay onClick={() => setReferenceIsOpen(false)} />
      )}

      <ReferencePanelWrapper
        onClick={() => setReferenceIsOpen(true)}
        $open={referenceIsOpen}
        $isMobile={!isDesktop}
      >
        <div>
          {referenceIsOpen ? (
            <ReferenceCloseX
              onClick={e => {
                e.stopPropagation()
                setReferenceIsOpen(false)
              }}
            >
              {/* ✕ */}
              {/* <Icon name="expand_more" size="large" /> */}
              <Icon name="close" />
            </ReferenceCloseX>
          ) : (
            <ReferencePanelOpen
              onClick={e => {
                e.stopPropagation()
                setReferenceIsOpen(true)
              }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
                {/* <Icon name="search" size="large" /> */}
                <Icon name="magic_button" size="large" /> Translate anything
              </div>
              <Icon name="expand_less" size="large" />
            </ReferencePanelOpen>
          )}
          <ReferencePanelContent>
            {queryText ? (
              <ReferencePanelResult />
            ) : (
              <>
                <br />
                <h4>
                  {currentLanguage.name_eng} → {startingLanguage.name_eng}
                </h4>
                <SearchBox language={currentLanguage} />

                <h4>
                  {startingLanguage.name_eng} → {currentLanguage.name_eng}
                </h4>
                <SearchBox language={startingLanguage} />

                <br />

                {!userId || !searchHistory || searchHistory.length === 0 ? (
                  <>
                    <p>You can also look up any word with a dotted underline by clicking it.</p>
                    <p>For example, try:</p>
                    <ul>
                      <li>
                        <Definable wordString="mangiare" />
                      </li>
                      <li>
                        <Definable wordString="pizza" />
                      </li>
                      <li>
                        <Definable wordString="italiano" />
                      </li>
                    </ul>
                    {!userId && (
                      <>
                        <Link to="/signup">Sign up</Link> to save your search history
                      </>
                    )}
                  </>
                ) : (
                  <SearchHistory />
                )}
              </>
            )}
          </ReferencePanelContent>
        </div>
      </ReferencePanelWrapper>
    </>
  )
}

const REFERENCE_PANEL_CLOSED_HEIGHT = "3rem"

const ReferencePanelOverlay = styled.div`
  position: fixed;
  z-index: 200;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: hsla(0, 0%, 0%, 0.5);
`
const ReferencePanelWrapper = styled.div`
  position: fixed;
  z-index: 500;
  bottom: 0;
  left: ${props => (props.$isMobile ? "0" : "auto")};
  right: ${props => (props.$isMobile ? "0" : "3rem")};
  width: ${props => !props.$isMobile && "360px"};
  max-height: 80vh;
  transform: ${props =>
    props.$open ? "translateY(0)" : `translateY(calc(100% - ${REFERENCE_PANEL_CLOSED_HEIGHT}))`};
  transition: transform 0.2s ease-in-out;

  box-shadow: 3px 3px var(--shadow);
  border-style: solid;
  border-width: ${props => (props.$isMobile ? "1px 0 0 0" : "1px 1px 0 1px")};
  display: flex;
  flex-direction: column;
  background: var(--bg);
`
const ReferencePanelOpen = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
  overflow: hidden;
  text-decoration: none;
  min-height: ${REFERENCE_PANEL_CLOSED_HEIGHT};
  padding: 0 1rem;
  box-shadow: inset 0 -1px var(--shadow);
  background: var(--bg);
  user-select: none;
`
const ReferenceCloseX = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  width: var(--xxl);
  height: var(--xxl);
  font-size: var(--l);

  text-decoration: none;
  font-weight: 500;
  background: var(--bg);
  border: none;
  border-radius: 1000px;
  cursor: pointer;

  &:hover {
    background: var(--badge);
  }
`
const ReferencePanelContent = styled.div`
  overflow-y: auto;
  padding: 0.5rem 1rem;
`
