// import { useState } from 'react'
import { Helmet } from "react-helmet-async"
import ReactTextareaAutosize from "react-textarea-autosize"

// import ipa_sounds from '@/consts/ipa_sounds'
import { useToast } from "@/context/toast"
import { Link } from "@/styles/Links"
import { CheckboxGroup, RadioGroup } from "@/views/_components/RadioCheckbox"
import SpeakableInput from "@/views/_components/SpeakableInput"
import { useState } from "react"
// import SpeakableInput from '@/styles/SpeakableInput'
// import Pronouncable from '@/views/_components/Pronouncable'

export default () => {
  // const [ speakableTextContent, setSpeakableTextContent ] = useState('Hello world')

  const { setToast } = useToast()

  const [speakableInputValue, setSpeakableInputValue] = useState("")

  return (
    <>
      <Helmet>
        <title>Styleguide | Protolang</title>
      </Helmet>
      <h1 data-testid="styleguide">Style guide</h1>
      <p>Components for developers of the Protolang app.</p>
      <p>
        Not sure why you're here? Go back to the <Link to="/">homepage</Link>.
      </p>
      <hr />
      <h1>Heading 1</h1>
      <p>Paragraph text goes here. Lorem ipsum dolor.</p>
      <h2>Heading 2</h2>
      <p>Foo bar baz</p>
      <ul>
        <li>List item 1</li>
        <li>List item 2</li>
        <li>List item 3</li>
      </ul>
      <h3>Heading 3</h3>
      <ol>
        <li>List item 1</li>
        <li>List item 2</li>
        <li>List item 3</li>
      </ol>
      <h4>Heading 4</h4>
      <p>Foo bar baz</p>
      <hr />
      <p style={{ fontSize: "var(--xxxl)" }}>Font size xxxl - display size</p>
      <p style={{ fontSize: "var(--xxl)" }}>Font size xxl - display size</p>
      <p style={{ fontSize: "var(--xl)" }}>Font size xl - display size</p>
      <p style={{ fontSize: "var(--l)" }}>Font size l - for important stuff</p>
      <p style={{ fontSize: "var(--m)" }}>Font size m - for most things</p>
      <p style={{ fontSize: "var(--s)" }}>Font size s - for less important stuff</p>
      <p style={{ fontSize: "var(--xs)" }}>Font size xs - for legalese only</p>
      <hr />
      <label>Input label</label>
      <input />
      <label>Input next to button</label>
      <div className="input-button-set">
        <input />
        <button className="button">Test</button>
      </div>
      <label>Text area</label>
      <textarea />
      <label>Resizable text area</label>
      <ReactTextareaAutosize />
      {/* <label>Speakable textarea</label>
    <SpeakableInput
      id="input_speakable_textarea"
      value={speakableTextContent}
      setValue={setSpeakableTextContent}
      onSubmit={() => alert('Submitted speakable text area!')}
      placeholder="Speakable text area"
      disabled={false}
    /> */}
      <div style={{ maxWidth: "400px" }}>
        <SpeakableInput
          value={speakableInputValue}
          setValue={setSpeakableInputValue}
          bigRecordButton
        />
      </div>
      <hr />
      <label>Select list</label>
      <select>
        <option>-- Choose an option --</option>
        <option>Option 1</option>
        <option>Option 2</option>
        <option>Option 3</option>
      </select>
      <label>Select list (disabled)</label>
      <select disabled>
        <option>-- Choose an option --</option>
        <option>Option 1</option>
        <option>Option 2</option>
        <option>Option 3</option>
      </select>
      <label>Checkboxes</label>
      <CheckboxGroup
        groupName="test"
        values={["1"]}
        setValues={values => console.log("ℹ️ checkbox", values)}
        options={[
          {
            id: "1",
            title: "Option 1",
            description: "This is a long sentence to use as a description",
          },
          { id: "2", title: "Option 2", description: "Foo bar baz" },
          { id: "3", title: "Other" },
        ]}
      />
      <label>Radios</label>
      <RadioGroup
        value={"1"}
        setValue={value => console.log("ℹ️ radio", value)}
        options={[
          {
            id: "1",
            title: "Option 1",
            description: "This is a long sentence to use as a description",
          },
          { id: "2", title: "Option 2", description: "Foo bar baz" },
          { id: "3", title: "Other" },
        ]}
      />
      <hr />
      <a href="https://github.com/sampl" target="_blank">
        External link
      </a>
      <br />
      <Link to="/">Internal link</Link>
      <br />
      <button onClick={() => alert("You clicked it")}>Plain button</button>
      <br />
      <br />
      <button className="button" onClick={() => alert("You clicked it")}>
        Button with button class
      </button>{" "}
      <Link to="/" className="button">
        Link as button
      </Link>{" "}
      <button className="button" disabled onClick={() => alert("Should not fire")}>
        Disabled button
      </button>{" "}
      <button className="button button-primary">Primary color</button>{" "}
      <button className="button button-tertiary">Tertiary color</button>{" "}
      <button className="button button-full-width">Full width</button>
      <br />
      <button className="button button-primary button-full-width">
        <img
          src="/images/social_icons/google.svg"
          alt="Google"
          style={{ height: "1rem", marginRight: ".25rem" }}
        />
        Auth button
      </button>
      <hr />
      <button onClick={() => setToast("Hello world!")}>Show toast</button>
      <br />
      {/* <h3>Pronunciation</h3>
    {Object.keys(ipa_sounds).map(ipaLetter => {
      // console.log('ℹ️ ipa letter', ipaLetter)
      return <><Pronouncable ipa={ipaLetter} />{' '}{ipaLetter}{' '}</>
    })} */}
      <br />
    </>
  )
}
