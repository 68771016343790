import { useForm, ValidationError } from "@formspree/react"
import { useState } from "react"
import ReactTextareaAutosize from "react-textarea-autosize"
import styled from "styled-components/macro"

import { useUser } from "@/context/user"
import { logError } from "@/utils/error"

// https://formspree.io/forms/xnqyjjgl/integration
// https://formspr.ee/react-help
export default ({ conversation }) => {
  const { userAuth } = useUser()

  const [emotion, setEmotion] = useState<string | null>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)

  const handleEmotionClick = (emotion: string) => {
    setEmotion(emotion)
    submitEmotion(emotion)
  }

  const submitEmotion = async (emotion: string) => {
    try {
      setLoading(true)
      setError(null)
      setSuccess(false)

      // https://help.formspree.io/hc/en-us/articles/360013470814-Submit-forms-with-JavaScript-AJAX-
      await fetch("https://formspree.io/f/xnqyjjgl", {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({
          form: "emotion-feedback",
          message: `[${emotion} emoji only]`,
          emotion,
          conversation: conversation?.id,
          email: userAuth?.email,
        }),
      })
      setSuccess(true)
      setError(null)
      setLoading(false)
      console.log("sent feedback!")
    } catch (error) {
      setSuccess(false)
      setLoading(false)
      setError(error)
      logError("send your emoji feedback", error)
    }
  }

  return (
    <FeedbackWrapper>
      <p>How are you like Protolang so far?</p>

      {error && <p>Sorry, something went wrong. Try again in a minute? ({error.message})</p>}

      <div
        style={{
          display: "flex",
          gap: ".5rem",
          pointerEvents: loading || success ? "none" : "all",
          opacity: loading || success ? ".5" : "1",
        }}
      >
        <FeedbackButton
          $selected={emotion === "ecstatic"}
          onClick={() => handleEmotionClick("ecstatic")}
        >
          🤩
        </FeedbackButton>
        <FeedbackButton $selected={emotion === "happy"} onClick={() => handleEmotionClick("happy")}>
          🙂
        </FeedbackButton>
        <FeedbackButton
          $selected={emotion === "confused"}
          onClick={() => handleEmotionClick("confused")}
        >
          🤔
        </FeedbackButton>
        <FeedbackButton
          $selected={emotion === "neutral"}
          onClick={() => handleEmotionClick("neutral")}
        >
          😐
        </FeedbackButton>
        <FeedbackButton $selected={emotion === "sad"} onClick={() => handleEmotionClick("sad")}>
          😢
        </FeedbackButton>
        <FeedbackButton $selected={emotion === "angry"} onClick={() => handleEmotionClick("angry")}>
          😠
        </FeedbackButton>
      </div>

      {success && <FollowupForm conversation={conversation} emotion={emotion} />}
    </FeedbackWrapper>
  )
}

const FollowupForm = ({ conversation, emotion }) => {
  const { userAuth } = useUser()

  const [state, handleSubmit] = useForm("xnqyjjgl")

  return (
    <div>
      {state.errors && state.errors.length > 0 && <p>{state.errors[0]?.message}</p>}

      {state.succeeded ? (
        <div
          style={{
            textAlign: "center",
            padding: "1rem",
            background: "var(--badge)",
            margin: "1rem 0 0",
          }}
        >
          <p style={{ fontWeight: "bold" }}>Thank you for your feedback!</p>
          <p>We'll use it to improve our chats in the future</p>
        </div>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <input type="hidden" id="form" name="form" value="emotion-feedback-followup" />
            <input type="hidden" id="emotion" name="emotion" value={emotion} />
            <input type="hidden" id="conversation" name="conversation" value={conversation?.id} />
            <input type="hidden" id="email" name="email" value={userAuth?.email} />
            <p style={{ fontStyle: "italic", margin: ".5rem 0 0" }}>
              {emotion === "ecstatic"
                ? "Awesome!"
                : emotion === "happy"
                ? "Great!"
                : emotion === "confused"
                ? ""
                : emotion === "neutral"
                ? ""
                : emotion === "sad"
                ? "Oof, sorry to hear that."
                : emotion === "angry"
                ? "Oof, sorry to hear that."
                : ""}
            </p>

            <label>Anything else you want to share? (optional)</label>
            <ReactTextareaAutosize
              id="message"
              name="message"
              disabled={state.submitting || state.succeeded}
              minRows={2}
              placeholder={"Feedback, questions, comments, concerns..."}
            />
            <ValidationError prefix="Message" field="message" errors={state.errors} />

            <br />

            <button className="button" type="submit" disabled={state.submitting || state.succeeded}>
              Send feedback
            </button>
          </form>
        </>
      )}
    </div>
  )
}

const FeedbackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 1rem 0;
  background: var(--bg);
  color: var(--text);
  margin: 1rem auto 0;
  max-width: 500px;
  border-top: 1px solid;
`
const FeedbackButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--xxl);
  height: var(--xxl);
  font-size: var(--xl);
  background: ${props => (props.$selected ? "var(--badge)" : "var(--bg)")};
  transform: ${props => (props.$selected ? "scale(1.3)" : "scale(1)")};
  border-radius: 50%;
  text-decoration: none;
`
