import { useLanguage } from "@/context/language"
import { supabase, useSupabaseQuery } from "@/db/supabase"
import { MainWrapper } from "@/styles/Layout"
import Definable from "@/views/_components/Definable"
import ErrorPage from "@/views/errors/ErrorPage"
import SavedAddRemove from "@/views/saved/SaveButton"

export default () => {
  const { currentLanguage } = useLanguage()

  const query = supabase.from("saved_words").select().eq("language_id", currentLanguage.id)
  const [savedWords, loading, error] = useSupabaseQuery(query, [currentLanguage.id])

  if (loading) return "loading..."
  if (error) return <ErrorPage error={error} />

  return (
    <MainWrapper>
      <h1>Saved words</h1>
      {!savedWords || savedWords.length <= 0 ? (
        "no saved items"
      ) : (
        <ul>
          {savedWords.map(savedItem => {
            return (
              <li key={savedItem.id}>
                <SavedAddRemove wordString={savedItem.content_l2} />
                <Definable wordString={savedItem.content_l2} />
              </li>
            )
          })}
        </ul>
      )}
    </MainWrapper>
  )
}
