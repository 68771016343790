import { useEffect, useState } from "react"
import styled from "styled-components/macro"

import { useLanguage } from "@/context/language"
import { supabase } from "@/db/supabase"
import Definable from "@/views/_components/Definable"
import ErrorComponent from "@/views/errors/ErrorComponent"
// import Definable from '@/views/lessons/Definable'
// import SpeakWord from '@/views/_components/SpeakWord'

// https://open-meteo.com/en/docs
// translated via chatgpt, checked with deepl (mostly right)
const weatherCodes = {
  0: "Cielo sereno",
  1: "Cielo prevalentemente sereno",
  2: "Parzialmente nuvoloso",
  3: "Nuvoloso",
  45: "Nebbia",
  48: "Nebbia di gelicidio",
  51: "Pioggerella leggera",
  53: "Pioggerella moderata",
  55: "Pioggerella densa",
  56: "Pioggerella leggera ghiacciata",
  57: "Pioggerella densa ghiacciata",
  61: "Pioggia leggera",
  63: "Pioggia moderata",
  65: "Pioggia forte",
  66: "Pioggia leggera ghiacciata",
  67: "Pioggia forte ghiacciata",
  71: "Neve leggera",
  73: "Neve moderata",
  75: "Neve forte",
  77: "Nevischio",
  80: "Pioggia leggera intermittente",
  81: "Pioggia moderata intermittente",
  82: "Pioggia forte intermittente",
  85: "Nevischio intermittente leggero",
  86: "Nevischio intermittente forte",
  95: "Temporale lieve o moderato",
  96: "Temporale con grandine leggera",
  99: "Temporale con grandine forte",
}

export default () => {
  const { currentLanguage } = useLanguage()

  const [weather, setWeather] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const getWeather = async () => {
    try {
      if (!currentLanguage.id) {
        setError("Missing language")
        return
      }
      setLoading(true)
      console.log("🏃 triggering edge function get_weather")
      const { data, error } = await supabase.functions.invoke("get_weather", {
        body: { language_id: currentLanguage.id },
      })
      setError(null)
      if (error) throw error
      console.log("🌤️ weather data", data)
      setWeather(data.weather)
      setLoading(false)
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    getWeather()
  }, [])

  if (loading) return "..."
  if (error) return <ErrorComponent error={error} />

  const numberOfReadings = weather?.hourly?.time.length
  const temperature = weather?.hourly?.apparent_temperature[numberOfReadings - 1]
  const temperatureFahrenheit = Math.floor(temperature * (9 / 5) + 32)
  const temperatureUnit = weather?.hourly_units?.apparent_temperature
  const precipitation_probability = weather?.hourly?.precipitation_probability[numberOfReadings - 1]
  const weatherCode = weather?.hourly?.weathercode[numberOfReadings - 1]
  const weatherCodeDescription = weatherCode && weatherCodes[weatherCode]
  const precipitationDescription = precipitation_probability
    ? `, ${precipitation_probability}% precipitazioni`
    : ""
  const weatherSummary = weatherCodeDescription + precipitationDescription

  return (
    <div>
      <WeatherWrapper>
        <h4>Weather</h4>
        <p>
          <span style={{ fontSize: "var(--xl)" }}>{temperature || "..."}</span>
          {temperatureUnit || ""} a Roma ({temperatureFahrenheit || "..."}°F)
        </p>
        <p>
          <Definable wordString={weatherSummary} splitByWord />
        </p>
        {/* <Definable wordString={newsItem?.title} /> */}
      </WeatherWrapper>
      {/* <p style={{ fontSize: 'var(--s)' }}>
      weather via
      {' '}
      <a href="https://open-meteo.com/" target="_blank" rel="noreferrer">open-meteo.com</a>
    </p> */}
    </div>
  )
}

const WeatherWrapper = styled.div`
  position: relative;

  & > * {
    margin-bottom: 0;
  }
`
