import { saveAs } from "file-saver"
import JSZip from "jszip"
import { useState } from "react"
import yaml from "yaml"

import { supabase, useSupabaseQuery } from "@/db/supabase"

const situationsReadmeContent = `# Protolang situations

This is a collection of ai prompt conversation situations from [Protolang.com](https://protolang.com).

Generated ${new Date().toString()}

License information coming soon.
`

export default () => {
  // ready, working, done
  const [downloadingState, setDownloadingState] = useState("ready")

  const languagesQuery = supabase.from("languages").select()
  const [languages, languagesLoading, languagesError] = useSupabaseQuery(languagesQuery)

  const downloadAll = async () => {
    setDownloadingState("working")

    // get situations
    console.log("⬇️ getting situations")
    const { data: situations, error } = await supabase
      .from("conversation_situations")
      .select("*, character_id(*)")
      .order("language_id, id", { ascending: true })

    if (error) {
      alert("Sorry, could not download situations right now. Try again later?")
      console.error(error)
      return
    }

    // generate object of markdown files
    console.log("⬇️ generating situationFiles")
    const situationFiles = situations.map((situation, index) => {
      const language = languages.find(lang => lang.id === situation.language_id)
      const languageSlug = language.slug_eng
      const {
        proficiency_level,
        name_eng,
        background_file,
        background_color,
        ai_prompt,
        user_summary_eng,
        user_goal_eng,
        topics,
        published_at,
      } = situation
      let metadata: any = {
        language: languageSlug,
        proficiency_level,
        name_eng,
        background_file,
        background_color,
        ai_prompt,
        user_summary_eng,
        user_goal_eng,
        topics,
      }
      if (situation.character_id.name_slug) {
        metadata.character = situation.character_id.name_slug
      }
      metadata.is_published = !!published_at
      const yamlContent = yaml.stringify(metadata, { sortMapEntries: true })
      const indexPadded = index.toString().padStart(6, "0")
      return {
        name: `${indexPadded} - ${situation.name_eng || "[No content]"}.yaml`,
        content: yamlContent,
      }
    })

    // TODO - append CC license here
    situationFiles.push({
      name: "README.md",
      content: situationsReadmeContent,
    })

    // make zip file
    console.log("⬇️ zipping situationFiles", situationFiles)
    const zip = new JSZip()
    situationFiles.forEach((file, index) => zip.file(file.name, file.content))
    const zipFile = await zip.generateAsync({ type: "blob" })

    // save file
    console.log("⬇️ saving situationFiles")
    saveAs(zipFile, `Protolang situations.zip`)

    setDownloadingState("done")
  }

  if (languagesLoading) return <p>Loading...</p>
  if (languagesError) return <p>Error: {languagesError.message}</p>

  return (
    <button
      disabled={downloadingState === "working" || downloadingState === "done"}
      onClick={downloadAll}
    >
      {downloadingState === "ready"
        ? "Download all situations"
        : downloadingState === "working"
        ? "Downloading..."
        : downloadingState === "done"
        ? "Done!"
        : "Error"}
    </button>
  )
}
