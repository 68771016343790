import { useState } from "react"
import { Link } from "react-router-dom"

import { useUser } from "@/context/user"
import { supabase } from "@/db/supabase"
import { OneCol } from "@/styles/Layout.js"
import { logError } from "@/utils/error.js"

export default () => {
  const [saving, setSaving] = useState(false)

  const { userId, userProfile } = useUser()

  async function onSubmit(event) {
    event.preventDefault()
    try {
      setSaving(true)
      if (!userId) return

      const { error } = await supabase
        .from("user_profiles")
        .update({
          has_agreed_to_editor_terms: true,
        })
        .eq("id", userId)
      if (error) throw error

      // Force reload the page so this page doesn't show up again
      location.reload()
    } catch (error) {
      logError("save your editor agreement", error)
    } finally {
      setSaving(false)
    }
  }

  const disabled = !userProfile?.username || saving

  return (
    <OneCol>
      <h1>Before you start editing...</h1>
      <p>
        Please look at the licenses we use on our <Link to="/open-content">open content page</Link>{" "}
        and <Link to="/terms">terms of service</Link>.
      </p>

      <ul>
        <li>
          <strong>
            Any content you contribute to Protolang is automatically licensed under our{" "}
            <Link to="/open-content"></Link> free content licenses
          </strong>
          , which means it will always be free for others to download and share.
        </li>
        <li>
          Never add someone else's content unless it has a permissive Creative Commons license or
          equivalent.
        </li>
        <li>
          If you do use someone else's open content, <em>always</em> cite the author, source, and
          appropriate license at the bottom of the page (regardless of whether the license requires
          attribution).
        </li>
      </ul>

      <p>
        Please also review our <Link to="/conduct">code of conduct</Link> (we don't tolerate
        harassment on the platform).
      </p>

      <p style={{ color: "red", fontWeight: "bold" }}>
        WARNING TO EDITORS: Protolang is in alpha and the live database is completely cleared every
        week or so. YOUR EDITS WILL NOT BE SAVED. Please make your own copy of your lessons for now.
        (This will chance when we launch.)
      </p>

      <p>
        One last thing: please choose or confirm your username so others can see who you are! You
        can always change this later.
      </p>
      <UserName refreshOnSet />
      <br />

      <p>That's it! We're super excited to have you build courses with us 😁</p>

      <hr />

      <form onSubmit={onSubmit}>
        <button className="button" disabled={disabled} type="submit">
          {saving ? "Saving..." : "I agree"}
        </button>
      </form>
    </OneCol>
  )
}

const UserName = ({ refreshOnSet }) => {
  const { userProfile, userId } = useUser()
  const [username, setUsername] = useState(userProfile?.username)
  const [isEditing, setIsEditing] = useState(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = async event => {
    event.preventDefault()
    try {
      setLoading(true)

      const { error } = await supabase
        .from("user_profiles")
        .upsert({
          id: userId,
          username,
        })
        .eq("id", userId)

      if (error) {
        throw error
      } else {
        refreshOnSet && location.reload()
      }
    } catch (error) {
      logError("update username", error)
    } finally {
      setLoading(false)
      setIsEditing(false)
    }
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <label htmlFor="username">Username</label>
        {!isEditing ? (
          <>
            {username || "[None set]"}
            {" - "}
            <button
              onClick={event => {
                event.preventDefault()
                setIsEditing(true)
              }}
            >
              edit
            </button>
          </>
        ) : (
          <>
            <input
              id="username"
              type="text"
              value={username || ""}
              onChange={e => setUsername(e.target.value)}
            />
            <button className="button" type="submit" disabled={loading}>
              {loading ? "Updating..." : "Update"}
            </button>
          </>
        )}
      </form>
    </>
  )
}
