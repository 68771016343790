import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"
import styled from "styled-components/macro"

import { MainWrapper, OneCol } from "@/styles/Layout"
import LoomVideo from "@/views/_components/LoomVideo"

export default () => {
  return (
    <MainWrapper>
      <Helmet>
        <title>About | Protolang</title>
      </Helmet>
      <OneCol>
        <h1>About</h1>
        {/* <LetterWrapper>
          <p>Protolang was born out of my personal frustration learning Italian.</p>
          <p>
            In 2022, I went to Italy for the first time to visit family. Before I left, I spent
            months on Duolingo's Italian course, diligently following lessons and building a streak.
            But as soon as I arrived in Italy, it was obvious that my conversational Italian was
            non-existent.
          </p>
          <p>
            Even worse, when I tried speaking with my Italian relatives, I didn't know basic words
            like “grandmother”. Duolingo taught me how to say “dolphin” and “monkey”—but not the
            words that actually mattered to me.
          </p>
          <p>
            After airing my frustrations to friends, it was obvious I wasn't alone. Lots of language
            learners struggle to make progress with the current generation of “gameified” apps. Many
            feel constrained by the strict learning path, or the condescending tone. And despite
            their earnest effort, most app users never seem to have even a single conversation in
            their target language.
          </p>
          <p>
            I started looking around for other options, and what I found was fascinating: decades of
            science on second language acquisition, massive free databases of translation and
            pronunciation data, hundreds of communities sharing content and learning together, and a
            new generation of AI that can simulate real conversation on the fly. The only thing
            missing was a tool to bring it all together.
          </p>
          <p>
            That's my hope for Protolang: that we bring the best ideas in language learning together
            into a powerful learning tool.
          </p>
          <p>
            Read on to hear about our unique approach to online language learning, or see how it
            works for yourself.
          </p>
          <p>Buon viaggio!</p>
          <div style={{ fontFamily: "cursive", fontSize: "var(--xl)" }}>Sam</div>
          <div style={{ fontSize: "var(--s)", margin: "-.25rem 0 0" }}>Creator of Protolang</div>
        </LetterWrapper> */}

        <p>Hi! Thanks for checking out Protolang :-)</p>
        <p>
          Protolang is a free language learning website. My goal is to help motivated language
          learners get to practical proficiency in their new language as quickly as possible.
        </p>
        <p>
          Questions/comments/feedback? <Link to="/contact">Contact me</Link>.
        </p>
        <p>- Sam</p>
        <LoomVideo id="ae393b1448524717bce13e1e13fa653c" />
        {/* <p>We believe that:</p>

      <ul>
        <li>
          Everyone deserves to learn and interact with their community, digitally and in person, in their native language
        </li>
        <li>
          Diversity of language creates diversity of thought, and diversity of thought creates stronger communities
        </li>
        <li>
          The world would be a better place if more people spoke the languages of other cultures
        </li>
        <li>
          We should preserve endangered languages for future generations
        </li>
        <li>
          Big challenges (like learning new languages) require powerful tools and supportive communities
        </li>
        <li>
          Learning things online should be free for anyone in the world
        </li>
        <li>
          Self-directed learning tools are key to the future of education
        </li>
        <li>
          Free content and open content create the best learning experiences
        </li>
        <li>
          Small, dispersed groups of passionate people can build amazing things
        </li>
      </ul> */}
        {/* <p>Protolang is run by <a href="https://directedworks.com/">Directed Works</a>.</p> */}
      </OneCol>
    </MainWrapper>
  )
}

const LetterWrapper = styled.div`
  max-width: 32rem;
  margin: 0 auto;
  margin-bottom: 2rem;
`
