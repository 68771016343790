import { useState } from "react"
import styled from "styled-components/macro"

import { useLanguage } from "@/context/language"
import { useUser } from "@/context/user"
import OnboardingStep from "@/views/_components/OnboardingStep"
import SpeakableInput from "@/views/_components/SpeakableInput"
import {
  getResponse,
  punctuateText,
  saveUsersMessage,
  validateUsersMessage,
} from "@/utils/conversationHelpers"
import { logError } from "@/utils/error"
import ConversationHints from "@/views/conversations/ConversationHints"

const BLOCK_INVALID_MESSAGES = false

export default ({
  conversationMessages,
  isSendingMessage,
  setIsSendingMessage,
  setResponderIsTyping,
  responderIsTyping,
  conversation,
  situation,
  isConvoCreator,
}) => {
  const { userLoading, userId, userProfile } = useUser()
  const { startingLanguage, currentLanguage, currentUserLanguage } = useLanguage()

  const [message, setMessage] = useState("")
  const [receiveError, setReceiveError] = useState(null)

  const submitConversationMessage = async () => {
    if (!userId) {
      alert("Sorry, you must be logged in to have a conversation")
      return
    }
    if (!message || message.trim().length === 0) return

    setIsSendingMessage(true)
    let messageWithPunctuation: string
    try {
      messageWithPunctuation = await punctuateText({ currentLanguage, message })
    } catch (error) {
      logError("format punctuation", error, true)
    }

    let userMessage
    try {
      userMessage = await saveUsersMessage({
        conversation,
        userId,
        message: messageWithPunctuation,
      })
      setMessage("")
    } catch (error) {
      logError("save your message", error)
      setIsSendingMessage(false)
      // this one matters - if it errors, stop the show and have them try again
      return
    }

    let isValid: boolean
    try {
      isValid = await validateUsersMessage({
        startingLanguage,
        currentLanguage,
        conversation,
        message: messageWithPunctuation,
      })
    } catch (error) {
      logError("check your message's grammar", error, true)
    }
    setIsSendingMessage(false)
    if (!isValid && BLOCK_INVALID_MESSAGES) return

    // we manually generate the latest set of messages
    // realtime may update them, but we don't have that info or want to rely on that
    // because supabase realtime is not on the client.
    // If we DON'T do this, the messages will get sent, but without the latest user message
    const currentMessages = [...conversationMessages, userMessage]
    getResponseForUserMessage(currentMessages)
  }

  const getResponseForUserMessage = async currentMessages => {
    setResponderIsTyping(true)
    setReceiveError(null)
    try {
      await getResponse({
        startingLanguage,
        currentLanguage,
        currentUserLanguage,
        conversation,
        conversationMessages: currentMessages,
        situation,
        userGender: userProfile?.refer_to_as_gender,
      })
    } catch (error) {
      setReceiveError(error)
      logError("get a conversation response", error, true)
    }
    setResponderIsTyping(false)
  }

  const disabled =
    isSendingMessage ||
    userLoading ||
    !userId ||
    !isConvoCreator ||
    !conversation ||
    responderIsTyping ||
    conversationMessages.length === 0

  return (
    <OnboardingStep
      hide={disabled}
      popoverContent={
        <>
          <h3>Click here to speak your answer</h3>
          <p>You can also type it if you want</p>
          <p>(Not sure what to say? "Ciao" is always a good start)</p>
        </>
      }
      side={"top"}
      buttonText="OK"
      flagName="has_completed_convo_input_onboarding"
      dependsOn={[
        "has_completed_chat_welcome_onboarding",
        "has_completed_situation_onboarding",
        "has_completed_convo_message_onboarding",
      ]}
    >
      <ConversationInputWrapper>
        <form
          onSubmit={event => {
            event.preventDefault()
            submitConversationMessage()
          }}
        >
          {receiveError && (
            <div style={{ fontSize: "var(--s)", color: "red", margin: ".5rem 0" }}>
              <p>
                Sorry, we couldn't get a chat response right now. Our servers may be overwhelmed.
                Try again in a minute?
              </p>
              <button
                className="button"
                onClick={() => {
                  setReceiveError(null)
                  getResponseForUserMessage(conversationMessages)
                }}
              >
                Try again
              </button>
            </div>
          )}
          <SpeakableInput
            value={message}
            disabled={disabled}
            setValue={setMessage}
            onSubmit={submitConversationMessage}
            minRows={1}
            bigRecordButton
            // placeholder={`Type something in ${currentLanguage.name_eng}, or...`}
          />
        </form>
        <ConversationHints
          conversationMessages={conversationMessages}
          situation={situation}
          useHint={setMessage}
          disabled={disabled}
        />
      </ConversationInputWrapper>
    </OnboardingStep>
  )
}

const ConversationInputWrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`
