// import styled from 'styled-components/macro'

interface Props {
  name: string
  size?: "x-small" | "small" | "medium" | "large" | "x-large" | "xx-large"
  filled?: boolean
  inline?: boolean
}

// https://fonts.google.com/icons
const Icon = ({ name, size, filled, inline }: Props) => {
  const fontVariationSettings = `'FILL' ${filled ? 1 : 0}, 'wght' 300, 'GRAD' 0, 'opsz' 20`
  const fontSize =
    size === "x-small"
      ? "80%"
      : size === "small"
      ? "100%"
      : size === "medium"
      ? "120%"
      : size === "large"
      ? "160%"
      : size === "x-large"
      ? "200%"
      : size === "xx-large"
      ? "300%"
      : "120%" // match the size of the parent text
  return (
    <span
      className="material-symbols-rounded"
      style={{
        fontSize,
        display: inline ? "inline-block" : "block",
        fontVariationSettings,
        color: !name ? "red" : "inherit",
      }}
    >
      {name || "error"}
    </span>
  )
}

export default Icon
