import { useForm, ValidationError } from "@formspree/react"
import { useState } from "react"
import ReactTextareaAutosize from "react-textarea-autosize"

import { useUser } from "@/context/user"
import Modal from "@/styles/Modal"

// https://formspree.io/forms/xnqyjjgl/integration
// https://formspr.ee/react-help
export default () => {
  const { userAuth } = useUser()

  const [isGivingFeedback, setIsGivingFeedback] = useState(false)
  const [state, handleSubmit] = useForm("xnqyjjgl")

  return (
    <>
      <button className="button button-small" onClick={() => setIsGivingFeedback(true)}>
        Share feedback
      </button>

      <Modal
        title="Share feedback"
        isOpen={isGivingFeedback}
        onClose={() => setIsGivingFeedback(false)}
        width="460px"
      >
        {!state.succeeded && !state.errors?.length && (
          <p>
            Protolang is under active development. Your feedback is incredibly valuable to us as we
            build.
          </p>
        )}
        {state.succeeded && (
          <p
            style={{
              color: "var(--bg)",
              background: "var(--text)",
              fontWeight: "bold",
              padding: ".5rem 1rem",
            }}
          >
            Thanks for your feedback!
          </p>
        )}
        {state.errors && state.errors.length > 0 && <p>{state.errors[0]?.message}</p>}

        <form onSubmit={handleSubmit}>
          <input type="hidden" id="form" name="form" value="general-feedback" />
          <label htmlFor="email">Your email</label>
          <input
            id="email"
            type="email"
            name="email"
            placeholder="your@email.com"
            defaultValue={userAuth?.email || ""}
            // this prevents email from being sent
            // disabled={userAuth && userAuth.email || state.submitting || state.succeeded}
            required
            style={{ width: "100%" }}
          />
          <ValidationError prefix="Email" field="email" errors={state.errors} />

          <br />

          <label htmlFor="message">Message</label>
          <ReactTextareaAutosize
            id="message"
            name="message"
            disabled={state.submitting || state.succeeded}
            minRows={3}
            placeholder="Comments, questions, or feedback"
            required
          />
          <ValidationError prefix="Message" field="message" errors={state.errors} />

          <br />

          <button className="button" type="submit" disabled={state.submitting || state.succeeded}>
            Send feedback
          </button>
        </form>
      </Modal>
    </>
  )
}
