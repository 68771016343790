import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"
import styled from "styled-components/macro"

import { supabase, useSupabaseQuery } from "@/db/supabase"
import { MainWrapper } from "@/styles/Layout"
import ErrorComponent from "@/views/errors/ErrorComponent"

export default () => {
  const query = supabase.from("languages").select()
  const [languages, loading, error] = useSupabaseQuery(query)

  const liveLanguages = languages?.filter(
    l => l.publish_status === "general_availability" || l.publish_status === "beta_testing"
  )
  const soonLanguages = languages?.filter(
    l => l.publish_status === "not_started" || l.publish_status === "in_development"
  )

  if (loading) return "loading..."
  if (error) return <ErrorComponent error={error} />

  return (
    <MainWrapper>
      <Helmet>
        <title>All languages | Protolang</title>
      </Helmet>
      <div style={{ textAlign: "left", margin: "2rem 0" }}>
        <h1>Choose your language</h1>
        {liveLanguages?.map(language => {
          return (
            <Link
              key={language.id}
              to={`/${language.slug_eng}`}
              className="button"
              style={{ padding: "1rem 2rem" }}
            >
              {language.name_eng}
              {language.publish_status === "beta_testing" && " (beta)"}
            </Link>
          )
        })}
      </div>

      <br />
      <h3>Coming soon</h3>
      <LanguageListWrapper>
        {soonLanguages?.map(language => {
          return (
            <div key={language.id}>
              <Link to={`/${language.slug_eng}`}>
                {language.name_eng}
                {language.name_eng !== language.endonym && ` · ${language.endonym}`}
              </Link>
            </div>
          )
        })}
      </LanguageListWrapper>
    </MainWrapper>
  )
}

const LanguageListWrapper = styled.div`
  columns: 3;
  column-gap: 1rem;
  @media (max-width: 900px) {
    columns: 2;
  }
  @media (max-width: 600px) {
    columns: 1;
  }
`
