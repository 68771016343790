import styled from "styled-components/macro"
import { useDebounce } from "use-debounce"

import { useLanguage } from "@/context/language"
import Definable from "@/views/_components/Definable"
import RepeatableMic from "@/views/_components/RepeatableMic"
import SpeakWord from "@/views/_components/SpeakWord"
import MnemonicSuggested from "@/views/mnemonics/MnemonicSuggested"

export default props => {
  const { startingLanguage, currentLanguage } = useLanguage()

  const initialL1 = props[`${startingLanguage.iso639_3}`]
  const initialL2 = props[`${currentLanguage.iso639_3}`]
  const initialL2Alt = props[`${currentLanguage.iso639_3}Alt`]
  const initialL2Alts = props[`${currentLanguage.iso639_3}Alts`]
  const initialL1Alt = props[`${startingLanguage.iso639_3}Alt`]
  const initialL1Alts = props[`${startingLanguage.iso639_3}Alts`]
  const initialContextL1 = props[`${startingLanguage.iso639_3}Context`]

  const [word_l2_dirty] = useDebounce(initialL2, 500, { leading: true })
  const [word_l1_dirty] = useDebounce(initialL1, 500, { leading: true })
  const [word_l2_alt_dirty] = useDebounce(initialL2Alt, 500, { leading: true })
  const [word_l1_alt_dirty] = useDebounce(initialL1Alt, 500, { leading: true })
  const [word_l2_alts_dirty] = useDebounce(initialL2Alts, 500, {
    leading: true,
  })
  const [word_l1_alts_dirty] = useDebounce(initialL1Alts, 500, {
    leading: true,
  })
  const [context_l1_dirty] = useDebounce(initialContextL1, 500, {
    leading: true,
  })

  // TODO - this is whack
  const word_l2_alts_parsed =
    word_l2_alts_dirty
      ?.replaceAll("']", "")
      .replaceAll("['", "")
      .split(`', '`)
      .map(word => word.trim()) || []
  const word_l1_alts_parsed =
    word_l1_alts_dirty
      ?.replaceAll("']", "")
      .replaceAll("['", "")
      .split(`', '`)
      .map(word => word.trim()) || []

  const word_l2 = word_l2_dirty?.replaceAll("*", "").replaceAll("_", "") //.replace('.','\\.')
  const word_l1 = word_l1_dirty?.replaceAll("*", "").replaceAll("_", "") //.replace('.','\\.')
  const word_l2_alt = word_l2_alt_dirty?.replaceAll("*", "").replaceAll("_", "")
  const word_l1_alt = word_l1_alt_dirty?.replaceAll("*", "").replaceAll("_", "")
  const context_l1 = context_l1_dirty

  const word_l2_alts = [word_l2_alt, ...word_l2_alts_parsed].filter(Boolean)
  const word_l1_alts = [word_l1_alt, ...word_l1_alts_parsed].filter(Boolean)

  return (
    <LessonEmbedErrorWrapper>
      <div>
        <RepeatableMic correctAnswer={word_l2} />
      </div>
      <div>
        {word_l2 && (
          <div style={{ display: "flex" }}>
            <strong>
              <Definable wordString={word_l2} />
            </strong>
            <SpeakWord wordString={word_l2} />
            <Alternates alts={word_l2_alts} />
          </div>
        )}
        {word_l1 && (
          <div
            style={{
              display: "flex",
              lineHeight: "1",
              margin: ".5rem 0 .25rem",
            }}
          >
            <Definable wordString={word_l1} language={startingLanguage} />
            <Alternates alts={word_l1_alts} language={startingLanguage} />
            {context_l1 && (
              <div
                style={{
                  fontSize: "var(--s)",
                  display: "flex",
                  alignItems: "flex-end",
                  margin: "0 0 0 .25rem",
                }}
              >
                ({context_l1})
              </div>
            )}
          </div>
        )}
        <MnemonicSuggested wordString={word_l2} />
      </div>
    </LessonEmbedErrorWrapper>
  )
}

const Alternates = ({ alts, language }) => {
  const { currentLanguage } = useLanguage()

  if (!alts || alts.length === 0) return null
  return (
    <div
      style={{
        fontSize: "var(--s)",
        display: "flex",
        alignItems: "flex-end",
        margin: "0 0 0 .25rem",
      }}
    >
      or
      <span style={{ display: "inline-block", width: ".15rem" }} />
      {alts.map((alt, index) => {
        return (
          <span key={index}>
            <Definable wordString={alt} language={language || currentLanguage} />
            {index !== alts.length - 1 && (
              <>
                ,<span style={{ display: "inline-block", width: ".15rem" }} />
              </>
            )}
          </span>
        )
      })}
    </div>
  )
}

const LessonEmbedErrorWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: max-content auto max-content;
  gap: 0.5rem;
  border: 1px solid;
  padding: 1rem 1rem 1rem 0.5rem;
  margin: 1rem 0;
  box-shadow: 1px 1px var(--shadow);
  background: var(--bg);

  // smoosh siblings together in a block
  & + & {
    margin-top: calc((1rem + 1px) * -1);
  }
`
