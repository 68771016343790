import { Link } from "react-router-dom"
// import styled from 'styled-components/macro'
import { useEffect, useState } from "react"

import { useLanguage } from "@/context/language"
import { useUser } from "@/context/user"
import { supabase } from "@/db/supabase"
import ErrorComponent from "@/views/errors/ErrorComponent"

export default () => {
  const { userId, isAlphaTester } = useUser()
  const { currentLanguage } = useLanguage()

  const [wordScore, setWordScore] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const getWordScore = async () => {
    if (!currentLanguage.id) {
      setError("Missing language")
      return
    }
    if (!userId) {
      setError("Missing user")
      return
    }
    try {
      console.log("🏃 triggering db function get_user_word_score")
      setLoading(true)
      const { data, error } = await supabase.rpc("get_user_word_score", {
        lang_id: currentLanguage.id,
        user_id: userId,
      })
      if (error) throw error
      setError(null)
      if (error) throw error
      setWordScore(data)
      setLoading(false)
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    getWordScore()
  }, [])

  const currentScore = wordScore || 0

  if (!userId)
    return (
      <p>
        <Link to={`/login?redirectTo=${encodeURIComponent(window.location.pathname)}`}>Log in</Link>{" "}
        to see your vocabulary
      </p>
    )
  if (loading) return "..."
  if (error) return <ErrorComponent error={error} />
  if (!isAlphaTester) return <>Free preview account</>
  if (currentScore === 0) return "No words learned yet"

  return (
    <>
      <strong>{currentScore}</strong> word vocabulary
    </>
  )
}
