// import styled from 'styled-components/macro'
import { useLanguage } from "@/context/language"
import { supabase, useSupabaseQuery } from "@/db/supabase"
import Definable from "@/views/_components/Definable"
import SpeakWord from "@/views/_components/SpeakWord"
// import ReportIssue from '@/views/practice/ReportIssue'

const WORD_QUOTE_CUTOFF = 3

export default () => {
  const { currentLanguage, currentUserLanguage } = useLanguage()

  const dateSlug = new Date().toLocaleString("it-IT", {
    month: "long",
    day: "numeric",
  })

  const quoteQuery = supabase
    .from("quotes_of_the_day")
    .select()
    .eq("language_id", currentLanguage.id)
    .eq("date_slug", dateSlug)
    .single()
  const [quote, quoteLoading, quoteError] = useSupabaseQuery(quoteQuery, [currentLanguage.id])

  const wordQuery = supabase
    .from("words_of_the_day")
    .select()
    .eq("language_id", currentLanguage.id)
    .eq("date_slug", dateSlug)
    .single()
  const [word, wordLoading, wordError] = useSupabaseQuery(wordQuery, [currentLanguage.id])

  return (
    <div>
      {quoteLoading || wordLoading ? (
        "loading..."
      ) : quoteError || wordError ? (
        (quoteError || wordError).message
      ) : currentUserLanguage?.proficiency_level > WORD_QUOTE_CUTOFF ? (
        <>
          <h4>Quote of the day</h4>
          <div>
            "<Definable wordString={quote?.quote} $inline splitByWord />"
            <SpeakWord wordString={quote?.quote} />
          </div>
          <div
            style={{ fontSize: "var(--s)" }}
            dangerouslySetInnerHTML={{ __html: "- " + quote?.source_html }}
          />
          {/* <div style={{fontSize: 'var(--s)'}}><ReportIssue quote={quote} /></div> */}
        </>
      ) : (
        <>
          <h4>Word of the day</h4>
          <div style={{ fontSize: "var(--l)" }}>
            <Definable wordString={word.word} />
            <SpeakWord wordString={word.word} />
          </div>
        </>
      )}
    </div>
  )
}

// const SpeechBubble = styled.div`
//   background: var(--bg);
//   padding: 1rem;
//   margin: 0 0 .5rem 0;
//   border: 1px solid;
//   position: relative;
//   font-size: var(--l);

//   &:before {
//     content: '';
//     position: absolute;
//     bottom: -11px;
//     left: 2rem;
//     width: 0;
//     height: 0;
//     border: 11px solid transparent;
//     border-top-color: inherit;
//     border-bottom: 0;
//     border-left: 0;
//   }
//   &:after {
//     content: '';
//     position: absolute;
//     bottom: -9px;
//     left: 2rem;
//     width: 0;
//     height: 0;
//     border: 9px solid transparent;
//     border-top-color: var(--bg);
//     border-bottom: 0;
//     border-left: 0;
//     transform: translateX(1px);
//   }
// `
