import { Link } from "react-router-dom"

import { useLanguage } from "@/context/language"
import { useUser } from "@/context/user"
import { supabase, useSupabaseQuery } from "@/db/supabase"
import HelpButton from "@/views/_components/HelpButton"

export default () => {
  const { userId } = useUser()
  const { currentLanguage } = useLanguage()

  const query = supabase
    .from("user_phrase_scores")
    .select("*, phrase_id(*)")
    .eq("created_by", userId)
    .lt("percent_correct", 0.5)
    .order("percent_correct", { ascending: true })
    .limit(5)
  const [phraseScores, loading, error] = useSupabaseQuery(query, [userId])

  return (
    <div>
      <h4>
        Problem phrases
        <HelpButton helpText="Phrases you have the most trouble with" />
      </h4>
      {!userId ? (
        <>
          <Link to="/signup">Create an account</Link> to see the phrases you make the most mistakes
          on
        </>
      ) : error ? (
        error.message
      ) : loading ? (
        "loading..."
      ) : !phraseScores || phraseScores.length <= 0 ? (
        "(No problem phrases right now)"
      ) : (
        phraseScores?.map(phraseScore => {
          return (
            <div key={phraseScore.phrase_id.id}>
              <Link to={`/${currentLanguage.slug_eng}/phrase/${phraseScore.phrase_id.random_slug}`}>
                {phraseScore.phrase_id.content_l2}
              </Link>
              {" - "}
              {Math.round(phraseScore.percent_correct * 100)}% correct
            </div>
          )
        })
      )}
    </div>
  )
}
