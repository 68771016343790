import { useState } from "react"
import styled from "styled-components/macro"

import { useLanguage } from "@/context/language"
import { useSoundFx } from "@/context/soundEffects"
import { supabase } from "@/db/supabase"
import { AvatarCharacter } from "@/styles/Avatars"
import Typing from "@/styles/Typing"
import { ConversationMessages, ConversationSituations } from "@/types/schema"
import { logError } from "@/utils/error"
import Definable from "@/views/_components/Definable"
import ReportIssue from "@/views/_components/ReportIssue"
import SpeakWord from "@/views/_components/SpeakWord"
// import { fromNow } from "@/utils/date"

interface ConversationMessageProps {
  message: ConversationMessages
  situation?: ConversationSituations
  autoPlaySpeech?: boolean
}
export default ({ message, situation, autoPlaySpeech }: ConversationMessageProps) => {
  const [showTranslation, setShowTranslation] = useState(false)
  const [loading, setLoading] = useState(false)
  const [translation, setTranslation] = useState(null)

  const { startingLanguage, currentLanguage } = useLanguage()
  const { repeatOn } = useSoundFx()

  const getTranslation = async () => {
    if (!message.content) return
    try {
      setLoading(true)
      // only target lang uses EN-US or EN-GB, source is always EN for english
      // https://www.deepl.com/docs-api/translate-text/translate-text/
      const source_lang = currentLanguage.iso639_1.toUpperCase()
      const target_lang =
        startingLanguage.iso639_1 === "en" ? "EN-US" : startingLanguage.iso639_1.toUpperCase()
      const body = {
        text: message.content,
        source_lang,
        target_lang,
      }
      console.log("🏃 triggering edge function deepl_translate", body)
      const { data, error } = await supabase.functions.invoke("deepl_translate", {
        body,
      })
      setLoading(false)
      if (error) throw error
      setTranslation(data?.translations[0].text)
    } catch (error) {
      setLoading(false)
      logError("translate conversation message", error)
    }
  }

  return (
    <ConversationMessageWrapper key={message.id} sender={message.sender_type}>
      {message.sender_type === "assistant" && situation?.character_id?.avatar_file && (
        <AvatarCharacter>
          <img
            src={`/images/conversation_avatars/victoruler/${situation?.character_id?.avatar_file}`}
            alt={situation?.character_id?.name}
          />
        </AvatarCharacter>
      )}
      <div>
        <ConversationMessageBubble sender={message.sender_type}>
          {message.content ? (
            <>
              <div>
                <Definable wordString={message.content} splitByWord />
              </div>
              <div
                style={{
                  filter: message.sender_type === "user" && "invert(1) hue-rotate(180deg)",
                  margin: "-0.15rem -0.33rem", // offset button-small padding
                }}
              >
                <SpeakWord
                  wordString={message.content}
                  autoPlay={autoPlaySpeech && repeatOn}
                  gender={situation?.character_id?.gender}
                  age={situation?.character_id?.age}
                />
              </div>
            </>
          ) : (
            <>
              <Typing />
              <div style={{ width: ".5rem" }} />
            </>
          )}
        </ConversationMessageBubble>
        {message.content && (
          <>
            {loading && <Typing />}
            {showTranslation && translation && <div>{translation}</div>}
            <ConversationMessageTime>
              {/* {message.fake_time || fromNow(message.created_at)}
          {' · '} */}
              <button
                onClick={() => {
                  if (!translation) getTranslation()
                  setShowTranslation(!showTranslation)
                }}
              >
                {showTranslation ? "hide translation" : "full translation"}
              </button>
              {" · "}
              <ReportIssue conversationMessage={message} />
            </ConversationMessageTime>
          </>
        )}
      </div>
    </ConversationMessageWrapper>
  )
}

// TODO - https://stackoverflow.com/a/44051405
const ConversationMessageWrapper = styled.div`
  display: grid;
  grid-template-columns: ${props => (props.sender === "assistant" ? "auto 1fr" : "1fr auto")};
  grid-gap: 1rem;
  margin: 0 0 1rem;
  text-align: ${props => (props.sender === "assistant" ? "left" : "right")};
`
const ConversationMessageBubble = styled.div`
  display: inline-flex;
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  left: ${props => (props.sender === "assistant" ? "0" : "auto")};
  right: ${props => (props.sender === "user" ? "0" : "auto")};
  gap: 0.5rem;

  font-size: var(--m);
  background: ${props => (props.sender === "assistant" ? "var(--badge)" : "var(--text)")};
  color: ${props => (props.sender === "assistant" ? "var(--text)" : "var(--badge)")};
  /* border-radius: 50px; */
`
const ConversationMessageTime = styled.div`
  margin: 0.25rem 0 0 0;
  font-size: var(--s);
`
