import EditTopics from "@/views/dashboard/EditTopics"

export default () => {
  return (
    <>
      <h1>Language goals</h1>
      <EditTopics />

      {/* TODO - Goal date,  */}
    </>
  )
}
