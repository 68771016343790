import { useSoundFx } from "@/context/soundEffects"
import * as ToggleGroup from "@radix-ui/react-toggle-group"
import styled from "styled-components/macro"

// import useMediaQuery from '@/hooks/useMediaQuery'

export default ({ items, value, onChange }) => {
  // const isDesktop = useMediaQuery('(min-width: 600px)')
  const { playTap } = useSoundFx()

  if (!items?.length) return null
  return (
    <>
      <HorizontalFilterWrapper
        type="single"
        value={value}
        onValueChange={onChange}
        aria-label="Filter by topic"
      >
        {items.map((item, i) => {
          return (
            <HorizontalFilterItem
              key={i}
              value={item.value}
              aria-label={item.name}
              onClick={playTap}
            >
              {item.name}
            </HorizontalFilterItem>
          )
        })}
      </HorizontalFilterWrapper>
    </>
  )
}

const HorizontalFilterWrapper = styled(ToggleGroup.Root)`
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  margin: 0 calc(var(--gutter) * -1);
  padding: 0.5rem var(--gutter);
  background: var(--bg);
  z-index: 300;
  max-width: 100vw;
  overflow-x: auto;

  // like border-bottom, but with margins for crispy left edges
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: var(--gutter);
    right: var(--gutter);
    height: 1px;
    background: var(--text);
  }
`
const HorizontalFilterItem = styled(ToggleGroup.Item)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  margin: 0.25rem;
  border-radius: 3px;
  background: var(--bg);
  color: var(--text);
  border: 1px solid;
  box-shadow: 1px 1px;
  font-weight: 500;
  user-select: none;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.15s ease;

  &:focus,
  &:hover {
    outline: none;
    transform: translate(-1px, -1px);
    box-shadow: 2px 2px;
  }
  &[data-state="on"] {
    background: var(--text);
    color: var(--bg);
    border-color: var(--text);
    transform: translate(1px, 1px);
    box-shadow: 0px 0px;
  }
`
