// https://en.wikipedia.org/wiki/International_English_Language_Testing_System

export default [
  {
    id: 0,
    title: "Getting started",
    description: "Finish a few lessons to get to level 1",
    IELTS_score: 0,
    IELTS_label: "Did not attempt the test",
    IELTS_description: "No assessable information provided at all.",
    CEFR_level: null,
    CEFR_description: null,
  },
  {
    id: 1,
    title: "Novice",
    description: "You know a few isolated words and phrases",
    IELTS_score: 1,
    IELTS_label: "Non User",
    IELTS_description: "Essentially has no ability to use the language beyond possibly a few isolated words.",
    CEFR_level: "A0",
    CEFR_description: "Not started",
  },
  {
    id: 2,
    title: "Beginner",
    description: "You can understand and use familiar everyday expressions",
    IELTS_score: 2,
    IELTS_label: "Intermittent User",
    IELTS_description: "No real communication is possible except for the most basic information using isolated words or short formulae in familiar situations and to meet immediate needs. Has great difficulty understanding spoken and written content.",
    CEFR_level: "A1",
    CEFR_description: "Basic user - Breakthrough",
  },
  {
    id: 3,
    title: "Advanced beginner",
    description: "You can understand general meaning in familiar situations, but still have frequent breakdowns in communication",
    IELTS_score: 3,
    IELTS_label: "Extremely Limited User",
    IELTS_description: "Conveys and understands only general meaning in very familiar situations. Frequent breakdowns in communication occur.",
    CEFR_level: "A2",
    CEFR_description: "Basic user - Waystage",
  },
  {
    id: 4,
    title: "Intermediate",
    description: "You are basically competent in familiar situations",
    IELTS_score: 4,
    IELTS_label: "Limited User",
    IELTS_description: "Basic competence is limited to familiar situations. Has frequent problems in understanding and expression. Is not able to use complex language.",
    CEFR_level: "B1",
    CEFR_description: "Independent user - Threshold",
  },
  {
    id: 5,
    title: "Advanced intermediate",
    description: "You have partial command of the language, grasping overall meaning in most situations",
    IELTS_score: 5,
    IELTS_label: "Modest user",
    IELTS_description: "Has partial command of the language, coping with overall meaning in most situations, though is likely to make many mistakes. Should be able to handle basic communication in own field.",
    CEFR_level: "B2",
    CEFR_description: "Independent user - Vantage",
  },
  {
    id: 6,
    title: "Advanced",
    description: "You have operational command of the language and detailed reasoning, though with occasional inaccuracies and misunderstandings in some situations",
    IELTS_score: 7,
    IELTS_label: "Good User",
    IELTS_description: "Has operational command of the language, though with occasional inaccuracies, inappropriateness and misunderstandings in some situations. Generally handles complex language well and understands detailed reasoning.",
    CEFR_level: "C1",
    CEFR_description: "Proficient user - Advanced",
  },
  {
    id: 7,
    title: "Fluent",
    description: "You have full operational command of the language: appropriate, accurate and fluent with complete understanding",
    IELTS_score: 9,
    IELTS_label: "Expert User",
    IELTS_description: "Has full operational command of the language: appropriate, accurate and fluent with complete understanding.",
    CEFR_level: "C2",
    CEFR_description: "Proficient user - Mastery",
  },
]