import styled from "styled-components/macro"
import { useDebounce } from "use-debounce"

import { useLanguage } from "@/context/language"
import { useUser } from "@/context/user"
import { supabase, useSupabaseQuery } from "@/db/supabase"
import Definable from "@/views/_components/Definable"
import ReportIssue from "@/views/_components/ReportIssue"
import SpeakWord from "@/views/_components/SpeakWord"
import MnemonicSuggested from "@/views/mnemonics/MnemonicSuggested"
import PhraseEdit from "@/views/practice/PhraseEdit"
import PhraseNew from "@/views/practice/PhraseNew"
import SaveButton from "@/views/saved/SaveButton"

export default ({
  simple,
  phrase: {
    ita: initialL2,
    eng: initialL1,
    itaAlt: initialL2Alt,
    itaAlts: initialL2Alts,
    engAlt: initialL1Alt,
    engAlts: initialL1Alts,
    engContext: initialL1Context,
    ...rest
  },
}) => {
  const unaccountedForParamsLength = Object.keys(rest).length - 1
  if (unaccountedForParamsLength > 0) console.warn("unaccountedForParams in ...rest:", rest)

  const { isAdmin } = useUser()
  const { startingLanguage } = useLanguage()

  const [word_L2_dirty] = useDebounce(initialL2, 500, { leading: true })
  const [word_L1_dirty] = useDebounce(initialL1, 500, { leading: true })
  const [word_L2_alt_dirty] = useDebounce(initialL2Alt, 500, { leading: true })
  const [word_L1_alt_dirty] = useDebounce(initialL1Alt, 500, { leading: true })
  const [word_L2_alts_dirty] = useDebounce(initialL2Alts, 500, {
    leading: true,
  })
  const [word_L1_alts_dirty] = useDebounce(initialL1Alts, 500, {
    leading: true,
  })
  const [context_L1_dirty] = useDebounce(initialL1Context, 500, {
    leading: true,
  })

  // TODO - this is whack
  const word_L2_alts_parsed =
    word_L2_alts_dirty
      ?.replaceAll("']", "")
      .replaceAll("['", "")
      .split(`', '`)
      .map(word => word.trim()) || []
  const word_L1_alts_parsed =
    word_L1_alts_dirty
      ?.replaceAll("']", "")
      .replaceAll("['", "")
      .split(`', '`)
      .map(word => word.trim()) || []

  const word_L2 = word_L2_dirty?.replaceAll("*", "").replaceAll("_", "") //.replace('.','\\.')
  const word_L1 = word_L1_dirty?.replaceAll("*", "").replaceAll("_", "") //.replace('.','\\.')
  const word_L2_alt = word_L2_alt_dirty?.replaceAll("*", "").replaceAll("_", "")
  const word_L1_alt = word_L1_alt_dirty?.replaceAll("*", "").replaceAll("_", "")
  const context_L1 = context_L1_dirty

  const word_L2_alts = [word_L2_alt, ...word_L2_alts_parsed].filter(Boolean)
  const word_L1_alts = [word_L1_alt, ...word_L1_alts_parsed].filter(Boolean)

  // https://supabase.com/docs/reference/javascript/or
  const phraseQuery = supabase
    .from("phrases")
    .select()
    // https://postgrest.org/en/stable/api.html#reserved-characters
    .or(`content_l2.eq."${word_L2}",content_l1.eq."${word_L1}"`)
  const [phrases, phraseLoading, phraseError] = useSupabaseQuery(phraseQuery, [word_L1, word_L2])
  const phrase = phrases && phrases.length > 0 ? phrases[0] : null

  if (phraseLoading)
    return (
      <tr>
        <td>...</td>
      </tr>
    )

  const wordStringL1 = word_L1 || phrase?.content_l1
  const wordStringL2 = word_L2 || phrase?.content_l2

  return (
    <tr>
      {/* <td>
      <RepeatableMic correctAnswer={wordStringL2} />
    </td> */}
      <td>
        {!wordStringL2 ? (
          <span style={{ color: "red" }}>❌ not found</span>
        ) : (
          <>
            <strong>
              <Definable wordString={wordStringL2} />
            </strong>
            <SpeakWord wordString={wordStringL2} />
            {/* <Alternates alts={word_L2_alts} /> */}
          </>
        )}
      </td>
      <td>
        {!wordStringL1 ? (
          <span style={{ color: "red" }}>❌ not found</span>
        ) : (
          <>
            {wordStringL1}
            {/* <Alternates alts={word_L1_alts} language={startingLanguage} /> */}
            {/* {context_L1 && <div style={{fontSize: 'var(--s)', display: 'flex', alignItems: 'flex-end', margin: '0 0 0 .25rem'}}>({context_L1})</div>} */}
          </>
        )}
        {!simple && <MnemonicSuggested wordString={wordStringL2} />}
      </td>
      <td style={{ width: 0 }}>
        <SaveButton wordString={wordStringL2} />
      </td>
      <td style={{ fontSize: "var(--s)", textAlign: "right" }}>
        <strong>
          {((phraseError && phraseError.code === "PGRST116") || !phrase) && (
            <>
              <PhraseNew
                l2Word={word_L2}
                l1Word={word_L1}
                l2Alts={word_L2_alts}
                l1Alts={word_L1_alts}
                l1Context={context_L1}
              />
            </>
          )}
        </strong>
        {!simple && phrase && (
          <span style={{ display: "flex", gap: ".25rem" }}>
            {isAdmin && <PhraseEdit phrase={phrase} />}
            {" · "}
            <ReportIssue phrase={phrase} />
          </span>
        )}
      </td>
    </tr>
  )
}

const Alternates = ({ alts, language }) => {
  const { currentLanguage } = useLanguage()

  if (!alts || alts.length === 0) return null
  return (
    <div
      style={{
        fontSize: "var(--s)",
        display: "flex",
        alignItems: "flex-end",
        margin: "0 0 0 .25rem",
      }}
    >
      or
      <span style={{ display: "inline-block", width: ".15rem" }} />
      {alts.map((alt, index) => {
        return (
          <span key={index}>
            <Definable wordString={alt} language={language || currentLanguage} />
            {index !== alts.length - 1 && (
              <>
                ,<span style={{ display: "inline-block", width: ".15rem" }} />
              </>
            )}
          </span>
        )
      })}
    </div>
  )
}

export const LessonEmbedErrorWrapper = styled.tr`
  position: relative;
  display: grid;
  grid-template-columns: max-content auto max-content;
  gap: 0.5rem;
  border: 1px solid;
  padding: 1rem 1rem 1rem 0.5rem;
  margin: 1rem 0;
  box-shadow: 1px 1px var(--shadow);
  background: var(--bg);

  // smoosh siblings together in a block
  & + & {
    margin-top: calc((1rem + 1px) * -1);
  }
`
