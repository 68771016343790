import { useRef, useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components/macro"

const TIMES_PER_SECOND = 10

// https://codesandbox.io/s/cocky-antonelli-ekmium?file=/src/App.js:0-1220
export default ({ to }) => {
  const defaultChars = "Protolang"
  const alternativeChars = "pᴙøTöłånG"
  const timer = useRef<ReturnType<typeof setInterval>>()
  const [string, setString] = useState(defaultChars)
  const changeRandomLetter = () => {
    setString(prevString => {
      const index = Math.floor(Math.random() * prevString.length)
      const isOld = prevString[index] === defaultChars[index]
      const newChar = isOld ? alternativeChars[index] : defaultChars[index]
      const newString = prevString.substring(0, index) + newChar + prevString.substring(index + 1)
      return newString
    })
  }
  const startScramble = () => {
    console.log("🔁 start scramble")
    timer.current = setInterval(changeRandomLetter, 1000 / TIMES_PER_SECOND)
  }
  const stopScramble = () => {
    console.log("🔁 stop logo scramble")
    setString("Protolang")
    clearInterval(timer.current)
  }
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <LogoWrapper to={to || "/"} onMouseEnter={startScramble} onMouseLeave={stopScramble}>
        {string}
      </LogoWrapper>
      <BetaTag>beta</BetaTag>
    </div>
  )
}

const LogoWrapper = styled(Link)`
  font-weight: bold;
  text-decoration: none;
  margin: 0 0.5rem 0 0;
  width: 4.75rem;
`
const BetaTag = styled.div`
  color: #666;
  font-size: var(--s);
  transform: translateY(1px);
  user-select: none;
`
