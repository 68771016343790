// https://usehooks-ts.com/react-hook/use-copy-to-clipboard

type CopyFn = (text: string) => Promise<boolean> // Return success

export function useCopyToClipboard(): CopyFn {
  const copy: CopyFn = async text => {
    try {
      if (!navigator?.clipboard) {
        console.warn('Clipboard not supported')
        return false
      }
      await navigator.clipboard.writeText(text)
      console.log('📋 Copied text')
      return true
    } catch (error) {
      console.warn('Copy failed', error)
      return false
    }
  }
  return copy
}
