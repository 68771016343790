import styled from "styled-components/macro"

import { useLanguage } from "@/context/language"
import { useUser } from "@/context/user"
import { supabase, useSupabaseQuery } from "@/db/supabase"
import ErrorComponent from "@/views/errors/ErrorComponent"
import LessonListItem from "@/views/lessons/LessonListItem"

export default ({ lessons, lessonProgressData }) => {
  const { currentLanguage } = useLanguage()
  const { userId } = useUser()

  const savedLessonsQuery = supabase
    .from("saved_lessons")
    .select()
    .eq("created_by", userId)
    .eq("language_id", currentLanguage.id)
  const [savedLessons, loading, error] = useSupabaseQuery(savedLessonsQuery, [
    userId,
    currentLanguage.id,
  ])

  const mySavedLessons = lessons?.filter(l => savedLessons?.find(sl => sl.lesson_id === l.id))

  if (loading) return <div>Loading...</div>
  if (error) return <ErrorComponent error={error} />

  return (
    <LessonLevelContent>
      {!mySavedLessons?.length && `No saved lessons in ${currentLanguage.name_eng} yet`}
      {mySavedLessons?.map(lesson => (
        <LessonListItem
          key={lesson.random_slug}
          lesson={lesson}
          lessonProgressData={lessonProgressData}
        />
      ))}
    </LessonLevelContent>
  )
}

const LessonLevelContent = styled.div``
