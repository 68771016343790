// import { useEffect, useState } from 'react'
import styled from "styled-components/macro"

import { useUser } from "@/context/user"
import { normalizeString } from "@/utils/string"
import Definable from "@/views/_components/Definable"
import PhraseScore from "@/views/_components/PhraseScore"
import SpeakWord from "@/views/_components/SpeakWord"
import AnswerWord from "@/views/practice/AnswerWord"
import PhraseEdit from "@/views/practice/PhraseEdit"
import SaveButton from "@/views/saved/SaveButton"

// import { supabase, useSupabaseQuery } from '@/data/supabase'
// import CardDeck from '@/views/practice/CardDeck'
// import { TwoColumns } from '@/styles/Layout'
// import DailyProgress from '@/views/practice/DailyProgress'

// Play the sound in starting language
// play in target language
// see alternates in both languages
// see your score for this phrase
// See the lessons that include this phrase
// Diff the two phrases to see differences
// Show your answer with clickable words

export default ({ phrase }) => {
  const { isAdmin } = useUser()

  if (!phrase) return ""
  return (
    <>
      <PhraseWrapper>
        <strong style={{ fontSize: "var(--l)" }}>
          <Definable wordString={phrase.content_l2} />
          <SpeakWord wordString={phrase.content_l2} />
          <SaveButton wordString={phrase.content_l2} />
        </strong>
        {phrase.content_l2_alts && phrase.content_l2_alts.length > 0 && (
          <div>
            or{" "}
            {phrase.content_l2_alts?.map(alt => {
              return (
                <span key={alt}>
                  <Definable wordString={alt} />
                </span>
              )
            })}
          </div>
        )}

        <br />

        <p>{phrase.content_l1}</p>
        {phrase.content_l1_alts && phrase.content_l1_alts.length > 0 && (
          <p>Other meanings: {phrase.content_l1_alts?.join(", ")}</p>
        )}

        {isAdmin && <PhraseEdit phrase={phrase} />}

        {/* TODO - attempts list? */}
        <h3>
          Your accuracy: <PhraseScore phrase={phrase} />
        </h3>
      </PhraseWrapper>

      {phrase.content_l2
        ?.split(" ")
        .map(normalizeString)
        .map((word, i) => (
          <AnswerWord key={i} wordString={word} />
        ))}
    </>
  )
}

export const PhraseWrapper = styled.div`
  border: 1px solid;
  padding: 2rem;
  background: #fafafa;
  margin: 2rem 0;
`
