import styled from "styled-components/macro"

import { useLanguage } from "@/context/language"
import { useUser } from "@/context/user"
import { supabase, useSupabaseRealtimeQuery } from "@/db/supabase"
import Tooltip from "@/styles/Tooltip"
import { getStartOfDayForQuery } from "@/utils/date"

const DAILY_ATTEMPT_GOAL = 20

export default ({ src }) => {
  const { userId } = useUser()
  const { currentLanguage } = useLanguage()

  // TODO - only return a count
  const query = supabase
    .from("phrase_attempts")
    .select()
    .eq("created_by", userId)
    .eq("language_id", currentLanguage.id)
    .gte("created_at", getStartOfDayForQuery())

  const [attemptsToday] = useSupabaseRealtimeQuery(
    query,
    [userId],
    {},
    {
      event: "INSERT",
      schema: "public",
      table: "phrase_attempts",
      filter: `created_by=eq.${userId}`,
    }
  )

  const attemptsTodayNum = attemptsToday?.length
  const ratioToday = attemptsTodayNum && attemptsTodayNum / DAILY_ATTEMPT_GOAL
  const degrees = ratioToday && ratioToday * 360

  return (
    <ProgressAvatarWrapper>
      <Tooltip label={`${attemptsTodayNum} attempts today (your goal is ${DAILY_ATTEMPT_GOAL})`}>
        <RadialBg $degrees={degrees}>
          <AvatarImage src={src} alt="avatar" />
        </RadialBg>
      </Tooltip>
    </ProgressAvatarWrapper>
  )
}

const ProgressAvatarWrapper = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 50%;
`
const RadialBg = styled.div`
  width: 100%;
  height: 100%;
  background: ${props =>
    props.$degrees <= 1
      ? `conic-gradient(
      var(--text) 0deg 10deg,    var(--bg) 10deg 20deg,
      var(--text) 20deg 30deg,   var(--bg) 30deg 40deg,
      var(--text) 40deg 50deg,   var(--bg) 50deg 60deg,
      var(--text) 60deg 70deg,   var(--bg) 70deg 80deg,
      var(--text) 80deg 90deg,   var(--bg) 90deg 100deg,
      var(--text) 100deg 110deg, var(--bg) 110deg 120deg,
      var(--text) 120deg 130deg, var(--bg) 130deg 140deg,
      var(--text) 140deg 150deg, var(--bg) 150deg 160deg,
      var(--text) 160deg 170deg, var(--bg) 170deg 180deg,
      var(--text) 180deg 190deg, var(--bg) 190deg 200deg,
      var(--text) 200deg 210deg, var(--bg) 210deg 220deg,
      var(--text) 220deg 230deg, var(--bg) 230deg 240deg,
      var(--text) 240deg 250deg, var(--bg) 250deg 260deg,
      var(--text) 260deg 270deg, var(--bg) 270deg 280deg,
      var(--text) 280deg 290deg, var(--bg) 290deg 300deg,
      var(--text) 300deg 310deg, var(--bg) 310deg 320deg,
      var(--text) 320deg 330deg, var(--bg) 330deg 340deg,
      var(--text) 340deg 350deg, var(--bg) 350deg 360deg
    )`
      : `conic-gradient(var(--text) ${props.$degrees}deg, var(--bg) ${props.$degrees}deg 360deg)`};
  border: ${props => (props.$empty ? "2px dashed" : "none")};
  border-radius: 50%;
  padding: ${props => (props.$degrees <= 1 ? "1px" : "2px")};
`
const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--bg);
  border: 2px solid var(--bg);
  display: block;
`
