import { useEffect, useRef, useState } from "react"
import TextareaAutosize from "react-textarea-autosize"
import styled from "styled-components/macro"
import { useTimer } from "use-timer"

import { DebugDiv, useDebug } from "@/context/debug"
import { useLanguage } from "@/context/language"
import { useSoundFx } from "@/context/soundEffects"
import { useSpeechRecognition } from "@/context/speechRecognition"
import useMediaQuery from "@/hooks/useMediaQuery"
import Icon from "@/styles/Icon"
import PulseRecorder from "@/styles/PulseRecorder"
import Tooltip from "@/styles/Tooltip"
import VirtualKeyboard from "@/views/_components/VirtualKeyboard"

const INTERVAL_MILLISECONDS = 250 // use 1/4 of a second interval for more precision in UI
const START_THRESHOLD = 4 * 4 // if we don't detect ANY transcripts in X seconds, we automatically stop listening
const END_THRESHOLD = 0.5 * 4 // if we haven't detected NEW transcripts in X seconds, we automatically stop listening

interface SpeakableInputParams {
  value: string
  setValue: (value: string) => void
  disabled?: boolean
  onSubmit?: () => void
  $fill?: boolean
  bigRecordButton?: boolean
  minRows?: number
}
export default ({
  value,
  setValue,
  disabled = false,
  onSubmit,
  $fill,
  bigRecordButton,
  minRows,
  ...rest
}: SpeakableInputParams) => {
  const isDesktop = useMediaQuery("(min-width: 800px)")

  const { currentLanguage } = useLanguage()
  const { isDebugging } = useDebug()

  // https://www.npmjs.com/package/use-timer
  const {
    time: startTimer,
    start: startStartTimer,
    reset: resetStartTimer,
  } = useTimer({ interval: INTERVAL_MILLISECONDS })
  const {
    time: endTimer,
    start: startEndTimer,
    reset: resetEndTimer,
  } = useTimer({ interval: INTERVAL_MILLISECONDS })

  const [isListening, setIsListening] = useState(false)

  const inputRef = useRef(null)

  const { playSuccess, playTryAgain, playError } = useSoundFx()

  const {
    speechRecognitionIsSupported,
    willShowMicPermissionsModal,
    setHasInteractedWithMic,
    startSpeechRecognition,
    stopSpeechRecognition,
    clearSpeechRecognition,
    interimTranscript,
    finalTranscript,
    recognitionState,
  } = useSpeechRecognition()

  const userHasSpoken = Boolean(interimTranscript.length || finalTranscript.length)

  useEffect(() => {
    if (isListening && recognitionState === "waiting") {
      setIsListening(false)
    }
  }, [recognitionState])

  useEffect(() => {
    // clear it out when card reloads
    stopSpeechRecognition()
    clearSpeechRecognition()

    // stop listening and reset when closed
    return () => {
      clearSpeechRecognition()
      stopSpeechRecognition()
    }
  }, [])

  // TODO - add spoken content to textarea, don't replace it
  useEffect(() => {
    if (!isListening) return // coming from another component, ignore
    setValue(finalTranscript)
  }, [finalTranscript])

  useEffect(() => {
    if (interimTranscript.length === 0 && finalTranscript.length === 0) return
    resetEndTimer()
    startEndTimer()
  }, [interimTranscript, finalTranscript])

  useEffect(() => {
    if (!userHasSpoken && startTimer > START_THRESHOLD) {
      console.log("⏰ start timed out")
      resetStartTimer()
      resetEndTimer()
      stopSpeechRecognition()
      playTryAgain()
    }
  }, [value, startTimer, START_THRESHOLD])

  useEffect(() => {
    if (userHasSpoken && endTimer > END_THRESHOLD) {
      console.log("⏰ end timed out")
      resetStartTimer()
      resetEndTimer()
      stopSpeechRecognition()
      isListening && playTryAgain()
    }
  }, [value, endTimer, END_THRESHOLD, isListening])

  const toggleSpeech = e => {
    e.stopPropagation()
    if (isListening) {
      setIsListening(false)
      resetStartTimer()
      resetEndTimer()
      stopSpeechRecognition()
      playTryAgain()
    } else if (willShowMicPermissionsModal) {
      setHasInteractedWithMic(true)
    } else {
      setIsListening(true)
      startStartTimer()
      startSpeechRecognition(currentLanguage.ietf_bcp_47)
      playSuccess()
    }
  }

  const onKeyDown = event => {
    if (event.keyCode == 13 && event.shiftKey == false) {
      event.preventDefault()
      event.stopPropagation()
      onSubmit && onSubmit()
    }
  }

  if (!speechRecognitionIsSupported)
    return (
      <div
        style={{
          display: bigRecordButton && "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="input-button-set">
          <TextareaAutosize
            value={value}
            disabled={disabled}
            onKeyDown={onKeyDown}
            onChange={e => setValue(e.target.value)}
            minRows={minRows || 2}
            lang={currentLanguage.iso639_1}
            placeholder={`Type in ${currentLanguage.name_eng}...`}
            autoCorrect="on"
            {...rest}
          />
          {bigRecordButton && (
            <button className="button" type="submit" onClick={onSubmit}>
              Send
              <Icon name="send" size="medium" />
            </button>
          )}
        </div>
        {bigRecordButton && (
          <p style={{ fontStyle: "italic", fontSize: "var(--s)" }}>
            <Icon name="warning" size="small" inline /> Your browser doesn't support speech
            recognition yet. Try Chrome?
          </p>
        )}
      </div>
    )

  const isReadyToSend = !isListening && !!value?.length && !interimTranscript?.length

  return (
    <>
      <SpeechInputWrapper
        $fill={$fill}
        $isMobile={!isDesktop}
        $speaking={isListening}
        $vertical={bigRecordButton}
      >
        {isDebugging && (
          <DebugDiv>
            {recognitionState} · Start: ({startTimer}/{START_THRESHOLD}) · End: ({endTimer}/
            {END_THRESHOLD})
          </DebugDiv>
        )}
        {isListening ? (
          <FakeTextarea>
            {value} <span style={{ opacity: 0.5 }}>{interimTranscript}</span>{" "}
          </FakeTextarea>
        ) : (
          <RealTextarea
            value={value}
            disabled={disabled || isListening}
            onKeyDown={onKeyDown}
            onChange={e => setValue(e.target.value)}
            minRows={minRows || 2}
            lang={currentLanguage.iso639_1}
            ref={inputRef}
            autoCorrect="on"
            {...rest}
          />
        )}
        <SpeechInputButtonWrapper>
          {currentLanguage.accent_letters?.length && (
            <VirtualKeyboard
              // Lighthouse sez: aria attributes do not match their roles for "on-focus-only"
              inputElement={inputRef.current}
              trigger={
                <div className="on-focus-only">
                  <Tooltip label="Add accents">
                    <SpeechInputButton
                      type="button"
                      className="button button-tertiary button-small"
                      disabled={disabled}
                    >
                      <Icon name="keyboard_alt" />
                    </SpeechInputButton>
                  </Tooltip>
                </div>
              }
            />
          )}

          {value && value.length > 0 && (
            <Tooltip label="Clear text">
              <SpeechInputButton
                type="button"
                className="button button-tertiary button-small"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  setValue("")
                }}
              >
                <Icon name="close" />
              </SpeechInputButton>
            </Tooltip>
          )}
          {!bigRecordButton && (
            <Tooltip label={isListening ? "Click to stop" : "Type with your voice"}>
              <SpeechInputButton
                type="button"
                className="button button-tertiary button-small"
                disabled={disabled}
                onClick={toggleSpeech}
              >
                {isListening ? <PulseRecorder /> : <Icon name="mic" />}
              </SpeechInputButton>
            </Tooltip>
          )}
        </SpeechInputButtonWrapper>
      </SpeechInputWrapper>
      {bigRecordButton && isReadyToSend ? (
        <button
          className="button button-large button-full-width"
          type="submit"
          onClick={onSubmit}
          style={{
            borderRadius: "0 0 2px 2px",
            marginTop: "-1px",
          }}
        >
          <Icon name="send" size="medium" />
          Send
        </button>
      ) : bigRecordButton && !isReadyToSend ? (
        <button
          className="button button-large button-full-width"
          type="button"
          disabled={isListening || disabled}
          onClick={toggleSpeech}
          style={{
            borderRadius: "0 0 2px 2px",
            marginTop: "-1px",
          }}
        >
          {disabled ? (
            <>
              <Icon name="mic" size="medium" />
              <span data-testid="click-to-speak">Click to speak</span>
            </>
          ) : isListening ? (
            <>
              <PulseRecorder />
              Listening...
            </>
          ) : willShowMicPermissionsModal ? (
            <>
              <Icon name="mic" size="medium" />
              <span data-testid="click-to-enable-microphone">Click to enable microphone</span>
            </>
          ) : (
            <>
              <Icon name="mic" size="medium" />
              <span data-testid="click-to-speak">Click to speak</span>
            </>
          )}
        </button>
      ) : null}
    </>
  )
}

const SpeechInputWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto max-content;
  margin: ${props => (props.$vertical ? "0" : "0 0 1rem")};
  width: ${props => (props.$fill || props.$isMobile ? "100%" : "auto")};
  border: 1px solid var(--input-border-color);
  background: var(--input-bg-color);
  opacity: ${props => props.$speaking && ".7"};

  .on-focus-only {
    width: 0;
    opacity: 0;
    overflow: hidden;
  }
  &:focus-within {
    border-color: var(--text);
    .on-focus-only {
      width: auto;
      opacity: 1;
    }
  }
`

// buttons

const SpeechInputButtonWrapper = styled.div`
  padding: 0.1rem 0.1rem 0;
  z-index: 200;
  display: flex;
`
const SpeechInputButton = styled.button`
  width: 2rem;
  height: 2rem;
`

// textarea

const RealTextarea = styled(TextareaAutosize)`
  margin: 0;
  border: none !important;
  box-shadow: none !important;
  display: block;
  padding: 0.5rem 0 0.5rem 0.5rem;
  width: 100%;
  line-height: 1.2;
`
const FakeTextarea = styled.div`
  margin: 0;
  border: none !important;
  box-shadow: none !important;
  display: block;
  padding: 0.5rem 0 0.5rem 0.5rem;
  width: 100%;
  line-height: 1.2;
`
