import { useEffect, useState } from "react"
import { useDebounce } from "use-debounce"

import { useLanguage } from "@/context/language"
import { supabase } from "@/db/supabase"
import Typing from "@/styles/Typing"
import { logError } from "@/utils/error"
import Definable from "@/views/_components/Definable"
import ErrorComponent from "@/views/errors/ErrorComponent"

export default ({ wordString }) => {
  const { currentLanguage, startingLanguage } = useLanguage()

  const [wordStringDebounced] = useDebounce(wordString, 500, { leading: true })

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [examples, setExamples] = useState(null)

  useEffect(() => {
    const triggerRemoteFunction = async () => {
      if (!wordStringDebounced || wordStringDebounced.trim() === "") {
        setLoading(false)
        return
      }
      try {
        setLoading(true)
        console.log("🏃 triggering edge function ai_prompt")

        const messages = [
          {
            role: "system",
            content: `
              Please generate three examples of the user-provided ${currentLanguage.name_eng} word used in a sentence.
              Please only generate sentences that are grammatically correct ${currentLanguage.name_eng} sentences.
            `,
          },
          {
            role: "user",
            content: wordStringDebounced,
          },
        ]

        const functions = [
          {
            name: "generate_examples",
            description: `Two example ${currentLanguage.name_eng} sentences that use the word ${wordStringDebounced}, with their ${startingLanguage.name_eng} translations`,
            parameters: {
              type: "object",
              properties: {
                sentence_1: {
                  type: "object",
                  properties: {
                    sentence_content: {
                      type: "string",
                      description: `The sentence in ${currentLanguage.name_eng}`,
                    },
                    translation: {
                      type: "string",
                      description: `The same sentence translated to ${startingLanguage.name_eng}`,
                    },
                  },
                },
                sentence_2: {
                  type: "object",
                  properties: {
                    sentence_content: {
                      type: "string",
                      description: `The sentence in ${currentLanguage.name_eng}`,
                    },
                    translation: {
                      type: "string",
                      description: `The same sentence translated to ${startingLanguage.name_eng}`,
                    },
                  },
                },
              },
              required: ["sentence_1", "sentence_2"],
            },
          },
        ]

        const function_call = { name: "generate_examples" }

        console.log("Generated ai prompt", {
          messages,
          functions,
          function_call,
        })

        console.log("🏃 triggering edge function ai_prompt")
        const { data, error } = await supabase.functions.invoke("ai_prompt", {
          body: { messages, functions, function_call },
        })
        if (error) throw new Error(error.message)
        setError(null)

        const responseResultString = data?.promptResponse?.function_call?.arguments
        const responseResult = responseResultString && JSON.parse(responseResultString)
        console.log("📙 Got example sentences", responseResult)

        setLoading(false)
        setExamples([responseResult.sentence_1, responseResult.sentence_2])
      } catch (error) {
        logError("could not get examples for this word or phrase", error)
        setError(error)
        setExamples(null)
      }
    }
    triggerRemoteFunction()
  }, [wordStringDebounced])

  if (loading) return <Typing />
  if (error) return <ErrorComponent error={error} />

  return (
    <div style={{ whiteSpace: "break-spaces" }}>
      {examples?.map((example, index) => {
        return (
          <div key={index}>
            <Definable wordString={example.sentence_content} splitByWord />
            <p style={{ fontSize: "var(--s)" }}>{example.translation}</p>
          </div>
        )
      })}
    </div>
  )
}
