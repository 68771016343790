import styled from "styled-components/macro"

import { useLanguage } from "@/context/language"
import { supabase, useSupabaseQuery } from "@/db/supabase"
import Popover from "@/styles/Popover"
import ErrorComponent from "@/views/errors/ErrorComponent"

export default ({ wordString }) => {
  const { currentLanguage } = useLanguage()

  // prepare wordString for text search
  // `little big` => `'little' | 'big'`
  // https://supabase.com/docs/guides/database/full-text-search#match-any-search-words
  const wordStringSearchable = wordString
    ?.split(" ")
    .map(word => `'${word.replaceAll(`'`, `''`)}'`)
    .join(" | ")

  const query = supabase
    .from("mnemonics")
    .select("*, mnemonic_votes(*)")
    .eq("language_id", currentLanguage.id)
    .textSearch("target_phrase", wordStringSearchable)
  const [mnemonics, loading, error] = useSupabaseQuery(query, [wordString])
  const mnemonic = mnemonics?.length > 0 ? mnemonics[0] : null
  const otherMnemonics = mnemonics?.length > 1 ? mnemonics.slice(1) : null

  if (!wordString?.length) return null

  if (!wordString) return null
  if (error) return <ErrorComponent error={error} />
  if (loading) return "..."
  if (!mnemonic) return null
  return (
    <div style={{ fontSize: "var(--s)", fontStyle: "italic" }}>
      {mnemonic.remember_method}
      {otherMnemonics?.length && (
        <Popover trigger={<MoreButton>···</MoreButton>}>
          {otherMnemonics.map(mnemonic => (
            <div key={mnemonic.id}>
              <strong>{mnemonic.target_phrase}</strong>
              {": "}
              {mnemonic.remember_method}
            </div>
          ))}
        </Popover>
      )}
    </div>
  )
}

const MoreButton = styled.button`
  background: var(--badge);
  border: none;
  font-size: 0.8em;
  padding: 2px 4px;
  border-radius: 4px;
  margin: 0 0 0 0.25rem;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: #ddd;
  }
`
