import { useEffect, useState } from "react"
import styled from "styled-components/macro"
import { useDebounce } from "use-debounce"

import { useLanguage } from "@/context/language"
import { supabase } from "@/db/supabase"
import { useCopyToClipboard } from "@/hooks/useCopyText"
import Typing from "@/styles/Typing"
import { logError } from "@/utils/error"
import Definable from "@/views/_components/Definable"
import SpeakWord from "@/views/_components/SpeakWord"
import ErrorComponent from "@/views/errors/ErrorComponent"

export default ({ wordString, queryLanguage }) => {
  const { startingLanguage, currentLanguage } = useLanguage()

  const sourceLanguage = queryLanguage || startingLanguage
  const targetLanguage =
    sourceLanguage.id === startingLanguage.id ? currentLanguage : startingLanguage
  const toL2 = targetLanguage.id === currentLanguage.id

  const [wordStringDebounced] = useDebounce(wordString, 500, { leading: true })
  const copyText = useCopyToClipboard()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [translations, setTranslations] = useState("")
  const [wasCopied, setWasCopied] = useState(false)

  const translationText = translations?.[0]?.text

  const copyTranslation = () => {
    copyText(translationText)
    setWasCopied(true)
  }

  useEffect(() => {
    const triggerRemoteFunction = async () => {
      try {
        if (!wordStringDebounced || wordStringDebounced.trim() === "") {
          setLoading(false)
          return
        }
        setLoading(true)
        // only target lang uses EN-US or EN-GB, source is always EN for english
        // https://www.deepl.com/docs-api/translate-text/translate-text/
        const source_lang = sourceLanguage.iso639_1.toUpperCase()
        const target_lang =
          targetLanguage.iso639_1 === "en" ? "EN-US" : targetLanguage.iso639_1.toUpperCase()
        const body = {
          text: wordStringDebounced,
          source_lang,
          target_lang,
        }
        console.log("🏃 triggering edge function deepl_translate", body)
        const { data, error } = await supabase.functions.invoke("deepl_translate", {
          body,
        })
        setLoading(false)
        if (error) throw error
        setTranslations(data?.translations)
      } catch (error) {
        setLoading(false)
        setError(error)
        logError("translate word in reference panel", error, true)
      }
    }
    triggerRemoteFunction()
  }, [wordStringDebounced])

  useEffect(() => {
    setTimeout(() => setWasCopied(false), 10 * 1000)
  }, [wasCopied])

  if (error) return <ErrorComponent error={error} />

  return (
    <TranslationWrapper onClick={copyTranslation} data-active={wasCopied}>
      <div className="copy-helper">{wasCopied ? "copied!" : "copy"}</div>
      <div style={{ fontSize: "var(--s)" }}>{targetLanguage.name_eng} translation</div>
      <div style={{ fontSize: "var(--l)", fontWeight: "500" }}>
        {loading ? (
          <div style={{ height: "1.5em", display: "flex", alignItems: "center" }}>
            <Typing />
          </div>
        ) : !translations.length ? (
          "(No translation found)"
        ) : toL2 ? (
          <>
            <Definable wordString={translationText} splitByWord />
            <SpeakWord wordString={translationText} autoPlay />
          </>
        ) : (
          translationText
        )}
      </div>
    </TranslationWrapper>
  )
}

const TranslationWrapper = styled.div`
  padding: 0.5rem 1rem;
  background: var(--badge);
  cursor: pointer;
  position: relative;

  .copy-helper {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.25rem 0.5rem;
    font-size: var(--s);
    font-weight: 500;
    color: var(--text);
    transition: opacity 0.3s ease;
    opacity: 0;
  }

  &:hover .copy-helper {
    opacity: 0.5;
  }
  &:active {
    opacity: 0.9;
  }
`
