import ReactMarkdown from "react-markdown"
import remarkDirective from "remark-directive"
import remarkDirectiveRehype from "remark-directive-rehype"
import remarkGfm from "remark-gfm"

import LessonDialog from "@/views/lessons/LessonDialog"
import LessonEmbed from "@/views/lessons/LessonEmbed"
import LessonEmbedInline from "@/views/lessons/LessonEmbedInline"
import LessonVideo from "@/views/lessons/LessonVideo"

export default ({ content }) => {
  return (
    <>
      <ReactMarkdown
        children={content}
        remarkPlugins={[remarkGfm, remarkDirective, remarkDirectiveRehype]}
        components={{
          b: LessonEmbed,
          i: LessonEmbedInline,
          dialog: LessonDialog,
          video: LessonVideo,
        }}
      />
    </>
  )
}
