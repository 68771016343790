import { Link } from "react-router-dom"
import styled from "styled-components/macro"

import JoinWaitlist from "@/views/account/JoinWaitlist"
import BlogArticle from "@/views/blog/BlogArticle"
// import ArrowCallout from '@/views/_components/ArrowCallout'

export default () => {
  return (
    <BlogArticle
      title="Why online language learning is broken"
      byLine={
        <>
          By{" "}
          <a href="https://twitter.com/sampleite" target="_blank">
            Sam Pierce Lolla
          </a>
          {" · "}May 1, 2023
        </>
      }
      intro={
        <>
          <p>Have you ever noticed that all language apps look the&nbsp;same?</p>
        </>
      }
    >
      <img src="/images/blog_post_illustrations/why_broken.png" style={{ maxWidth: "100%" }} />

      <p>Candy-colored graphics. Repetitive exercises. Aggressive phone&nbsp;notifications.</p>
      <p>
        Apps like Duolingo are popular and addictive, but limited in their effectiveness. According
        to the New York Times,{" "}
        <a
          href="https://www.nytimes.com/2019/05/04/smarter-living/500-days-of-duolingo-what-you-can-and-cant-learn-from-a-language-app.html"
          target=""
        >
          "if you want to become fluent in a language — or even conversational — they won’t be
          enough"
        </a>
        .
      </p>
      <p>
        So, where exactly do these apps fall short?{" "}
        {/* Why aren't there better tools for serious, self-motivated language learners? */}
        {/* </p>
    <p> */}
        And why is it <em>still</em> so hard to learn a language&nbsp;online?
      </p>

      <h2>What's missing</h2>

      {/* Repetitive exercises: Some users find the exercises repetitive and boring, especially when they have already mastered a particular lesson. */}
      {/* Inaccurate translations: Duolingo's translations are not always accurate or natural-sounding, which can cause confusion and frustration for users. */}
      {/* Monolingual approach: Duolingo's approach is primarily monolingual, meaning it teaches the language using only the target language. Some users find this challenging and would prefer more explanations in their native language. */}

      <section>
        <BigList>
          <li>
            <h3>No deep practice</h3>

            <p>
              Modern language apps are all about getting you started. But do small, easy practice
              sessions really help you learn?
            </p>

            <p>I call this The Big Lie:</p>

            <blockquote>
              "If you spend 5 minutes a day on your phone looking at flashcards, one day you will
              wake up and speak French."
            </blockquote>

            <p>
              Unfortunately, <strong>this is not true</strong>. Learning a new language takes lots
              of practice. It's not impossible—3 year-olds do it every day—but it does take work.
            </p>

            <p>
              We should be helping people rise to that challenge, not artificially dumbing things
              down.
            </p>
          </li>

          <li>
            <h3>Narrow, prescriptive learning paths</h3>

            <p>Modern language apps have a rigid lesson order.</p>

            <p>
              For example, if you want to learn how to talk about your family, you will probably
              have to learn all the names of fruits and animals first.
            </p>

            <img src="narrow_learning_path.png" />

            <p>
              In fact, Duolingo recently made their learning path <em>more narrow</em>. The change
              was{" "}
              <a href="https://www.reddit.com/r/duolingo/comments/wy4wmb/learning_path_thread/">
                so poorly received by users
              </a>{" "}
              that{" "}
              <a href="https://www.nbcnews.com/tech/tech-news/duolingos-update-redesign-luis-von-ahn-interview-rcna44655">
                it made national news
              </a>
              , and has been criticized{" "}
              <a href="https://uxdesign.cc/down-the-wrong-path-the-disaster-of-the-latest-duolingo-ui-update-a4cdd1e6ea1c">
                by designers
              </a>{" "}
              and <a href="">by education experts</a> alike.
            </p>

            <p>
              Language learning apps should take inspiration from{" "}
              <a href="">Montessori principles</a> and sites like <a href="">Lichess</a>, which{" "}
            </p>
          </li>

          <li>
            <h3>Overemphasis on gamification</h3>

            <blockquote>"Play stupid games, win stupid prizes"</blockquote>

            {/* While gamification can be motivating for some, others find the constant pressure to earn points and maintain streaks distracting from their language learning goals. */}

            <p>
              What's the point of learning a language? For many, it's to unlock new experiences. To
              live in new places and meet new people. To change how your mind actually{" "}
              <em>thinks</em>, and see the world through another culture's eyes.
            </p>

            <p>
              Unfortunately, language apps seem to have forgotten that. They motivate their users
              with fake prizes (Duolingo alone has: gold, rubies, diamonds).
            </p>

            <p>
              There's no doubt these help some people come back to the app. But does it really help
              anyone learn?
            </p>
          </li>

          <li>
            <h3>Lack of real explanations</h3>

            <p>
              Most apps are mostly just a series of flashcards. They don't provide in-depth
              explanations of grammar rules, sentence structure, or cultural context.
            </p>

            <p>
              And there's a good reason. Learning languages by rote is notoriously boring and
              ineffective.
            </p>

            <p>
              But removing instructional content entirely is equally flawed. Sometimes learners just
              need to translate a phrase, or look up a conjugation.
            </p>

            <p>
              Speaking a language requires more than vocabulary memorization. Not explaining context
              and structure leaves students struggling.
            </p>
          </li>

          <li>
            <h3>Not enough speaking practice</h3>

            <p>
              Many expert educators will tell you that actually speaking and listening to a language
              is incredibly important for learning.
            </p>

            <p>
              Some believe this so strongly, in fact, that they build{" "}
              <a href="https://www.languagetransfer.org/" target="_blank">
                courses that have no written content at all
              </a>
              .
            </p>

            <p>
              Yet most language apps are largely text-based, with few opportunities to go
              audio-only.
            </p>
          </li>

          <li>
            <h3>Easy, but not powerful</h3>

            <p>
              One of the best parts about Duolingo is also one of it's worst: anyone can use it. You
              don't really have to think: you just react.
            </p>

            <p>It takes no think.</p>

            <p>
              Modern language apps are designed to always be <em>easy</em>. But learning a language
              is not easy.
            </p>
          </li>

          <li>
            <h3>No language immersion</h3>

            <p>Everyone knows the best way to learn a language is to immerse yourself in it.</p>

            <p>But that's impossible in today's apps.</p>

            <p>
              There still very little access to television, radio, or podcasts. And no real
              simulation of what it would actually be like to be in a foreign country.
            </p>

            {/* <p>And yet. Duolingo gives even advanced Spanish students instruction <em>in English</em>.</p> */}
          </li>

          {/* <li>
          <h3>Fragmented tools</h3>

          <p>There's an app for a dictionary, an app for flashcards, and an app for lessons.</p>

          <p>Learners have to spend half their time organizing their notes.</p>
        </li>

        <li>
          <h3>No community platform</h3>

          <blockquote>
            There's only one want to learn a language: by speaking it.
          </blockquote>

          <p>Sweet stories of couple's meeting on Duolingo.</p>

          <p>Language apps are supposed to help you <em>talk to people</em>. So why does it feel like playing a game all by yourself?</p>
        </li> */}

          {/* <li>
          <h3>Practicing is slow</h3>

          <p>Compare to Anki, the beloved flash card app.</p>
        </li> */}

          <li>
            <h3>Closed content licenses</h3>

            <p>Duolingo is closed source</p>

            <p>
              No. Worse, contributions made by the community are owned by, you guessed it: Duolingo.
              Why would someone contribute to a community if only person who gets paid is a giant
              corporation?
            </p>

            <p>
              Luckily, there's tons of open content in language learning... if you know where to
              look. <a href="">Tatoeba's phrase database</a> is a goldmine of language knowledge,
              but hasn't really been incorporated into a teaching tool yet.
            </p>

            <p>
              Duolingo is a large for-profit company backed by venture capitalists with proprietary
              course content.
            </p>

            <p>
              No-cost isn't enough. We need truly free lessons that can be improved on and shared,
              that aren't owned by a single company.
            </p>

            {/* <p><em>UPDATE: see our <Link to="open-content">open content downloads</Link></em></p> */}
          </li>

          <li>
            <h3>Selling your attention</h3>

            <blockquote>
              "If you're not paying for to use a product, the product is you."
            </blockquote>

            <p>Apps have gotten into the habit of getting us addicted.</p>

            <p>
              This is <a href="">the attention economy</a>—the business of capturing people's time
              to make money on advertising.
            </p>

            <p>
              But it's a bad deal for customers. Facebook <a href="">profits off of hate</a>. And
              dating apps are incentivized to keep you single so you{" "}
              <a href="">keep you coming back</a>.
            </p>

            <p>
              Language apps do it, too. Duolingo has <a href="">sold customer data for years</a> to
              make money.{" "}
            </p>

            <p>
              Luckily public sentiment is turning, and the era of the attention economy is closing.
            </p>

            <p>
              Free access to education is vital. But not at the cost of addicting people or selling
              their data.
            </p>
          </li>

          {/* <li>
          <h3>No innovation</h3>

          <p>Language translation is a far cry from science fiction.</p>

          <p>Duolingo doesn't actually recognize what you say in voice. .</p>
        </li> */}
        </BigList>
      </section>

      <hr />

      <section>
        <h2>What we're doing about it</h2>

        <p>
          We're working an alternative. It's a new language app called <Link to="/">Protolang</Link>
          .
        </p>

        {/* <ArrowCallout>How it works</ArrowCallout>
      <LoomVideo id="ae393b1448524717bce13e1e13fa653c" /> */}

        <p>
          Protolang is an open, AI-powered, immersive learning environment for people who want to
          learn languages <em>fast</em>.
        </p>

        <p>
          We use something we're calling <strong>digital immersion</strong>: surrounding you with
          your target language on day one:
        </p>

        <ul>
          <li>
            Instead of spoon-feeding you lessons, Protolang gives you{" "}
            <strong>access to all content</strong>, instantly.
          </li>
          <li>
            Instead of just flashcards, we're building{" "}
            <strong>lessons, conversations, games, video, audio, stories</strong>, and more for you
            to discover
          </li>
          {/* <li>Instead of copyrighting data, we're <strong><Link to="open-content">releasing our for content for free</Link></strong> for anyone to use.</li> */}
        </ul>

        {/* <p>It’s a bit like getting thrown into the swimming pool to learn how to swim. But when it comes to languages, that’s the best way to learn!</p> */}
        {/* <p>
        Protolang takes the opposite approach: we open the floodgates and give immediate access to everything you need to learn. Instead of spoon-feeding you, our goal is to give you a rich language playground for you to explore.
      </p> */}

        <p>
          Protolang is still in private alpha.{" "}
          <strong>
            <Link to="/login">Reserve your spot</Link>
          </strong>{" "}
          and be the first to know when we launch.
        </p>

        <JoinWaitlist />
      </section>
    </BlogArticle>
  )
}

const BigList = styled.ol`
  // https://css-tricks.com/css-counters-custom-list-number-styling/
  counter-reset: big-list-counter;
  margin: 0;
  padding: 0;

  li {
    position: relative;
    margin: 0 0 6rem;
    padding: 0;
    list-style: none;
    counter-increment: big-list-counter;
  }

  li:before {
    content: counter(big-list-counter);
    font-family: var(--font-family-sans);
    position: absolute;
    top: 0rem;
    left: -2rem;
    line-height: 1.5rem;
    font-size: 1.5rem;
    font-weight: normal;
    color: #999;
  }
`
