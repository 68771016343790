import { useLanguage } from "@/context/language"
import { supabaseDictionaries, useSupabaseQuery } from "@/db/supabase"
import Typing from "@/styles/Typing"
import Pronouncable from "@/views/_components/Pronouncable"
import ErrorComponent from "@/views/errors/ErrorComponent"

export default ({ wordString }) => {
  const { currentLanguage } = useLanguage()

  const query = supabaseDictionaries.from(currentLanguage.iso639_1).select().eq("word", wordString)
  const [word, loading, error] = useSupabaseQuery(query, [wordString])

  if (!wordString || wordString.length <= 0) return null
  if (loading) return <Typing />
  if (error) return <ErrorComponent error={error} />
  if (!word || word.length <= 0) return "Word not found"
  const definition = word[0]
  var { sounds } = definition._wiktionary_data
  var ipa = sounds?.[0]?.ipa
  if (!ipa) return "Pronunciation not found"

  return <Pronouncable ipa={ipa} />
}
