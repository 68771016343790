import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"

const environment = import.meta.env.VITE_ENVIRONMENT

// TODO - react router integration? only if we don't use next
// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
export const trackingInit = () => {
  if (environment === "development") return
  console.log("📊 Initializing Sentry")
  return Sentry.init({
    environment,

    dsn: "https://8a39776e883f4130989e1e9ec2cf5185@o393330.ingest.sentry.io/4504774867877888",

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // https://docs.sentry.io/platforms/javascript/guides/react/session-replay/
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [new BrowserTracing(), new Sentry.Replay()],
  })
}

export const setTrackingUser = ({ email }) => {
  if (environment === "development") return

  Sentry.setUser({ email })
}

export const createTrackingError = ({ action, error }) => {
  if (environment === "development") return

  // https://github.com/getsentry/sentry-javascript/issues/1607#issuecomment-551756226
  Sentry.withScope(scope => {
    // scope.setTags(context.tags)
    scope.setExtras({ action })
    Sentry.captureException(error)
  })
}
