import { Link } from "react-router-dom"

export default () => {
  return (
    <>
      <h1>Payments & billing</h1>
      <p>
        We are working on some features to make Protolang smoother for users who can afford to
        support the project. However, the core of the app will remain free.
      </p>
      <p>
        Learn more and join the waitlist: <Link to="/subscription">Protolang Pro</Link>
      </p>
    </>
  )
}
