import { useState } from "react"
import { Helmet } from "react-helmet-async"
import { Link, Navigate } from "react-router-dom"

import { useUser } from "@/context/user"
import { logError } from "@/utils/error.js"

export default () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const { userId, signUpWithEmailPassword_DO_NOT_USE } = useUser()

  const onSubmit = async event => {
    event.preventDefault()
    try {
      await signUpWithEmailPassword_DO_NOT_USE(email, password)
    } catch (error) {
      logError("test sign up", error)
    }
  }

  if (userId) return <Navigate to="/" />

  return (
    <>
      <Helmet>
        <title>TEST Sign up | Protolang</title>
      </Helmet>

      <h1>Whoops, this isn't what you're looking for...</h1>
      <p>This page is for internal testing only</p>

      <Link to="/">Go back to the homepage</Link>

      <form onSubmit={onSubmit} style={{ overflow: "hidden" }}>
        <div style={{ transform: "translate(0, 500px)" }}>
          <input
            type="email"
            value={email}
            placeholder="email"
            onChange={e => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            value={password}
            placeholder="password"
            onChange={e => setPassword(e.target.value)}
            required
          />
          <button className="button" type="submit">
            Sign up
          </button>
        </div>
      </form>
    </>
  )
}
