import { useEffect, useState } from "react"
// import styled from 'styled-components/macro'

import { parsePhrasesFromLessonContent } from "@/utils/parseMarkdown"
import LessonPhraseListItem from "@/views/lessons/LessonPhraseListItem"

const INITIAL_PHRASES_TO_SHOW_COUNT = 10

export default ({ lesson, simple }) => {
  const [phrases, setPhrases] = useState([])
  const [isShowingMore, setIsShowingMore] = useState(false)

  useEffect(() => {
    const getPhrases = async () => {
      const content = lesson?.content_l1
      const vocab = lesson?.vocab_l1
      const { phrases } = await parsePhrasesFromLessonContent(content + vocab, true)
      setPhrases(phrases)
    }
    getPhrases()
  }, [lesson])

  const phrasesToShow = isShowingMore ? phrases : phrases.slice(0, INITIAL_PHRASES_TO_SHOW_COUNT)

  if (!phrasesToShow?.length) return "no phrases for this lesson"

  return (
    <>
      <table>
        <tbody>
          {phrasesToShow.map((phrase, index) => {
            return <LessonPhraseListItem key={index} phrase={phrase} simple={simple} />
          })}
        </tbody>
      </table>
      {!isShowingMore && phrases.length > INITIAL_PHRASES_TO_SHOW_COUNT && (
        <button onClick={() => setIsShowingMore(true)}>Show more...</button>
      )}
    </>
  )
}

// const SidebarWrapper = styled.div`
//   position: sticky;
//   padding-top: 2rem;
//   top: 0;
// `
