import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { useLanguage } from "@/context/language"
import { useUser } from "@/context/user"
import { supabase, useSupabaseQuery } from "@/db/supabase"
import { AvatarCharacter } from "@/styles/Avatars"
import {
  LeftPanelInner,
  LeftPanelWrapper,
  MoveOnChange,
  PanelsWrapper,
  RightPanelInner,
  RightPanelWrapper,
} from "@/styles/CreateAccount"
import { FormQuestion } from "@/styles/Form"
import { RadioGroup } from "@/views/_components/RadioCheckbox"
import { logError } from "@/utils/error.js"
import Definable from "@/views/_components/Definable"
import SpeakWord from "@/views/_components/SpeakWord"
import { Helmet } from "react-helmet-async"
import { useDebounce } from "use-debounce"

export default ({ closeModal }: { closeModal?: () => void }) => {
  const navigate = useNavigate()
  const { userId, userProfile } = useUser()
  const { currentLanguage, contentStrings } = useLanguage()

  const [genderPreference, setGenderPreference] = useState(null)
  const [languageEducator, setLanguageEducator] = useState(null)
  // const [speakOtherLanguages, setSpeakOtherLanguages] = useState(null)
  // const [languagesYouSpeak, setLanguagesYouSpeak] = useState([])
  const [firstName, setFirstName] = useState(userProfile?.first_name)
  const [saving, setSaving] = useState(false)
  const [dataHidden, setDataHidden] = useState(false)

  const [firstNameDebounced] = useDebounce(firstName, 500, { leading: true })
  useEffect(() => {
    setDataHidden(true)
    setTimeout(() => {
      setDataHidden(false)
    }, 100)
  }, [genderPreference, firstNameDebounced])

  const welcomeWord =
    (genderPreference === "male" && contentStrings?.welcome?.male) ||
    contentStrings?.welcome?.female ||
    "Welcome"

  const friendWord =
    (firstNameDebounced !== "" && firstNameDebounced) ||
    (genderPreference === "male" && contentStrings?.friend?.male) ||
    contentStrings?.friend?.female ||
    "friend"

  const welcomePhrase = contentStrings?.welcome?.phrase || `We're excited to have you!`

  const fullWelcomePhrase = `${welcomeWord}, ${friendWord}! ${welcomePhrase}`

  const situationQuery = supabase
    .from("conversation_situations")
    .select("*, character_id(*)")
    .limit(1)
    .single()
  const [testSituation] = useSupabaseQuery(situationQuery)

  // const languagesQuery = supabase
  //   .from('languages')
  //   .select()
  // const [languages, languagesLoading, languagesError] = useSupabaseQuery(languagesQuery)

  async function updateUserOnboarding(event) {
    event.preventDefault()
    try {
      setSaving(true)
      if (!userId) return
      const formData = {
        id: userId,
        refer_to_as_gender: genderPreference,
        is_language_educator: languageEducator,
        // languages_spoken: !speakOtherLanguages ? [] : languagesYouSpeak,
        first_name: firstName,
      }
      if (firstName === "") delete formData.first_name
      const { error } = await supabase
        .from("user_profiles")
        .upsert(formData)
        .eq("id", userId)
        .select()
      if (error) throw error
      closeModal && closeModal()
      navigate(`/${currentLanguage.slug_eng}`)

      // navigation isn't enough because we're already on that page
      // and user_languages, which we use to decide whether to show onboarding or not,
      // is not real-time updated. So instead we force reload the page.
      // TODO - a better way to do this
      location.reload()
    } catch (error) {
      logError("create user settings", error)
    } finally {
      setSaving(false)
    }
  }

  // should never happen, but just in case
  if (!userId) return "Error showing account creation - no user found."

  // const languageOptionsSimple = !languages ? [] : languages.map(language => ({id: language.id, title: `${language.endonym} (${language.name_eng})` }))
  // const languageOptions = [...languageOptionsSimple, {id: 'other', title: 'Other'}]

  // TODO - timezone

  return (
    <>
      <Helmet>
        <title>Finish creating your account | Protolang</title>
      </Helmet>

      <PanelsWrapper>
        <LeftPanelWrapper>
          <LeftPanelInner>
            <form onSubmit={updateUserOnboarding}>
              <h1>Almost ready!</h1>
              <p>Just a few questions more to help our characters speak to you correctly*</p>

              <FormQuestion>
                <div>
                  <label>What should our chat characters call you?</label>
                  <p className="form-label-helper">Your first name or a nickname, for example</p>
                  <input
                    id="first_name"
                    type="text"
                    placeholder="Your name"
                    value={firstName || ""}
                    onChange={e => setFirstName(e.target.value)}
                  />
                </div>
                <div></div>
              </FormQuestion>

              <FormQuestion>
                <div>
                  <label>What gender should we use when speaking to&nbsp;you?</label>
                  <p className="form-label-helper">
                    Some languages use different words based on your gender
                  </p>
                  <RadioGroup
                    value={genderPreference}
                    setValue={setGenderPreference}
                    options={[
                      {
                        id: "female",
                        title: "Refer to me as a woman",
                      },
                      {
                        id: "male",
                        title: "Refer to me as a man",
                      },
                      {
                        id: "mix",
                        title: "Either/both/other",
                      },
                    ]}
                  />
                </div>
                <div></div>
              </FormQuestion>

              <FormQuestion>
                <div>
                  <label>Have you taught another language professionally?</label>
                  <p className="form-label-helper">
                    If so, we may give you early access to some new features
                  </p>
                  <RadioGroup
                    value={languageEducator}
                    setValue={setLanguageEducator}
                    options={[
                      {
                        id: true,
                        title: "Yes, I'm a language educator",
                      },
                      {
                        id: false,
                        title: "Nope!",
                      },
                    ]}
                  />
                </div>
                <div></div>
              </FormQuestion>

              {/* <FormQuestion>
              <div>
                <label>Do you speak any language(s) besides {startingLanguage.name_eng}?</label>
                <RadioGroup
                  value={speakOtherLanguages}
                  setValue={setSpeakOtherLanguages}
                  options={[
                    {
                      id: true,
                      title: "Yes, I speak other languages",
                    },
                    {
                      id: false,
                      title: `No, I only speak ${startingLanguage.name_eng}`,
                    },
                  ]}
                  />
              </div>
            </FormQuestion> */}

              {/* {speakOtherLanguages && <>
              <FormQuestion>
                <div>
                  <label>Which languages do you already speak?</label>
                  <CheckboxGroup
                    disabled={languagesLoading || languagesError}
                    groupName="languagesYouSpeak"
                    values={languagesYouSpeak}
                    setValues={setLanguagesYouSpeak}
                    options={languageOptions}
                  />
                </div>
                <div>
                  {languagesYouSpeak && languagesYouSpeak.length > 0 && <>
                    <FormHint>
                      OK, we'll use this to help you suggest cognates, or words that are almost the same in two languages. We may also contact you about helping us develop new courses (but you can unsubscribe anytime).
                    </FormHint>
                  </>}
                </div>
              </FormQuestion>
            </>} */}

              <br />

              <button
                className="button button-large button-full-width button-primary"
                type="submit"
                disabled={saving}
                data-testid="onboarding-create-account"
              >
                {saving ? "One moment..." : `All done? ${contentStrings?.lets_go || `Let's go`}!`}
              </button>

              <p style={{ fontSize: "var(--s)", margin: ".5rem 0 0" }}>
                * Note: this information may be visible on your public profile
              </p>
            </form>
          </LeftPanelInner>
        </LeftPanelWrapper>
        <RightPanelWrapper>
          <RightPanelInner>
            <MoveOnChange data-hidden={dataHidden}>
              <div
                style={{
                  display: "grid",
                  gap: "1rem",
                  gridTemplateColumns: "max-content auto",
                }}
              >
                <div>
                  <AvatarCharacter $width="6rem">
                    <img
                      src={`/images/conversation_avatars/victoruler/${testSituation?.character_id?.avatar_file}`}
                      alt={testSituation?.character_id?.name}
                    />
                  </AvatarCharacter>
                </div>
                <div
                  style={{
                    background: "var(--badge)",
                    padding: "1rem",
                    fontSize: "var(--l)",
                  }}
                >
                  <Definable wordString={fullWelcomePhrase} splitByWord />
                  <SpeakWord wordString={fullWelcomePhrase} />
                </div>
              </div>
            </MoveOnChange>
            <br />
            <br />
            <p>Try clicking the play button! (Make sure your speakers are on)</p>
          </RightPanelInner>
        </RightPanelWrapper>
      </PanelsWrapper>
    </>
  )
}
