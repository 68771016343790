import { Helmet } from "react-helmet-async"
import styled from "styled-components/macro"

export default ({ title, byLine, intro, children }) => {
  return (
    <>
      <Helmet>
        <title>{title} | Protolang blog</title>
      </Helmet>

      <BlogHeadline>{title}</BlogHeadline>
      <ByLine>{byLine}</ByLine>

      <Intro>{intro}</Intro>

      <ArticleWrapper>{children}</ArticleWrapper>
    </>
  )
}

const BlogHeadline = styled.h1`
  font-size: 2.5rem;
  font-size: 4.5rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.03em;
  margin: 0 auto 2rem;
  text-align: center;
  max-width: 60rem;
  padding: 4rem 2rem 0;
`
const ByLine = styled.div`
  font-size: var(--m);
  font-family: var(--font-family-sans);
  margin: 0 auto 4rem;
  text-align: center;
  max-width: 20rem;
  padding: 0 2rem;
`
const Intro = styled.section`
  font-size: 1.5rem;
  line-height: 1.6;
  max-width: 44rem;
  padding: 0;
  margin: 0 auto;
  p {
    margin: 0 0 1.2rem;
    font-weight: bold;

    /* &:first-child:first-letter {
      float: left;
      font-family: var(--font-family-serif);
      font-size: 75px;
      line-height: 60px;
      padding-top: 8px;
      padding-right: 4px;
    } */
  }
`
const ArticleWrapper = styled.div`
  max-width: 44rem;
  margin: 0 auto;

  h2 {
    font-size: 2.25rem;
    font-weight: 500;
    margin: 4rem 0 2rem;
  }
  h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 2rem 0 1rem;
    font-family: var(--font-family-sans);
  }
  section {
    /* max-width: 600px; */
    margin: 0 0 4rem 0;

    &:nth-child(odd) {
      /* margin-left: auto; */
    }
  }
  p,
  ul,
  ol {
    font-size: 1.2rem;
    line-height: 1.6;
    margin: 0 0 1rem;
    font-family: var(--font-family-serif);
  }
  li {
    margin: 0 0 0.5rem;
  }
  hr {
    width: 100%;
    border: none;
    height: 1rem;
    margin: 4rem 0;
    position: relative;

    &:after {
      content: "× × ×";
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      text-align: center;
      font-size: var(--s);
      line-height: 1;
      font-weight: bold;
    }
  }
`
