import * as Tooltip from "@radix-ui/react-tooltip"
import React from "react"
import ReactDOM from "react-dom/client"
import { HelmetProvider } from "react-helmet-async"
import { BrowserRouter } from "react-router-dom"

import DebugProvider from "@/context/debug"
import LanguageProvider from "@/context/language"
import OnboardingProvider from "@/context/onboarding"
import ToastProvider from "@/context/toast"
import UserProvider from "@/context/user"
import Router from "@/routes/Router"
import "@/styles/main.css"
import { trackingInit } from "@/vendor/sentry"
import { analyticsInit } from "@/vendor/vercel"

console.log("*-*-* PROTOLANG *-*-*")

const environment = import.meta.env.VITE_ENVIRONMENT
console.log("Environment:", environment)

// vercel analytics
analyticsInit()

// sentry
trackingInit()

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserRouter>
      <HelmetProvider>
        <ToastProvider>
          <Tooltip.Provider>
            <UserProvider>
              <DebugProvider>
                <OnboardingProvider>
                  <LanguageProvider>
                    <Router />
                  </LanguageProvider>
                </OnboardingProvider>
              </DebugProvider>
            </UserProvider>
          </Tooltip.Provider>
        </ToastProvider>
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>
)
