import { useTimer } from "use-timer"

import { useSpeechRecognition } from "@/context/speechRecognition"
import { testAnswerString, testPartialAnswerString } from "@/utils/string"
import { useEffect } from "react"

const INTERVAL_MILLISECONDS = 250 // use 1/4 of a second interval for more precision in UI
const THRESHOLD_LIMIT = 2 * 4 // time we wait from the start before giving any answer
const TIMEOUT_LIMIT = 5 * 4 // time we wait with no changes before assuming you're wrong

// https://www.google.com/intl/en/chrome/demos/speech.html
export default correctAnswers => {
  const {
    speechRecognitionIsSupported,
    willShowMicPermissionsModal,
    setHasInteractedWithMic,
    startSpeechRecognition,
    stopSpeechRecognition,
    clearSpeechRecognition,
    interimTranscript,
    finalTranscript,
    recognitionState,
  } = useSpeechRecognition()

  // TODO - USE TIMER LOGIC FROM SPEAKABLE INPUT
  // We did a better job there once we had visual debugging
  // https://www.npmjs.com/package/use-timer
  const {
    time: thresholdTimer,
    start: startThresholdTimer,
    reset: resetThresholdTimer,
  } = useTimer({ interval: INTERVAL_MILLISECONDS })
  const {
    time: timeoutTimer,
    start: startTimeoutTimer,
    reset: resetTimeoutTimer,
  } = useTimer({ interval: INTERVAL_MILLISECONDS })

  useEffect(() => {
    resetTimeoutTimer()
    startTimeoutTimer()
  }, [interimTranscript, finalTranscript])

  useEffect(() => {
    if (recognitionState === "listening") {
      startThresholdTimer()
      startTimeoutTimer()
    } else {
      resetThresholdTimer()
      resetTimeoutTimer()
    }
  }, [recognitionState])

  const finalIsCorrect = testAnswerString({
    answer: finalTranscript,
    correctAnswers,
  })
  const interimIsCorrect = testAnswerString({
    answer: interimTranscript,
    correctAnswers,
  })
  const isCorrectSoFar = testPartialAnswerString({
    answer: finalTranscript,
    correctAnswers,
  })
  const hasFinalTranscript = finalTranscript?.length
  const hasInterimTranscript = interimTranscript?.length
  const thresholdReached = thresholdTimer > THRESHOLD_LIMIT
  const isDefinitelyCorrect = interimIsCorrect || finalIsCorrect
  const isDefinitelyWrong =
    thresholdReached && hasFinalTranscript && !finalIsCorrect && !isCorrectSoFar
  const timedOut = timeoutTimer > TIMEOUT_LIMIT
  const userCorrectAnswer = !isDefinitelyCorrect
    ? null
    : interimIsCorrect
    ? interimTranscript
    : finalTranscript

  let correctStatus
  if (isDefinitelyCorrect) {
    console.log(
      `👂 [✅] correct answer: "${
        userCorrectAnswer || "[no answer]"
      }" matches the answers "${correctAnswers.join(", ")}"`,
      { interimTranscript, finalTranscript }
    )
    correctStatus = "correct"
    stopSpeechRecognition()
  } else if (isDefinitelyWrong) {
    console.log(
      `👂 [❌] incorrect answer: "${
        finalTranscript || "[no answer]"
      }" does not match the answers "${correctAnswers.join(", ")}"`,
      { interimTranscript, finalTranscript }
    )
    correctStatus = "incorrect"
    stopSpeechRecognition()
  } else if (timedOut) {
    console.log(`👂 [💀] timed out`, { interimTranscript, finalTranscript })
    correctStatus = "incorrect"
    stopSpeechRecognition()
  } else if (thresholdReached && !hasFinalTranscript && !hasInterimTranscript) {
    console.log(`👂 [🤷] no answer`, { interimTranscript, finalTranscript })
    correctStatus = "incorrect"
    stopSpeechRecognition()
  } else if (recognitionState !== "listening") {
    // speech recognition hasn't started yet
    // console was getting noisy since this basically triggers on every render
    // console.log(`👂 [❓] not started`, {interimTranscript, finalTranscript})
    correctStatus = "waiting"
  } else {
    console.log(`👂 [⏳] new transcripts, still pending correctness`, {
      interimTranscript,
      finalTranscript,
      correctAnswers,
      interimIsCorrect,
      finalIsCorrect,
      isCorrectSoFar,
      thresholdReached,
      thresholdTimer,
      timeoutTimer,
    })
    correctStatus = "waiting"
  }

  return {
    speechRecognitionIsSupported,
    willShowMicPermissionsModal,
    setHasInteractedWithMic,
    startSpeechRecognition,
    stopSpeechRecognition,
    clearSpeechRecognition,
    interimTranscript,
    finalTranscript,
    recognitionState,
    correctStatus,
    userCorrectAnswer,
  }
}
