import { Link } from "react-router-dom"
import styled from "styled-components/macro"

import { useLanguage } from "@/context/language"
import { useUser } from "@/context/user"
import { ProgressBar, ProgressBarWrapper } from "@/styles/ProgressBar"
import EditTopics from "@/views/dashboard/EditTopics"

export default ({ lessons, lessonProgressData = [] }) => {
  const { userId } = useUser()
  const { currentLanguage, currentUserLanguage } = useLanguage()

  console.log("📚 lessonProgressData", lessonProgressData)

  if (!lessons || !lessonProgressData) return "..."

  const topics = currentUserLanguage?.goal_topics || []

  // calculate progress for each topic based on number of lessons completed
  // TODO - someday get progress data by topic
  const topicData = topics.map(topic => {
    const lessonsInTopic = lessons?.filter(l => l.topics?.includes(topic))
    const phrasesCompleted = lessonProgressData
      ?.filter(p => lessonsInTopic?.some(l => l.id === p.id))
      .reduce((acc, p) => acc + p.completed_phrases, 0)
    const phrasesTotal = lessonsInTopic?.reduce((acc, l) => acc + l.num_phrases, 0)
    const progress = phrasesCompleted / phrasesTotal
    const nextLesson = lessonsInTopic?.find(
      l => !lessonProgressData?.some(p => p.lesson_id === l.id && p.completed)
    )
    return { topic, progress, nextLesson }
  })

  return (
    <div>
      <h4>By topic</h4>
      {!userId ? (
        <p>
          <Link to={`/login?redirectTo=${encodeURIComponent(window.location.pathname)}`}>
            Log in
          </Link>{" "}
          to track progress in your most important topics
        </p>
      ) : topicData.length === 0 ? (
        <div style={{ border: "1px dashed", padding: "1rem", textAlign: "center" }}>
          No topics selected · <EditTopics />
        </div>
      ) : (
        <TopicsListWrapper>
          {topicData.map(t => {
            return (
              <TopicRow key={t.topic}>
                <div>
                  {capitalizeFirstLetter(t.topic)}
                  {/* <div style={{fontSize: 'var(--s)'}}>
                {(t.progress || t.progress === 0) ? <>{Math.floor(t.progress*100)}% complete</> : 'No lessons yet'}
              </div> */}
                </div>
                <ProgressBarWrapper>
                  <ProgressBar progress={t.progress} disabled={!t.progress} />
                </ProgressBarWrapper>
                {t.nextLesson && (
                  <Link to={`/${currentLanguage.slug_eng}/lessons/${t.nextLesson?.random_slug}`}>
                    Practice →
                  </Link>
                )}
              </TopicRow>
            )
          })}
        </TopicsListWrapper>
      )}
    </div>
  )
}

// https://stackoverflow.com/a/1026087
function capitalizeFirstLetter(string) {
  if (typeof string !== "string")
    throw new Error(
      `capitalizeFirstLetter needs a string, got typeof ${typeof string} for ${string}`
    )
  if (!string || string.length === 0) return ""
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const TopicsListWrapper = styled.div``
const TopicRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.5rem 0;
`
