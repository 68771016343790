import * as Dialog from "@radix-ui/react-dialog"
import styled from "styled-components/macro"

import Icon from "@/styles/Icon"

interface Props {
  isOpen: boolean
  onClose: () => void
  title?: string
  width?: string
  icon?: string
  children: React.ReactNode
  hideCloseButton?: boolean
}

export default ({
  isOpen,
  onClose,
  title,
  width = "600px",
  icon,
  children,
  hideCloseButton,
}: Props) => {
  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Portal>
        <ModalOverlay onClick={onClose} />
        <ModalWrapper>
          <ModalContentWrapper style={{ width }}>
            {!hideCloseButton && <ModalX onClick={onClose}>✕</ModalX>}
            {title && (
              <ModalTitle>
                {icon && (
                  <div style={{ margin: "0 0 .5rem" }}>
                    <Icon name={icon} size="large" />
                  </div>
                )}
                {title}
              </ModalTitle>
            )}
            {/* <Dialog.Description /> */}
            {children}
          </ModalContentWrapper>
        </ModalWrapper>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 600;
`
const ModalOverlay = styled(Dialog.Overlay)`
  background: var(--overlay);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 600;

  // https://www.radix-ui.com/docs/primitives/guides/animation
  &[data-state="open"] {
    animation: fadeIn 200ms ease-out;
  }
  &[data-state="closed"] {
    animation: fadeOut 200ms ease-in;
  }
`
const ModalContentWrapper = styled(Dialog.Content)`
  z-index: 700;
  position: absolute;
  background: var(--bg);
  padding: 2rem;
  border: 1px solid;
  max-width: calc(100vw - 1rem);
  box-shadow: 3px 3px var(--shadow);
  max-height: 100%;
  overflow: auto;

  // https://www.radix-ui.com/docs/primitives/guides/animation
  &[data-state="open"] {
    animation: fadeSlideIn 200ms ease-out;
  }
  &[data-state="closed"] {
    animation: fadeSlideOut 200ms ease-in;
  }
`
const ModalX = styled(Dialog.Close)`
  z-index: 800;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: var(--bg);
  border: none;
  text-decoration: none;
  opacity: 0.7;
  font-size: var(--l);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;

  &:hover {
    opacity: 1;
  }
`
const ModalTitle = styled(Dialog.Title)`
  margin: 0 0 1rem;
`
