import { useEffect } from "react"
import styled from "styled-components/macro"

import { useUser } from "@/context/user"
import Icon from "@/styles/Icon"
import OnboardingStep from "@/views/_components/OnboardingStep"
import Typing from "@/styles/Typing"
import ConversationMessage from "@/views/conversations/ConversationMessage"

export default ({
  conversationMessages,
  isSendingMessage,
  responderIsTyping,
  situation,
  convoLoadedAt,
  conversation,
}) => {
  const { userId } = useUser()

  let fakeMessages
  if (!conversationMessages) {
    fakeMessages = [
      {
        id: "11111",
        conversation_id: conversation,
        content: "Ciao, sono il tuo assistente virtuale. Come posso aiutarti?",
        sender_type: "assistant",
        sender_id: userId,
        created_at: new Date(),
        updated_at: null,
        created_by: userId,
        updated_by: null,
      },
      {
        id: "22222",
        conversation_id: conversation,
        content: "Ciao! Ho bisogno di un prestito per comprare una macchina",
        sender_type: "user",
        sender_id: userId,
        created_at: new Date(),
        updated_at: null,
        created_by: userId,
        updated_by: null,
      },
      {
        id: "33333",
        conversation_id: conversation,
        content:
          "Certamente, posso aiutarti in questo. Per prima cosa, mi serve qualche informazione su di te. Come ti chiami?",
        sender_type: "assistant",
        sender_id: userId,
        created_at: new Date(),
        updated_at: null,
        created_by: userId,
        updated_by: null,
      },
    ]
  }

  // TODO - fix that the window isn't too tall, we shouldn't need this
  useEffect(() => {
    if (conversationMessages?.length) {
      window.scrollTo(0, document.body.scrollHeight - 80)
    }
  }, [conversationMessages])

  return (
    <ConversationMessagesWrapper>
      {(conversationMessages || fakeMessages).map((message, index) => {
        const isMostRecentMessage = index === conversationMessages?.length - 1

        const loadTimestamp = new Date(convoLoadedAt)
        const messageTimestamp = new Date(message.created_at)
        const isNewSincePageLoad = messageTimestamp > loadTimestamp

        if (message.sender_type === "assistant") {
          return (
            <OnboardingStep
              key={message.id}
              hide={index !== 0 || !conversationMessages?.length}
              delaySeconds={8}
              popoverContent={
                <>
                  <h3>
                    Here's a message from {situation?.character_id?.name_default || "Protolang"},
                    your chat partner
                  </h3>
                  <p>Try clicking on a word to see its definition</p>
                  <p>(And make sure your speakers are on)</p>
                </>
              }
              overlay={false}
              side={`top`}
              buttonText={false}
              flagName="has_completed_convo_message_onboarding"
              dependsOn={[
                "has_completed_chat_welcome_onboarding",
                "has_completed_situation_onboarding",
              ]}
            >
              <ConversationMessage
                message={message}
                situation={situation}
                autoPlaySpeech={isMostRecentMessage && isNewSincePageLoad}
              />
            </OnboardingStep>
          )
        }
        if (message.sender_type === "user") {
          return (
            <ConversationMessage
              key={message.id}
              message={message}
              situation={situation}
              autoPlaySpeech={isMostRecentMessage && isNewSincePageLoad}
            />
          )
        }
        if (message.sender_type === "editor") {
          return <GrammarHint key={message.id} message={message} />
        }
        // don't show internal messages sent to ChatGPT for context
        if (message.sender_type === "system") {
          return ""
        }
        return "Error: unrecognized sender type"
      })}
      {(responderIsTyping || (conversationMessages && !conversationMessages?.length)) && (
        <ConversationMessage message={{ sender_type: "assistant" }} situation={situation} />
      )}
      {/* {(isSendingMessage) && <ConversationMessage message={{sender_type: 'user'}} situation={situation} />} */}
      {isSendingMessage && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typing />
        </div>
      )}
    </ConversationMessagesWrapper>
  )
}

const GrammarHint = ({ message }) => {
  return (
    <GrammarHintWrapper key={message.id} sender={message.sender_type}>
      <Icon name="magic_button" filled />
      <div style={{ padding: "0 0 0 .25rem" }}>{message.content}</div>
    </GrammarHintWrapper>
  )
}

const ConversationMessagesWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  flex-direction: column;
  padding: 1rem 1rem 0;
  flex: 1;
  width: 100%;
`
const GrammarHintWrapper = styled.div`
  margin: 1rem 0 1rem auto;
  display: flex;
  justify-content: flex-end;
  max-width: 800px;
  align-items: flex-start;

  img {
    margin: 0 0.25rem 0 0;
    width: 16px;
    height: 16px;
    opacity: 0.8;
  }
`
