import * as Tooltip from "@radix-ui/react-tooltip"
import styled from "styled-components/macro"

// https://www.radix-ui.com/docs/primitives/components/tooltip
export default ({ label, children }) => (
  <Tooltip.Root>
    <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
    <Tooltip.Portal>
      <TooltipContent sideOffset={5}>
        {label}
        <TooltipArrow />
      </TooltipContent>
    </Tooltip.Portal>
  </Tooltip.Root>
)

const TooltipContent = styled(Tooltip.Content)`
  color: var(--bg);
  background: var(--text);
  padding: 0.25rem 0.5rem;
  font-size: var(--s);
  filter: drop-shadow(0px 1px 0px var(--bg));
  z-index: 800;
`
const TooltipArrow = styled(Tooltip.Arrow)`
  filter: drop-shadow(0px 0.66px 0px var(--bg));
`
