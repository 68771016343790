import { useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { Link, useMatch, useParams } from "react-router-dom"
import styled from "styled-components/macro"

import { useLanguage } from "@/context/language"
import { useUser } from "@/context/user"
import {
  Header,
  HeaderFooterLayoutWrapper,
  Main,
  MainWrapper,
  SecondaryNavFakeLink,
} from "@/styles/Layout"
import Footer from "@/views/_layout/components/Footer"
import LanguagePicker from "@/views/languages/LanguagePicker"
// import Banner from '@/styles/Banner'
import LayoutSimple from "@/views/_layout/LayoutSimple"
import Logo from "@/views/_layout/components/Logo"
import ErrorPage from "@/views/errors/ErrorPage"
import CreateProfile from "@/views/onboarding/CreateProfile"
import UserLanguageOnboarding from "@/views/onboarding/UserLanguageOnboarding"
import ReferencePanel from "@/views/reference/ReferencePanel"
// import LanguageVote from '@/views/languages/LanguageVote'
import useMediaQuery from "@/hooks/useMediaQuery"
import { SecondaryNavLink } from "@/styles/Layout"
import Popover from "@/styles/Popover"
import UserLanguageDropdown from "@/views/_layout/components/UserLanguageDropdown"
import FeedbackForm from "@/views/feedback/FeedbackForm"
// import DashboardWelcome from '@/views/onboarding/DashboardWelcome'
import ReferenceProvider from "@/context/reference"
import SoundEffectsProvider from "@/context/soundEffects"
import SpeechRecognitionProvider from "@/context/speechRecognition"
import SpeechSynthesisProvider from "@/context/speechSynthesis"
import { Badge } from "@/styles/Badge"
import FakeContentBg from "@/views/_components/FakeContentBg"
import OfflineWatcher from "@/views/_layout/components/OfflineWatcher"
import JoinWaitlist from "@/views/account/JoinWaitlist"
import PageNotFound from "@/views/errors/PageNotFound"
import LanguageComingSoon from "@/views/languages/LanguageComingSoon"
import SecretOptions from "@/views/settings/SecretOptions"

const APP_IS_RELEASED = true // flip switch when we launch something

export default ({ children }) => {
  const { currentLanguage, userLanguages, loading, error, setCurrentLanguageSlug } = useLanguage()
  const { langSlug } = useParams()
  const { userAuth, userProfile, isAlphaTester } = useUser()

  useEffect(() => setCurrentLanguageSlug(langSlug), [langSlug])

  // somehow we got a bad link and undefined ended up in the url bar as a string
  if (langSlug === "undefined") {
    return (
      <LayoutSimple>
        <LanguagePicker />
      </LayoutSimple>
    )
  }
  if (!currentLanguage) {
    return (
      <LayoutSimple>
        <LanguagePicker />
      </LayoutSimple>
    )
  }
  // PGRST116 = "results contain 0 rows"
  // Not really an error, just a 404.
  // Someone tried to go to protolang.com/foo and we couldn't find a language with the slug "foo"
  if (error && error.code === "PGRST116") {
    return (
      <LayoutSimple>
        <ErrorPage error={error} />
        <PageNotFound />
      </LayoutSimple>
    )
  }
  // All other errors we'll treat as real errors
  if (error) {
    return (
      <LayoutSimple>
        <ErrorPage error={error} />
      </LayoutSimple>
    )
  }
  if (loading || !currentLanguage) {
    return <LayoutSimple>Loading...</LayoutSimple>
  }

  if (userAuth && !userProfile) {
    return (
      <LayoutLanguageContainer>
        <CreateProfile />
      </LayoutLanguageContainer>
    )
  }

  // requirements for totally blocking a language
  // increase as the language gets closer to release
  if (
    currentLanguage.publish_status === "not_started" ||
    (currentLanguage.publish_status === "in_development" && !isAlphaTester) ||
    (currentLanguage.publish_status === "beta_testing" && !isAlphaTester && !APP_IS_RELEASED)
  ) {
    return (
      <LayoutLanguageContainer hideReferenceDrawer logoReturnsHome={true}>
        <LanguageComingSoon />
      </LayoutLanguageContainer>
    )
  }

  if (isAlphaTester && !userLanguages?.map(ul => ul.language_id?.id).includes(currentLanguage.id)) {
    return (
      <LayoutLanguageContainer>
        <MainWrapper style={{ position: "relative" }}>
          <FakeContentBg>
            <UserLanguageOnboarding />
          </FakeContentBg>
        </MainWrapper>
      </LayoutLanguageContainer>
    )
  }

  return (
    <LayoutLanguageContainer>
      {/* don't show modal, at least for now */}
      {/* {user && !user.has_completed_dashboard_onboarding && <DashboardWelcome />} */}
      {children}
    </LayoutLanguageContainer>
  )
}

interface LayoutLanguageContainerProps {
  logoReturnsHome?: boolean
  hideReferenceDrawer?: boolean
  children: React.ReactNode
}
const LayoutLanguageContainer = ({
  logoReturnsHome = false,
  hideReferenceDrawer,
  children,
}: LayoutLanguageContainerProps) => {
  const { currentLanguage } = useLanguage()
  const { userId, userAuth, isAdmin, isAlphaTester } = useUser()
  const isRootPage = Boolean(useMatch("/:langId"))
  // const { knownMicPermissionState } = useSpeechRecognition()

  const isDesktop = useMediaQuery("(min-width: 800px)")

  // wrap these in a span so they're side-by-side on mobile
  const authContent = (
    <span>
      <SecondaryNavLink to="/signup">Create account</SecondaryNavLink>
      {" / "}
      <SecondaryNavLink to="/login">Log in</SecondaryNavLink>
    </span>
  )

  const dropdownContent = (
    <>
      <div>{userAuth?.email}</div>
      {isAlphaTester && (
        <>
          <hr />
          <Link to="/settings">Settings</Link>
        </>
      )}
      <hr />
      {isAlphaTester && (
        <>
          <p style={{ fontSize: "var(--s)" }}>Your stuff</p>
          <Link to={`/${currentLanguage?.slug_eng}/attempts`}>Practice history</Link>
          <br />
          <Link to={`/${currentLanguage?.slug_eng}/conversations`}>Conversation history</Link>
          <br />
          <Link to={`/${currentLanguage?.slug_eng}/saved`}>Saved phrases</Link>
          <br />
          <Link to={`/${currentLanguage?.slug_eng}/mnemonics`}>Your mnemonics</Link>
          <hr />
        </>
      )}
      {/*
      Bring this back when sounds affect more than just conversations.
      Until then, just leave settings in the convo itself.
    */}
      {/* <p style={{fontSize: 'var(--s)'}}>Sounds</p>
    <SoundToggle />
    <hr /> */}
      <Link to="/logout">Log out</Link>
      <SecretOptions />
    </>
  )

  const navContent = (
    <ContentWrapper $isMobile={!isDesktop}>
      {/* TODO - show this again when we start detecting permission using permissions api */}
      {/* {knownMicPermissionState === 'denied' && 
      <HeaderWarning href="https://www.lifewire.com/configure-camera-microphone-setting-in-google-chrome-4103623" target="_blank">
        Microphone access denied
      </HeaderWarning>
    } */}
      <OfflineWatcher />
      <FeedbackForm />
      <div style={{ width: ".5rem" }} />
      <UserLanguageDropdown />
      <div style={{ width: ".5rem" }} />
      {isAdmin && currentLanguage.id && (
        <SecondaryNavLink to={`/${currentLanguage.slug_eng}/admin`}>Admin</SecondaryNavLink>
      )}
      {isAlphaTester && (
        <SecondaryNavLink to={`/${currentLanguage.slug_eng}/analytics`}>Analytics</SecondaryNavLink>
      )}
      {isDesktop ? (
        <>
          {userId ? (
            <Popover align="end" trigger={<SecondaryNavFakeLink>Account</SecondaryNavFakeLink>}>
              {dropdownContent}
            </Popover>
          ) : (
            authContent
          )}
        </>
      ) : (
        <>
          <hr />
          <div>{userId ? dropdownContent : authContent}</div>
        </>
      )}
    </ContentWrapper>
  )

  return (
    <>
      <Helmet>
        <title>Learn {currentLanguage.name_eng} | Protolang</title>
      </Helmet>
      <SpeechSynthesisProvider>
        <SpeechRecognitionProvider>
          <SoundEffectsProvider>
            <ReferenceProvider>
              <HeaderFooterLayoutWrapper>
                <Header>
                  <nav>
                    <Logo to={logoReturnsHome ? `/` : `/${currentLanguage.slug_eng}`} />
                    {/* <MainNavLink to={`/${currentLanguage.slug_eng}/lessons`}>Lessons</MainNavLink> */}
                    {/* <MainNavLink to={`/${currentLanguage.slug_eng}/practice`}>Practice</MainNavLink> */}
                    {/* <MainNavLink to={`/${currentLanguage.slug_eng}/conversation`}>Conversation</MainNavLink> */}
                    {/* <MainNavLink to={`/${currentLanguage.slug_eng}/read`}>Read</MainNavLink> */}
                    {/* <Popover align="start" minWidth="12rem"
                    trigger={<MainNavFakeLink>more...</MainNavFakeLink>}
                  >
                    <MainNavLink to={`/${currentLanguage.slug_eng}/tv`} className="button" style={{width: '100%'}}>
                      <Icon name="tv_gen" />
                      &nbsp;&nbsp;
                      TV
                    </MainNavLink>
                    <div style={{height: '.5rem'}} />
                    <MainNavLink to={`/${currentLanguage.slug_eng}/radio`} className="button" style={{width: '100%'}}>
                      <Icon name="radio" />
                      &nbsp;&nbsp;
                      Radio
                    </MainNavLink>
                    {!userId && <>
                      <hr />
                      <p>Sign up to get notified of new features!</p>
                      <JoinWaitlist />
                    </>
                    }
                  </Popover> */}
                  </nav>
                  <div>
                    {isDesktop ? (
                      navContent
                    ) : (
                      <Popover
                        align="end"
                        trigger={<button className="button button-small">Menu</button>}
                      >
                        {navContent}
                      </Popover>
                    )}
                  </div>
                </Header>

                <Main>
                  {/* {!userId && <Banner><Link to="/signup">Create a free account</Link> to save your progress</Banner> } */}
                  {children}
                  {!userId && false && (
                    <AccountPromptWrapper>
                      <AccountPromptBox>
                        <Badge>Coming soon</Badge>
                        <>The immersive language app for self-motivated learners</>
                        <JoinWaitlist />
                      </AccountPromptBox>
                    </AccountPromptWrapper>
                  )}
                  {!hideReferenceDrawer && (!isRootPage || isAlphaTester) && <ReferencePanel />}
                </Main>

                <Footer />
              </HeaderFooterLayoutWrapper>
            </ReferenceProvider>
          </SoundEffectsProvider>
        </SpeechRecognitionProvider>
      </SpeechSynthesisProvider>
    </>
  )
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: ${p => (p.$isMobile ? "column" : "row")};
  align-items: ${p => (p.$isMobile ? "stretch" : "center")};
`
const AccountPromptWrapper = styled.div`
  pointer-events: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 1));
  /* background: red; */
  padding: 10rem 2rem 2rem;
`
const AccountPromptBox = styled.div`
  pointer-events: all;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  box-shadow: 2px 2px var(--shadow);
  border: 1px solid;
  text-align: center;
  /* width: 1000px; */
  max-width: 100%;
  background: var(--bg);
`
const HeaderWarning = styled.a`
  color: red;
  font-weight: 500;
  margin: 0 1rem 0 0;
  font-size: var(--s);
`
