import { useUser } from "@/context/user"
import { Link } from "react-router-dom"

export default () => {
  const { userAuth, logout } = useUser()

  return (
    <>
      <h1>Account</h1>

      <form>
        <label htmlFor="input_email">Email</label>
        <input
          id="input_email"
          type="text"
          defaultValue={userAuth?.email}
          placeholder="your@email.com"
          disabled
        />
        <p style={{ fontSize: "var(--s)" }}>
          Need to change your email address? Please{" "}
          <Link to={`/contact`}>contact the support team</Link>
        </p>
      </form>

      <br />

      <button onClick={() => logout()}>Log out →</button>
    </>
  )
}
