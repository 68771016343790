import { Helmet } from "react-helmet-async"

import { useSpeechSynthesis } from "@/context/speechSynthesis"
import { Link } from "@/styles/Links"
import SpeakWord from "@/views/_components/SpeakWord"

export default () => {
  const { voice, voices, setVoice } = useSpeechSynthesis()

  const updateVoice = newVoice => {
    const voice = voices.find(v => v.name === newVoice.name)
    console.log("updateVoice", voice)
    setVoice(voice)
  }

  return (
    <>
      <Helmet>
        <title>Voice Sandbox | Protolang</title>
      </Helmet>

      <h1>Voice sandbox</h1>
      <p>
        This is a behind-the-scenes tool to help developers debug voices.
        <strong>
          If you're not sure why you're here, please{" "}
          <Link to="/">click here to go to the homepage</Link>.
        </strong>
      </p>

      {voices?.map(v => {
        const { name } = v
        return (
          <button
            key={name}
            className="button"
            style={{ background: name === voice?.name && "#ccc" }}
            onClick={() => updateVoice(v)}
          >
            {v?.name}
          </button>
        )
      })}
      <SpeakWord wordString="andiamo" />
    </>
  )
}
