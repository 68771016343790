import { MainWrapper } from "@/styles/Layout"
import AttemptsList from "@/views/practice/AttemptsList"

export default () => {
  return (
    <MainWrapper>
      <h1>Practice history</h1>
      <AttemptsList />
    </MainWrapper>
  )
}
