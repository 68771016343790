import { createContext, useContext, useRef, useState } from "react"

// import { logError } from '@/utils/error'

const Context = createContext({
  soundFxOn: true,
  setSoundFxOn: (value: boolean) => {},
  repeatOn: true,
  setRepeatOn: (value: boolean) => {},
  audioOnly: false,
  setAudioOnly: (value: boolean) => {},
  playSuccess: () => {},
  playTryAgain: () => {},
  playError: () => {},
  playTap: () => {},
  playTapForce: () => {},
})

export default ({ children }) => {
  const [soundFxOn, setSoundFxOn] = useState(true)
  const [repeatOn, setRepeatOn] = useState(true)
  const [audioOnly, setAudioOnly] = useState(false)

  const soundRefCorrect = useRef(new Audio("/sounds/sound_effects/523424__andersmmg__ding-3.wav"))
  const soundRefTryAgain = useRef(
    new Audio("/sounds/sound_effects/171697__nenadsimic__menu-selection-click.wav")
  )
  const soundRefError = useRef(
    new Audio("/sounds/sound_effects/268108__nenadsimic__button-tick.wav")
  )
  const soundRefTap = useRef(
    new Audio("/sounds/sound_effects/478285__joao_janz__finger-tap-2_5.wav")
  )

  const playSuccess = () => soundFxOn && soundRefCorrect.current.play()
  const playTryAgain = () => soundFxOn && soundRefTryAgain.current.play()
  const playError = () => soundFxOn && soundRefError.current.play()
  const playTap = () => soundFxOn && soundRefTap.current.play()
  // I tried using playTap as an indicator for users who turn on sound effects,
  // but I think there's a race condition where the sound effect doesn't play
  // because soundFx are already on. So: new function that *always* plays the sound.
  const playTapForce = () => soundRefTap.current.play()

  const exposed = {
    soundFxOn,
    setSoundFxOn,

    repeatOn,
    setRepeatOn,

    audioOnly,
    setAudioOnly,

    playSuccess,
    playTryAgain,
    playError,
    playTap,
    playTapForce,
  }

  return <Context.Provider value={exposed}>{children}</Context.Provider>
}

export const useSoundFx = () => useContext(Context)
