import { useState } from "react"
import { Link, Navigate, useSearchParams } from "react-router-dom"
import styled from "styled-components/macro"

import { useUser } from "@/context/user"
import OrBar from "@/styles/OrBar"
import { logError } from "@/utils/error.js"
import { Helmet } from "react-helmet-async"

export default () => {
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)

  const { userId, signInWithOtp, signInWithGoogle } = useUser()
  const [searchParams] = useSearchParams()
  const redirectToSearchParam = searchParams.get("redirectTo")
  const redirectTo = redirectToSearchParam ? decodeURI(redirectToSearchParam) : "/"

  const onSubmit = async event => {
    event.preventDefault()
    try {
      setLoading(true)
      await signInWithOtp(email)
    } catch (error) {
      logError("log in", error)
    } finally {
      setLoading(false)
    }
  }

  if (userId) {
    return <Navigate to={redirectTo} />
  }

  return (
    <LoginLayoutWrapper>
      <Helmet>
        <title>Log in | Protolang</title>
      </Helmet>
      <LoginLayoutInner>
        <h1 style={{ margin: "0 0 1.5rem" }}>Log in</h1>

        <button
          className="button button-primary button-full-width"
          onClick={signInWithGoogle}
          disabled={loading}
        >
          <img
            src="/images/social_icons/google.svg"
            alt="Google"
            style={{ height: "1rem", marginRight: ".25rem" }}
          />
          Log in with Google
        </button>
        <OrBar />
        <form onSubmit={onSubmit}>
          <div className="input-button-set">
            <input
              type="email"
              placeholder="Your email"
              value={email}
              disabled={loading}
              onChange={e => setEmail(e.target.value)}
              required
            />
            <button className="button" disabled={loading} type="submit">
              {loading ? "Sending..." : "Send me a link"}
            </button>
          </div>
        </form>
        <p style={{ fontSize: "var(--s)" }}>
          By logging in, you agree to our <Link to="/terms">Terms of Service</Link>
        </p>
        <br />
        <div style={{ textAlign: "center", marginBottom: "-.5rem" }}>
          <Link to="/support#log-in-trouble">Need help logging in?</Link>
        </div>
      </LoginLayoutInner>
      <br />
      <div style={{ textAlign: "center" }}>
        New around here? <Link to="/signup">Create your account</Link>
      </div>
    </LoginLayoutWrapper>
  )
}

const LoginLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-bottom: 3rem;
`
const LoginLayoutInner = styled.div`
  width: 100%;
  max-width: 26rem;
  padding: 2rem;
  border: 1px solid;
  box-shadow: 2px 2px var(--shadow);

  @media (max-width: 600px) {
    padding: 0;
    border: none;
    box-shadow: none;

    input {
      width: auto;
    }
  }
`
