// If you add a new kind of local storage flag, PLEASE add it here!
// This is the only place we should be reading/writing to localStorage.
// Also, don't delete these keys--that way we remember what we've saved before.
// And prefix all keys with 'protolang-' to avoid collisions (just in case).

interface LocalStorageSchema {
  ['protolang-debug-mode-on']: boolean
  ['protolang-temporary-conversation-limit-override']: boolean
  ['protolang-has-prompted-for-mic-permissions']: boolean
  ['protolang-preference-dark-mode']: 'dark' | 'light' | 'system'
  ['protolang-onboarding-flags']: any
}

// https://medium.com/@gcascio/how-to-add-types-to-your-local-storage-9e47ca0087af
export function getLocalStorage<T extends keyof LocalStorageSchema>(key: T): LocalStorageSchema[T] | null {
  if (!window?.localStorage) throw new Error(`Cannot get localStorage key "${key}" on server`)
  return JSON.parse(window.localStorage.getItem(key)) as LocalStorageSchema[T]
}
export function setLocalStorage<T extends keyof LocalStorageSchema>(key: T, value: LocalStorageSchema[T]): void {
  if (!window?.localStorage) throw new Error(`Cannot set localStorage key "${key}" on server`)
  window.localStorage.setItem(key, JSON.stringify(value))
}
export function removeLocalStorage<T extends keyof LocalStorageSchema>(key: T): void {
  if (!window?.localStorage) throw new Error(`Cannot remove localStorage key "${key}" on server`)
  window.localStorage.removeItem(key)
}
