import styled from "styled-components/macro"

import Icon from "@/styles/Icon"

export const MultiPicker = ({ groupName, options, values, setValues, disabled }) => {
  if (!groupName) throw new Error("Group name is required.")
  if (!values) throw new Error("Values must be an array.")
  if (!Array.isArray(values)) throw new Error("Values must be an array.")
  if (options.some(o => !o.id)) throw new Error("All options must have an id.")
  if (!setValues) throw new Error("setValues must be a function.")

  return (
    <MultiPickerWrapper>
      {/* TODO - is this accessible? */}
      {values.map(value => {
        const option = options.find(o => o.id === value)
        return (
          <ChosenOption key={value}>
            <div>
              {option.title}{" "}
              <span style={{ fontSize: "small", opacity: ".6" }}>{option.description}</span>
            </div>
            <button
              className="button button-tertiary button-small"
              onClick={() => {
                let newValues = [...values]
                const index = newValues.indexOf(value)
                newValues.splice(index, 1)
                setValues(newValues)
              }}
            >
              <Icon name="delete" />
            </button>
          </ChosenOption>
        )
      })}
      <div style={{ height: "1rem" }} />
      <input
        type="text"
        name="example"
        disabled={disabled}
        list={groupName}
        placeholder="Add a language..."
        onChange={e => {
          const newTextValue = e.target.value
          // TODO - better way to get the value of the datalist.
          const newOption = options.find(o => o.title === newTextValue)
          if (newOption) {
            let newValues = [...values]
            newValues = [...newValues, newOption.id]
            setValues(newValues)
            e.target.value = ""
          }
        }}
      />
      <datalist id={groupName}>
        {options.map(option => {
          return (
            <option key={option.title} value={option.title}>
              {option.description}
            </option>
          )
        })}
      </datalist>
    </MultiPickerWrapper>
  )
}

const ChosenOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem;
  border-style: solid;
  border-width: 1px 0;
  margin: -1px 0 0;
  max-width: 400px;
`
const MultiPickerWrapper = styled.div`
  margin: 1rem 0 1rem;
`
