import { Link } from "react-router-dom"

import { supabase, useSupabaseQuery } from "@/db/supabase"
import AdminId from "@/views/_components/AdminId"
import { fromNow } from "@/utils/date"
import SupabaseAdminLink from "@/views/admin/SupabaseAdminLink"
import ErrorPage from "@/views/errors/ErrorPage"

export default () => {
  const query = supabase.from("conversation_characters").select("*, created_by(*)")
  const [characters, loading, error] = useSupabaseQuery(query)

  if (error) return <ErrorPage error={error} />
  if (loading) return <div>loading...</div>
  if (!characters || characters.length <= 0) return <div>no characters</div>

  return (
    <>
      <h1>Characters</h1>
      {characters.length} character{characters.length === 1 ? "" : "s"}
      <hr />
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Slug</th>
            <th>Name</th>
            <th>Age</th>
            <th>Gender</th>
            <th>Avatar</th>
            <th>Personality</th>
            <th>Updated</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {characters.map(character => {
            return (
              <tr key={character.id}>
                <td>
                  <AdminId>{character.id}</AdminId>
                </td>
                <td>
                  <strong>{character.name_slug}</strong>
                </td>
                <td>{character.name_default}</td>
                <td>{character.age}</td>
                <td>{character.gender}</td>
                <td>
                  <img
                    src={`/images/conversation_avatars/victoruler/${character.avatar_file}`}
                    style={{
                      width: "80px",
                      height: "80px",
                    }}
                  />
                </td>
                <td>{character.personality}</td>
                <td>
                  {character.created_by?.username ? (
                    <Link to={`/u/${character.created_by.username}`}>
                      {character.created_by.username}
                    </Link>
                  ) : (
                    "❌ no username"
                  )}
                </td>
                <td>{fromNow(character.created_at)}</td>
                <td>{fromNow(character.updated_at)}</td>
                <td>
                  <SupabaseAdminLink table="characters" id={character.id} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}
