import * as Popover from "@radix-ui/react-popover"
import { useEffect, useState } from "react"
import styled from "styled-components/macro"

import type { OnboardingFlagName } from "@/context/onboarding"
import { useOnboarding } from "@/context/onboarding"
import { useUser } from "@/context/user"
import { supabase } from "@/db/supabase"
import useMediaQuery from "@/hooks/useMediaQuery"
import Modal from "@/styles/Modal"
import { logError } from "@/utils/error"
import { getLocalStorage, setLocalStorage } from "@/utils/localStorage"

let DISABLE_ONBOARDING = true
// DISABLE_ONBOARDING = false

interface OnboardingStepProps {
  overlay?: boolean
  delaySeconds?: number
  align?: "start" | "center" | "end"
  side?: "top" | "right" | "bottom" | "left"
  minWidth?: string
  popoverContent: React.ReactNode
  hide?: boolean
  dependsOn?: string[]
  // keep in sync with database?
  flagName: OnboardingFlagName
  buttonText?: string | false
  modal?: boolean
  children?: React.ReactNode
}

export default ({
  overlay = false,
  delaySeconds = 0.5,
  align = "center",
  side = "bottom",
  minWidth = "auto",
  popoverContent,
  hide = false,
  dependsOn = [],
  flagName,
  buttonText,
  modal = false,
  children,
}: OnboardingStepProps) => {
  const isDesktop = useMediaQuery("(min-width: 800px)")

  const { userId } = useUser()
  const [visible, setVisible] = useState(false)
  const [isClosing, setIsClosing] = useState(false)

  const { flagsObject } = useOnboarding()

  const onClose = async () => {
    setIsClosing(true)
    try {
      if (!userId) {
        const localFlags = getLocalStorage("protolang-onboarding-flags")
        setLocalStorage("protolang-onboarding-flags", {
          ...localFlags,
          [flagName]: true,
        })
        return
      }

      const { error } = await supabase
        .from("user_profiles")
        .update({
          [flagName]: true,
        })
        .eq("id", userId)
      if (error) throw error
    } catch (error) {
      logError("finish tour", error)
    }
  }

  const isWaitingOnOtherSteps = dependsOn.some(dependency => !flagsObject?.[dependency])
  const isOpen =
    !DISABLE_ONBOARDING &&
    flagsObject &&
    !flagsObject[flagName] &&
    !isClosing &&
    !hide &&
    !isWaitingOnOtherSteps &&
    visible

  useEffect(() => {
    setTimeout(() => setVisible(true), delaySeconds * 1000)
  }, [])

  if (modal) {
    if (!isOpen) return null
    return (
      <Modal isOpen={true} onClose={onClose}>
        {popoverContent}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            maxWidth: "200px",
            margin: "0 auto",
          }}
        >
          {buttonText && (
            <button
              className="button button-primary button-large button-full-width"
              onClick={onClose}
            >
              {buttonText}
            </button>
          )}
        </div>
      </Modal>
    )
  }

  return (
    <Popover.Root open={isOpen} modal={overlay} onOpenChange={isOpen => !isOpen && onClose()}>
      <OnboardingTourPopoverAnchor
        $isOpen={isOpen}
        $overlay={overlay}
        onClick={event => event.stopPropagation()}
      >
        {children}
      </OnboardingTourPopoverAnchor>
      {isOpen && overlay && <OnboardingTourOverlay $visible={visible} />}
      <Popover.Portal>
        <OnboardingTourPopoverContent
          align={align}
          side={side}
          style={{ minWidth }}
          sideOffset={isDesktop && overlay ? 16 : 0}
          $isMobile={!isDesktop}
          $isOpen={isOpen}
          $dark={!overlay}
          onClick={event => event.stopPropagation()}
        >
          {popoverContent}
          {buttonText && (
            <button className="button" onClick={onClose}>
              {buttonText}
            </button>
          )}
          <OnboardingTourPopoverClose>✕</OnboardingTourPopoverClose>
          <OnboardingTourPopoverArrow />
        </OnboardingTourPopoverContent>
      </Popover.Portal>
    </Popover.Root>
  )
}

const OnboardingTourOverlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: var(--overlay);
  z-index: 1000;
  backdrop-filter: blur(1px);
  /* opacity: ${p => (p.$visible ? 1 : 0)}; */
  /* transition: all 0.4s ease-out; */

  /* https://www.radix-ui.com/docs/primitives/components/popover */
  animation-duration: 800ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: opacity;

  animation-name: fadeIn;
  &[data-state="open"] {
    animation-name: fadeOut;
  }
`
const OnboardingTourPopoverAnchor = styled(Popover.Anchor)`
  position: ${p => p.$isOpen && "relative"};
  background: ${p => p.$isOpen && p.$overlay && "var(--bg)"};
  box-shadow: ${p => p.$isOpen && p.$overlay && "0 0 1rem 1rem var(--bg)"};
  border-radius: ${p => p.$isOpen && p.$overlay && "1rem"};
  margin: ${p => p.$isOpen && p.$overlay && "-1rem"};
  padding: ${p => p.$isOpen && p.$overlay && "1rem"};
  z-index: ${p => p.$isOpen && 1100};
`
const OnboardingTourPopoverContent = styled(Popover.Content)`
  position: relative;
  z-index: 1100;
  color: ${p => (p.$dark ? "var(--bg)" : "var(--text)")};
  background: ${p => (p.$dark ? "var(--text)" : "var(--bg)")};
  border: ${p => (p.$dark ? "1px solid var(--text)" : "1px solid var(--text)")};
  box-shadow: ${p => (p.$isMobile ? "0 4px var(--bg)" : p.$dark ? "none" : "4px 4px")};
  padding: 1.5rem 2rem 1.5rem 1.5rem;
  width: ${p => p.$isMobile && "100vw"};
  max-width: 50vw;

  & > *:first-child {
    margin-top: 0;
  }
  & > *:last-child {
    margin-bottom: 0;
  }

  /* https://www.radix-ui.com/docs/primitives/components/popover */
  animation-duration: 800ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-state="open"][data-side="top"] {
    animation-name: slideUpAndFade;
  }
  &[data-state="open"][data-side="right"] {
    animation-name: slideRightAndFade;
  }
  &[data-state="open"][data-side="bottom"] {
    animation-name: slideDownAndFade;
  }
  &[data-state="open"][data-side="left"] {
    animation-name: slideLeftAndFade;
  }
`
const OnboardingTourPopoverClose = styled(Popover.Close)`
  font-family: var(--font-family-sans);
  height: 2.5rem;
  width: 2.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.3;
  transition: opacity 0.1s linear;
  text-decoration: none;

  &:hover {
    opacity: 1;
  }
  &:focus {
    opacity: 1;
  }
`
const OnboardingTourPopoverArrow = styled(Popover.Arrow)`
  fill: var(--text);
`
