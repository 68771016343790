import { useLanguage } from "@/context/language"
import { useUser } from "@/context/user"
import { supabase, useSupabaseQuery } from "@/db/supabase"
import LessonListItem from "@/views/lessons/LessonListItem"

export default ({ lesson }) => {
  const { currentLanguage } = useLanguage()
  const { userId } = useUser()

  const lessonsQuery = supabase
    .from("lessons")
    .select()
    .eq("language_id", currentLanguage.id)
    .order("sort_order", { ascending: true })
  const [lessons, lessonsLoading, lessonsError] = useSupabaseQuery(lessonsQuery, [
    currentLanguage.id,
  ])

  const lessonProgressQuery = supabase.from("user_lesson_progress").select().eq("user_id", userId)
  const [lessonProgressData, lessonProgressLoading, lessonProgressError] = useSupabaseQuery(
    lessonProgressQuery,
    [userId]
  )

  const lessonTopics = lesson?.topics || []
  const relatedLessons = lessons
    ?.filter(l => l.topics?.some(t => lessonTopics.includes(t)))
    .filter(
      l => l.num_phrases !== lessonProgressData?.find(p => p.lesson_id === l.id)?.completed_phrases
    )
    .filter(l => l.id !== lesson.id)
    .slice(0, 5)

  const error = lessonsError || lessonProgressError
  const loading = lessonsLoading || lessonProgressLoading

  return (
    <>
      {error ? (
        error.message
      ) : loading ? (
        "loading..."
      ) : relatedLessons.length === 0 ? null : (
        <>
          <div style={{ height: "0.5rem" }} />
          {relatedLessons.map(l => {
            return <LessonListItem key={l.id} lesson={l} lessonProgressData={lessonProgressData} />
          })}
        </>
      )}
    </>
  )
}
