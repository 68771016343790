import * as Toast from "@radix-ui/react-toast"
import { createContext, useContext, useState } from "react"
import styled from "styled-components/macro"

const Context = createContext()

export default ({ children }) => {
  const [toastContent, setToastContent] = useState({})
  const [open, setOpen] = useState(false)

  const setToast = content => {
    setToastContent(content)
    setOpen(true)
  }

  const exposed = {
    setToast,
  }

  return (
    <Context.Provider value={exposed}>
      <Toast.Provider duration={5 * 1000}>
        {children}
        <ToastRoot open={open} onOpenChange={setOpen}>
          <ToastBody>
            <ToastTitle>{toastContent}</ToastTitle>
          </ToastBody>
          <ToastClose>✕</ToastClose>
        </ToastRoot>
        <ToastViewport />
      </Toast.Provider>
    </Context.Provider>
  )
}

export const useToast = () => useContext(Context)

export const ToastViewport = styled(Toast.Viewport)`
  position: fixed;
  bottom: 1.5rem;
  left: 1.5rem;
  z-index: 900;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
`
export const ToastRoot = styled(Toast.Root)`
  display: flex;
  gap: 1rem;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  max-width: 300px;

  background-color: var(--bg);
  color: var(--text);
  border: 1px solid var(--text);
  box-shadow: 2px 2px var(--shadow);
`
export const ToastBody = styled.div``
export const ToastTitle = styled(Toast.Title)``
export const ToastClose = styled(Toast.Close)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;

  font-size: var(--m);
  text-decoration: none;

  &:hover {
    background-color: var(--badge);
    cursor: pointer;
  }
`
