import { Helmet } from "react-helmet-async"

import { useUser } from "@/context/user"
import { MainWrapper, OneCol } from "@/styles/Layout"
import CharactersDownload from "@/views/downloads/CharactersDownload"
import LessonsDownload from "@/views/downloads/LessonsDownload"
import MnemonicsDownload from "@/views/downloads/MnemonicsDownload"
import PhrasesDownload from "@/views/downloads/PhrasesDownload"
import SituationsDownload from "@/views/downloads/SituationsDownload"

export default () => {
  const { isAlphaTester } = useUser()
  if (!isAlphaTester)
    return (
      <MainWrapper>
        <Helmet>
          <title>Open content | Protolang</title>
        </Helmet>
        <OneCol>
          <h1>Open content</h1>
          <p>Open content downloads coming soon!</p>
        </OneCol>
      </MainWrapper>
    )

  return (
    <MainWrapper>
      <Helmet>
        <title>Open content | Protolang</title>
      </Helmet>
      <OneCol>
        <h1>Open content</h1>
        {/* <p>This project would not be possible without the open-content, community-driven projects it is built on.</p> */}
        <p>We are proud to offer much of our own content for use under free content licenses.</p>

        <h2>Data downloads</h2>
        <p>
          User-generated content is available to download here. All of this content is free to use
          under a Creative Commons license (see individual files for license details).
        </p>
        <ul>
          <li>
            <LessonsDownload />
          </li>
          <li>
            <PhrasesDownload />
          </li>
          <li>
            <MnemonicsDownload />
          </li>
          <li>
            <SituationsDownload />
          </li>
          <li>
            <CharactersDownload />
          </li>
        </ul>

        {/* <p>Occasional backups of these files are made and <a href="https://github.com/sampl/protolang-content-archive" target="_blank">posted to Github for archiving purposes</a>.</p> */}

        <h2>Attribution</h2>

        <p>Protolang uses data from the following open content projects:</p>
        <ul>
          {/* <li>
          <a href="https://tatoeba.org/" target="_blank">Tatoeba</a>
        </li> */}
          <li>
            <a href="https://en.wiktionary.org/" target="_blank">
              Wiktionary
            </a>
          </li>
          <li>
            <a href="https://github.com/tatuylonen/wiktextract" target="_blank">
              tatuylonen/wiktextract
            </a>
          </li>
          {/* <li>
          <a href="https://github.com/ian-hamlin/verb-data" target="_blank">ian-hamlin/verb-data</a>
        </li> */}
          {/* <li>
          <a href="https://github.com/argosopentech/argos-translate/" target="_blank">Argos Translate</a>
        </li>
        <li>
          <a href="https://libretranslate.com/" target="_blank">LibreTranslate</a>
        </li> */}
        </ul>
      </OneCol>
    </MainWrapper>
  )
}
