import { useEffect, useState } from "react"
import styled from "styled-components/macro"

import Icon from "@/styles/Icon"

// TODO - put logic in context so other components can disable things based on network status
// TODO - a single place to put all warnings, for ex when the mic is disabled?

export default () => {
  const [isOnline, setIsOnline] = useState(true)

  const handleOffline = () => {
    console.log("🛜 lost internet connection")
    setIsOnline(false)
  }
  const handleOnline = () => {
    console.log("🛜 regained internet connection")
    setIsOnline(true)
  }

  useEffect(() => {
    window.addEventListener("offline", handleOffline)
    window.addEventListener("online", handleOnline)
    return () => {
      window.removeEventListener("offline", handleOffline)
      window.removeEventListener("online", handleOnline)
    }
  }, [])

  if (isOnline) return null

  return (
    <OfflineWrapper>
      {/* <Icon name="wifi_off" /> */}
      <Icon name="cloud_off" />
      No internet connection
    </OfflineWrapper>
  )
}

const OfflineWrapper = styled.div`
  color: red;
  font-size: var(--s);
  display: flex;
  gap: 0.25rem;
  margin-right: 1rem;
  align-items: center;
  user-select: none;
`
