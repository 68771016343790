import { useEffect, useState } from "react"
import { useDebounce } from "use-debounce"

import { useLanguage } from "@/context/language"
import { supabase } from "@/db/supabase"
import Typing from "@/styles/Typing"
import { logError } from "@/utils/error"
import Definable from "@/views/_components/Definable"
import SpeakWord from "@/views/_components/SpeakWord"
import ErrorComponent from "@/views/errors/ErrorComponent"

export default ({ wordString }) => {
  const { currentLanguage, contentStrings } = useLanguage()

  const [wordStringDebounced] = useDebounce(wordString, 500, { leading: true })

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [conjugations, setConjugations] = useState(null)

  useEffect(() => {
    const triggerRemoteFunction = async () => {
      if (!wordStringDebounced || wordStringDebounced.trim() === "") {
        setLoading(false)
        return
      }
      try {
        // TODO - use open ai functions here too
        setLoading(true)
        console.log("🏃 triggering edge function ai_prompt")

        const messages = [
          {
            role: "system",
            content: `
              When the user shares a/an ${currentLanguage.name_eng} word or phase, please respond with a list of verb conjugations.
              Include the pronoun for each conjugation.
              Add a new line between each conjugation.
              Conjugate into its current tense, or the present tense if it is an infinitive.
            `,
          },
          {
            role: "user",
            content: wordStringDebounced,
          },
        ]

        const functions = [
          {
            name: "generate_conjugations",
            description: `Generate a list of ${currentLanguage.name_eng} verb conjugations for the word or phrase "${wordStringDebounced}"`,
            parameters: {
              type: "object",
              properties: {
                is_verb: {
                  type: "boolean",
                  description: `Whether or not the provided word or phrase is a verb that can be conjugated`,
                },
                tense: {
                  type: "string",
                  description: `The ${currentLanguage.name_eng} verb tense that the provided word or phrase is conjugated into. For example, "Present tense" or "Past tense"`,
                },
                infinitive: {
                  type: "string",
                  description: `The ${currentLanguage.name_eng} infinitive form of the word or phrase "${wordStringDebounced}"`,
                },
                conjugation_first_person_singular: {
                  type: "string",
                  description: `The ${currentLanguage.name_eng} word or phrase "${wordStringDebounced}" conjugated into the first person singular`,
                },
                conjugation_second_person_singular: {
                  type: "string",
                  description: `The ${currentLanguage.name_eng} word or phrase "${wordStringDebounced}" conjugated into the second person singular`,
                },
                conjugation_third_person_singular: {
                  type: "string",
                  description: `The ${currentLanguage.name_eng} word or phrase "${wordStringDebounced}" conjugated into the third person singular`,
                },
                conjugation_first_person_plural: {
                  type: "string",
                  description: `The ${currentLanguage.name_eng} word or phrase "${wordStringDebounced}" conjugated into the first person plural`,
                },
                conjugation_second_person_plural: {
                  type: "string",
                  description: `The ${currentLanguage.name_eng} word or phrase "${wordStringDebounced}" conjugated into the second person plural`,
                },
                conjugation_third_person_plural: {
                  type: "string",
                  description: `The ${currentLanguage.name_eng} word or phrase "${wordStringDebounced}" conjugated into the third person plural`,
                },
              },
              required: [
                "is_verb",
                "tense",
                "infinitive",
                "conjugation_first_person_singular",
                "conjugation_second_person_singular",
                "conjugation_third_person_singular",
                "conjugation_first_person_plural",
                "conjugation_second_person_plural",
                "conjugation_third_person_plural",
              ],
            },
          },
        ]

        const function_call = { name: "generate_conjugations" }

        console.log("Generated ai prompt", {
          messages,
          functions,
          function_call,
        })

        console.log("🏃 triggering edge function ai_prompt")
        const { data, error } = await supabase.functions.invoke("ai_prompt", {
          body: { messages, functions, function_call },
        })
        setLoading(false)
        if (error) throw new Error(error.message)
        setError(null)
        const responseResultString = data?.promptResponse?.function_call?.arguments
        const responseResult = responseResultString && JSON.parse(responseResultString)
        console.log("📙 Got conjugations", responseResult)
        setConjugations(responseResult)
      } catch (error) {
        logError("conjugate this word or phrase", error)
        setError(error)
        setConjugations(null)
      }
    }
    triggerRemoteFunction()
  }, [wordStringDebounced])

  if (loading)
    return (
      <>
        <Typing />
      </>
    )
  if (error) return <ErrorComponent error={error} />

  return (
    <div style={{ whiteSpace: "break-spaces" }}>
      {!conjugations.is_verb && `${wordStringDebounced} doesn't seem to have any verbs`}
      {conjugations && (
        <>
          <p style={{ fontSize: "var(--s)" }}>
            {conjugations.tense} for "
            <Definable wordString={conjugations.infinitive} />"
          </p>
          <table>
            <tbody>
              <tr>
                <td>{contentStrings?.pronouns?.first_person_singular || "I"}</td>
                <td>
                  <Definable wordString={conjugations.conjugation_first_person_singular} />
                  <SpeakWord wordString={conjugations.conjugation_first_person_singular} />
                </td>
              </tr>
              <tr>
                <td>{contentStrings?.pronouns?.second_person_singular || "You"}</td>
                <td>
                  <Definable wordString={conjugations.conjugation_second_person_singular} />
                  <SpeakWord wordString={conjugations.conjugation_second_person_singular} />
                </td>
              </tr>
              <tr>
                <td>{contentStrings?.pronouns?.third_person_singular || "He/She/It"}</td>
                <td>
                  <Definable wordString={conjugations.conjugation_third_person_singular} />
                  <SpeakWord wordString={conjugations.conjugation_third_person_singular} />
                </td>
              </tr>
              <tr>
                <td>{contentStrings?.pronouns?.first_person_plural || "We"}</td>
                <td>
                  <Definable wordString={conjugations.conjugation_first_person_plural} />
                  <SpeakWord wordString={conjugations.conjugation_first_person_plural} />
                </td>
              </tr>
              <tr>
                <td>{contentStrings?.pronouns?.second_person_plural || "You"}</td>
                <td>
                  <Definable wordString={conjugations.conjugation_second_person_plural} />
                  <SpeakWord wordString={conjugations.conjugation_second_person_plural} />
                </td>
              </tr>
              <tr>
                <td>{contentStrings?.pronouns?.third_person_plural || "They"}</td>
                <td>
                  <Definable wordString={conjugations.conjugation_third_person_plural} />
                  <SpeakWord wordString={conjugations.conjugation_third_person_plural} />
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  )
}
