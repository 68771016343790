import { createContext, useContext, useEffect, useState } from "react"

import { supabase, useSupabaseQuery, useSupabaseRealtimeQuery } from "@/db/supabase"
import { logError } from "@/utils/error.js"
import { setTrackingUser } from "@/vendor/sentry"

const Context = createContext({
  userId: null,
  userAuth: null,
  userLoading: true,
  userProfile: null,
  signInWithOtp: null,
  signInWithGoogle: null,
  signUpWithEmailPassword_DO_NOT_USE: null,
  logout: null,
  isAdmin: false,
  isAlphaTester: false,
})

export default ({ children }) => {
  const [userAuth, setUserAuth] = useState(null)
  const [userAuthError, setUserAuthError] = useState(null)
  const [userAuthLoading, setUserAuthLoading] = useState(true)

  const userProfileQuery = supabase.from("user_profiles").select().eq("id", userAuth?.id).single()
  const [userProfile, userProfileLoading, userProfileError] = useSupabaseRealtimeQuery(
    userProfileQuery,
    [userAuth?.id],
    {},
    {
      event: "*",
      schema: "public",
      table: "user_profiles",
      filter: `id=eq.${userAuth?.id}`,
    }
  )

  const roleQuery = supabase.from("user_roles").select().eq("role_user", userAuth?.id)
  const [roles, roleLoading, roleError] = useSupabaseQuery(roleQuery, [userAuth?.id])
  const role = roles?.[0]

  const getUserAuth = async () => {
    setUserAuthLoading(true)
    const { data, error } = await supabase.auth.getSession()
    const user = data?.session?.user

    if (user) {
      setTrackingUser({ email: user.email })
    }
    console.log(
      !user
        ? "👤 No user"
        : `👤 User: ${user.email || "Anonymous"} (${user.identities
            .map(i => i.provider)
            .join(", ")}) - ${user.id}`
    )

    setUserAuth(user)
    setUserAuthError(error)
    setUserAuthLoading(false)
  }

  useEffect(() => {
    getUserAuth()
    supabase.auth.onAuthStateChange(getUserAuth)
    // TODO - return function that unsubscribes
  }, [])

  useEffect(() => {
    const ensureProfilePicIsSet = async () => {
      const googleAvatarUrl = userAuth?.user_metadata?.avatar_url
      if (userProfile && !userProfile.avatar_url && googleAvatarUrl) {
        try {
          const { error } = await supabase
            .from("user_profiles")
            .upsert({
              id: userAuth.id,
              avatar_url: googleAvatarUrl,
            })
            .eq("id", userAuth.id)
          if (error) throw error
        } catch (error) {
          logError("update user avatar", error)
        }
      }
    }
    ensureProfilePicIsSet()
  }, [userProfile, userAuth])

  const signInWithOtp = async email => {
    try {
      const { error } = await supabase.auth.signInWithOtp({
        email,
        options: {
          emailRedirectTo: window.location.href,
        },
      })
      if (error) throw error
      alert("Check your email for the login link!")
    } catch (error) {
      logError("log in with OTP", error, true)
    }
  }

  async function signInWithGoogle() {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo: window.location.href,
      },
    })
    if (error) {
      logError("log in with Google", error)
    }
  }

  // This function is only for letting our tests create test accounts
  // and should never be exposed to users!
  async function signUpWithEmailPassword_DO_NOT_USE(email, password) {
    const { error } = await supabase.auth.signUp({
      email,
      password,
    })
    if (error) {
      logError("log in with email and password", error)
    }
  }

  const logout = async () => {
    await supabase.auth.signOut()
    setUserAuth(null)
  }

  const exposed = {
    userLoading: userAuthLoading || (userAuth && userProfileLoading) || (userAuth && roleLoading),
    userId: userAuth?.id,
    userAuth,

    // profile
    // don't set a default avatar_url if the user isn't logged in
    // OR doesn't have a profile already
    // because that will mean userProfile is truthy lower in the tree
    userProfile: userAuth &&
      userProfile && {
        avatar_url:
          userProfile?.avatar_url || "/images/conversation_avatars/noun-profile-1194176.svg",
        ...userProfile,
      },

    // log in/out
    signInWithOtp,
    signInWithGoogle,
    signUpWithEmailPassword_DO_NOT_USE,
    logout,

    // roles
    isAdmin: role?.role_type === "admin",
    isAlphaTester: userProfile?.is_alpha_tester || role?.role_type === "admin",
  }

  return <Context.Provider value={exposed}>{children}</Context.Provider>
}

export const useUser = () => useContext(Context)
