import { useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router-dom"

import { MainWrapper, OneCol } from "@/styles/Layout"
import { logError } from "@/utils/error"

export default ({ error }) => {
  const navigate = useNavigate()

  useEffect(() => {
    logError("load this page", error, true)
  }, [error])

  return (
    <MainWrapper>
      <Helmet>
        <title>Error | Protolang</title>
      </Helmet>
      <OneCol $cool>
        <h1>Whoops, something went wrong</h1>
        <p>
          It's our fault—sorry about that! We've recorded the error, and we're looking into it soon.
        </p>
        <p>
          Try <button onClick={() => location.reload()}>refreshing this page</button>, or check back
          a little later?
        </p>
        <hr />
        <button className="button" onClick={() => navigate("/")}>
          Back to the homepage
        </button>
      </OneCol>
    </MainWrapper>
  )
}
