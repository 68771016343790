import { Link, Navigate } from "react-router-dom"

import { useUser } from "@/context/user"
import Accordion from "@/styles/Accordion"
import { Badge } from "@/styles/Badge"
import Icon from "@/styles/Icon"
import { TwoColumns } from "@/styles/Layout"
import LoomVideo from "@/views/_components/LoomVideo"
import {
  BenefitBlock,
  CtaWrapper,
  FeatureBlock,
  FeatureBlockIconWrapper,
  FeatureBlocksWrapper,
  HeroWrapper,
  HomeBox,
  LandingPageWrapper,
  StepBlock,
  StepBlockWrapper,
} from "@/styles/Marketing"
import ArrowCallout from "@/views/_components/ArrowCallout"
import JoinWaitlist from "@/views/account/JoinWaitlist"
import LanguagePickerMini from "@/views/languages/LanguagePickerMini"

export default () => {
  const { userLoading, userAuth, isAlphaTester } = useUser()

  if (userLoading) return <div>loading...</div>

  const shouldRedirectToLanguagePage = !userLoading && (!userAuth || !isAlphaTester)
  if (shouldRedirectToLanguagePage) {
    console.log(
      "🏠 user is either not logged in or is not alpha or admin, jumping right to italian page..."
    )
    return <Navigate to="/italian" />
  }

  return (
    <LandingPageWrapper>
      <TwoColumns cols="2fr 1fr">
        <div>
          <HeroWrapper>
            <div>
              {/* <Stamp /> */}
              <div style={{ margin: "0 0 -1.5rem" }}>
                <Badge>COMING SOON</Badge>
              </div>
              <h1 style={{ fontSize: "2rem" }}>
                Protolang is an immersive language app for self-motivated learners
                {/* A powerful new way to learn&nbsp;languages */}
                {/* Free, fast, and&nbsp;easy. */}
              </h1>
              <p style={{ fontSize: "var(--l)", maxWidth: "700px" }}>
                Feel more confident in conversations by{" "}
                <strong>practicing real-world experiences</strong> that match your goals. Choose
                your own learning path, and unlock your next big&nbsp;adventure.
              </p>
              <p style={{ fontSize: "var(--s)", margin: "0 0 1rem" }}>
                Free lessons · Free flashcards · No account required
              </p>
              <Link to="/italian" className="button button-primary">
                Try it with Italian →
              </Link>{" "}
              or{" "}
              <Link to="/languages" className="button">
                Choose another language
              </Link>
              {/* <br />
            <blockquote>"It's like Duolingo for adults" - Joe Flateau</blockquote> */}
            </div>
          </HeroWrapper>

          <hr />

          <div>
            <BenefitBlock>
              <div>
                <h2>Learn things you actually care about</h2>
                <p>
                  Choose your interests and learning style, we'll target experiences just for you.
                  Or explore without limits on your own.
                  {/* wander */}
                </p>
                <p style={{ fontSize: "var(--s)" }}>
                  Access to all lessons · Choose topics · Smart suggestions
                  {/* · Learn at your own pace */}
                  {/* Conjugations · Mnemonics */}
                </p>
              </div>
              <div>{/* <BenefitBlockIllustration src="/images/features/lessons.svg" /> */}</div>
            </BenefitBlock>

            <BenefitBlock>
              <div>
                <h2>Surround yourself in your new language</h2>
                <p>
                  Talk like a local—with no awkward moments, and no plane ticket required. Protolang
                  gives you unlimited opportunities to listen, speak, and practice, just like you
                  would in another country.
                </p>
                {/* <p>Realistic simulated conversations powered by our cutting-edge AI. Unlimited audio and video content. Everything you need to hear, speak, listen, and practice as much as you need without getting bored.</p> */}
                <p style={{ fontSize: "var(--s)" }}>
                  Pronunciation
                  {" · "}
                  AI conversations
                  {" · "}
                  Videos
                  {" · "}
                  Music
                  {" · "}
                  Podcasts
                  {" · "}
                  News
                </p>
              </div>
              <div>
                {/* <BenefitBlockIllustration src="/images/screenshots/scenarios.png" /> */}
              </div>
            </BenefitBlock>

            <BenefitBlock>
              <div>
                <h2>Take control of your own learning path</h2>
                <p>
                  Learn any lesson, anytime. There's no rigid curriculum in Protolang. Follow our
                  suggestions, or explore as much as you want on your own.
                </p>
                <p style={{ fontSize: "var(--s)" }}>
                  No locked lessons
                  {" · "}
                  Custom decks
                  {" · "}
                  No log-in required
                </p>
              </div>
              <div>
                {/* <BenefitBlockIllustration src="/images/screenshots/scenarios.png" /> */}
              </div>
            </BenefitBlock>

            {/* <ScreenshotImage src="/images/screenshots/dashboard_screenshot.png" /> */}
          </div>

          <hr />

          {/* <p>The complete toolkit for second-language acquisition</p> */}

          <h2>How it works</h2>

          <StepBlockWrapper>
            {/* <StepBlock>
            <h3>Choose a language</h3>
            <p>While we're in beta, we only support English → Italian. Sign up to get notified when we launch your preferred language.</p>
          </StepBlock> */}
            <StepBlock>
              <h3>Choose your skill level</h3>
              <p>
                Everything adapts to your level, so you're always challenged—but never overwhelmed.
              </p>
            </StepBlock>
            <StepBlock>
              <h3>Pick topics</h3>
              <p>Only learn about the things you care about. You choose what you want to learn.</p>
            </StepBlock>
            <StepBlock>
              <h3>Start with the basics</h3>
              <p>
                Short, interactive lessons with practical phrases that get you up and running fast.
              </p>
            </StepBlock>
            <StepBlock>
              <h3>Practice what you've learned</h3>
              <p>Memorize and reenforce lesson content with flashcards and mnemonics.</p>
            </StepBlock>
            <StepBlock>
              <h3>Test your skills</h3>
              <p>
                When you're ready, practice realistic scenarios by chatting with our cutting-edge
                AI.
              </p>
            </StepBlock>
            <StepBlock>
              <h3>Keep it going</h3>
              <p>
                Add more vocabulary, learn more grammar, and explore deep-dives into local history
                and culture.
              </p>
            </StepBlock>
            <StepBlock>
              <h3>Go abroad with confidence</h3>
              <p>When the time comes, blend in like a local.</p>
            </StepBlock>
          </StepBlockWrapper>

          <hr />

          <FeatureBlocksWrapper>
            <FeatureBlock>
              <FeatureBlockIconWrapper>
                <Icon name="bookmark_add" size="large" />
              </FeatureBlockIconWrapper>
              <div>
                <h3>Custom decks</h3>
                <p>Save any word or phrase to practice later</p>
              </div>
            </FeatureBlock>

            <FeatureBlock>
              <FeatureBlockIconWrapper>
                <Icon name="volume_up" size="large" />
              </FeatureBlockIconWrapper>
              <div>
                <h3>Pronunciation</h3>
                <p>Listen to how words should really sound</p>
              </div>
            </FeatureBlock>

            <FeatureBlock>
              <FeatureBlockIconWrapper>
                <Icon name="swap_horiz" size="large" />
              </FeatureBlockIconWrapper>
              <div>
                <h3>Conjugations</h3>
                <p>Instant conjugations for any verb</p>
              </div>
            </FeatureBlock>

            <FeatureBlock>
              <FeatureBlockIconWrapper>
                <Icon name="search" size="large" />
              </FeatureBlockIconWrapper>
              <div>
                <h3>Definitions</h3>
                <p>Look up words without loosing your place</p>
              </div>
            </FeatureBlock>

            <FeatureBlock>
              <FeatureBlockIconWrapper>
                <Icon name="auto_awesome" size="large" />
              </FeatureBlockIconWrapper>
              <div>
                <h3>Grammar correction</h3>
                <p>AI helps you when you make a mistake</p>
              </div>
            </FeatureBlock>

            {/* <FeatureBlock>
            <FeatureBlockIconWrapper>
              <Icon name="smart_display" size="large" />
            </FeatureBlockIconWrapper>
            <div>
              <h3>Videos</h3>
              <p>YouTube with subtitles in your target language</p>
            </div>
          </FeatureBlock>

          <FeatureBlock>
            <FeatureBlockIconWrapper>
              <Icon name="radio" size="large" />
            </FeatureBlockIconWrapper>
            <div>
              <h3>Radio</h3>
              <p>Real native speakers, live 24/7</p>
            </div>
          </FeatureBlock> */}

            <FeatureBlock>
              <FeatureBlockIconWrapper>
                <Icon name="emoji_objects" size="large" />
              </FeatureBlockIconWrapper>
              <div>
                <h3>Mnemonics</h3>
                <p>Easy memorization hints to retain vocabulary</p>
              </div>
            </FeatureBlock>

            {/* <FeatureBlock>
            <FeatureBlockIconWrapper>
              <Icon name="shapes" size="large" />
            </FeatureBlockIconWrapper>
            <div>
              <h3>Cognates</h3>
              <p>Learn using the language you already know</p>
            </div>
          </FeatureBlock> */}

            <FeatureBlock>
              <FeatureBlockIconWrapper>
                <Icon name="shapes" size="large" />
              </FeatureBlockIconWrapper>
              <div>
                <h3>Examples</h3>
                <p>See any word or phrase used in a sentence</p>
              </div>
            </FeatureBlock>

            <FeatureBlock>
              <FeatureBlockIconWrapper>
                <Icon name="history" size="large" />
              </FeatureBlockIconWrapper>
              <div>
                <h3>History</h3>
                <p>See old guesses and track your progress</p>
              </div>
            </FeatureBlock>
            <FeatureBlock>
              <FeatureBlockIconWrapper>
                <Icon name="insights" size="large" />
              </FeatureBlockIconWrapper>
              <div>
                <h3>Analytics</h3>
                <p>Learn your strengths and weak spots</p>
              </div>
            </FeatureBlock>
          </FeatureBlocksWrapper>

          {/* <h2>See it in action</h2> */}

          <ArrowCallout text="See it in action" />
          <LoomVideo id="ae393b1448524717bce13e1e13fa653c" />

          {/* <Link to="/help">Full video tour →</Link> */}

          <br />

          <HomeBox>
            <h2>Educators: build your own interactive lessons</h2>
            <p>
              Are you a language teacher or content creator? Our platform lets you create AI-powered
              courses that fit your teaching style. Learn more about becoming an early partner.
            </p>
            <Link className="button" to="/contribute">
              How to apply →
            </Link>
          </HomeBox>

          {/* <h2>Blog</h2>
        <br />
        <BlogLink to={`/blog/why-online-language-learning-is-broken`}>Why online language learning is broken →</BlogLink>
        <Link to={`/blog`}>Read more</Link>
        <br />
        <br /> */}

          {/* <BenefitBlock>
          <div>
            <h2>Learn faster with our cutting-edge tech</h2>
            <p>
              Our advanced A.I. listens, speaks, and generates new content on the fly, so you'll never run out of things to do.
            </p>
            <p style={{fontSize: 'var(--s)'}}>
              High-accuracy translations · Instant speech recognition · Realistic text-to-speech · Powered by Open AI GPT
            </p>
          </div>
        </BenefitBlock>

        <BenefitBlock>
          <div>
            <h2>Spark new connections with people across the globe</h2>
            <p>
              Spark new experiences, and make friends in faraway places.
              If you love languages, you love finding ways to connect. Protolang gets you talking with other language learners from around the world.
            </p>
          </div>
        </BenefitBlock> */}

          <h2>FAQs</h2>
          <Accordion items={faqs} />

          <p>
            Have a different question? <Link to="/contact">Contact us</Link>
          </p>

          <br />
          <br />

          <div style={{}}>
            <h2 style={{ fontSize: "var(--xl)" }}>Reserve your spot</h2>
            <p>
              Protolang is in private alpha. Reserve your spot now, and we'll let you know when we
              launch.
            </p>
            <JoinWaitlist />
            <br />
          </div>

          {/* <Link to="/blog">Compare to Duolingo and other apps →</Link> */}
        </div>
        <div>
          <CtaWrapper>
            <LanguagePickerMini />
          </CtaWrapper>
        </div>
      </TwoColumns>
    </LandingPageWrapper>
  )
}

const faqs = [
  {
    title: "Will you add my favorite language?",
    content: (
      <>
        <p>We're working on it!</p>
        <p>
          If you (or someone you know) speaks a language besides English and you want to help build
          a course, <a href="/contributing">learn how to build your own lessons</a>.
        </p>
        <p>
          In the meantime, you can vote for your favorite course on the{" "}
          <Link to="/languages">languages page</Link>.
        </p>
      </>
    ),
  },
  {
    title: "Do you have a mobile app?",
    content: (
      <>
        <p>The website works well on phones, but we don't have a native app yet.</p>
        <p>
          Our top priority is making it easy to learn on a large screen where you can reference
          words as you work.
        </p>
        <p>
          Eventually we do plan to build a mobile app. <Link to="/signup">Create an account</Link>{" "}
          and we'll tell you when it's released.
        </p>
      </>
    ),
  },
  {
    title: "How much does it cost?",
    content: (
      <>
        <p>Protolang lessons and flashcards are free.</p>
        <p>
          We are also working on some premium features that cost us money to run, so they will
          require a paid account.
          {/* <a href="/open-content">Open license contributions</a> will always
          be free. */}
        </p>
      </>
    ),
  },
  {
    title: "What is open content?",
    content: (
      <>
        <p>
          Unlike Duolingo (and every other major learning app), many lessons in Protolang are freely
          sharable under permissive{" "}
          <a href="https://en.wikipedia.org/wiki/Creative-Commons">
            Creative Commons open content licenses
          </a>
          .
        </p>
        <p>
          That means you can download, print, and share these lessons for free (as long as you don't
          sell them for commercial profit).
        </p>
        {/* <p>
        Learn more about the <a href="/open-content">open content licenses we use</a>.
      </p> */}
      </>
    ),
  },
  {
    title: "Can I write my own content?",
    content: (
      <>
        <p>Yes—we are actively looking for educators who want to build custom lessons.</p>
        <p>
          Read our <a href="/contributing">contributor page</a> to learn how to apply, or{" "}
          <Link to="/signup">create an account</Link> and we'll let you know when contributions are
          public.
        </p>
      </>
    ),
  },
  {
    title: "Why is the website black and white?",
    content: (
      <>
        <p>
          We're focusing on making Protolang useful before we make it pretty.
          Stay&nbsp;tuned&nbsp;:)
        </p>
      </>
    ),
  },
]
