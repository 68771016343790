import { Link } from "react-router-dom"
import styled from "styled-components/macro"

import { useLanguage } from "@/context/language"
import useSaveLesson from "@/hooks/useSaveLesson"
import { Badge } from "@/styles/Badge"
import Icon from "@/styles/Icon"
import { ProgressBar, ProgressBarWrapper } from "@/styles/ProgressBar"

export default ({ lesson, lessonProgressData }) => {
  const { currentLanguage, currentUserLanguage, preferContentInL2 } = useLanguage()
  // const navigate = useNavigate()
  const userTopics = currentUserLanguage?.goal_topics || []

  const [isSaved, isLoading, addSaved, removeSaved] = useSaveLesson(lesson?.id)

  const { completed_phrases } = lessonProgressData?.find(l => l.lesson_id === lesson.id) || {
    completed_phrases: 0,
  }
  const total_phrases = lesson.num_phrases || 20
  const percentComplete = Math.round((completed_phrases / total_phrases) * 100)
  const phrasePreviews = [...new Set(lesson.phrase_slugs_l2 || [])].slice(0, 3).map(slug => {
    return slug.replaceAll("*", "").replaceAll("_", "")
  })
  const keyPhrases = lesson.key_phrases_l2 || phrasePreviews?.join(", ").substring(0, 40)

  return (
    <LessonListItemWrapper to={`/${currentLanguage.slug_eng}/lessons/${lesson.random_slug}`}>
      <LessonListItemMainContent>
        <div style={{ opacity: percentComplete === 100 ? ".5" : "1" }}>
          <div style={{ fontWeight: "600" }}>
            {preferContentInL2 && lesson.title_l2 ? lesson.title_l2 : lesson.title_l1 || "Unknown"}
          </div>
          <div style={{ fontSize: "var(--s)" }}>
            {/* {percentComplete === 100 ? <span style={{opacity: '.5'}}>Completed</span> : percentComplete === 0 ? `` : `${percentComplete}% complete`} */}
            {lesson.title_l2}
          </div>
        </div>
        <div style={{ fontSize: "var(--s)" }}>
          {lesson?.topics?.map(topic => (
            <Badge key={topic}>{topic}</Badge>
          ))}
        </div>
        <div style={{ fontSize: "var(--s)" }}>
          {keyPhrases}
          {/* {userTopics.find(topic => lesson?.topics?.includes(topic)) && <Badge>Recommended</Badge>} */}
        </div>
      </LessonListItemMainContent>
      <div style={{ paddingTop: ".5rem" }}>
        <ProgressBarWrapper>
          <ProgressBar progress={completed_phrases / total_phrases} />
        </ProgressBarWrapper>
      </div>
      <div>
        <button
          className="button button-tertiary button-small"
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            if (isLoading) return
            if (isSaved) {
              removeSaved()
            } else {
              addSaved()
            }
          }}
        >
          <Icon name={isLoading ? "pending" : "add_circle"} filled={isSaved} />
        </button>
      </div>
    </LessonListItemWrapper>
  )
}

const LessonListItemWrapper = styled(Link)`
  display: grid;
  grid-template-columns: auto 30px max-content;
  gap: 1rem;
  /* justify-content: space-between; */
  /* align-items: center; */
  padding: ${p => (p.$block ? "1rem" : ".5rem 1rem")};
  /* box-shadow: ${p => p.$block && "2px 2px var(--shadow)"}; */
  text-decoration: none;
  border: 1px solid;
  margin: -1px 0 0 0;
`
const LessonListItemMainContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  @media (max-width: 600px) {
    grid-template-columns: auto;
    gap: 0.5rem;
  }
`
