// import { Link as RouterLink } from 'react-router-dom'
import styled from "styled-components/macro"

import Icon from "@/styles/Icon"
import Tooltip from "@/styles/Tooltip"

export default ({ helpText = "" }) => {
  return (
    <Tooltip label={helpText}>
      <HelpButton>
        <Icon name="help" size="small" />
      </HelpButton>
    </Tooltip>
  )
}

export const HelpButton = styled.div`
  display: inline-block;
  cursor: pointer;
  padding: 0.25rem;
  opacity: 0.75;

  /* &:hover {
    opacity: 1;
  } */
`
