export default [
  {
    id: null,
    name: 'Not licensed',
  },
  {
    id: 'CC BY 4.0',
    name: 'Creative Commons Attribution 4.0 International',
    url: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    id: 'CC BY-NC 4.0',
    name: 'Creative Commons Attribution-NonCommercial 4.0 International',
    url: 'https://creativecommons.org/licenses/by-nc/4.0/',
  },
  {
    id: 'CC BY-SA 4.0',
    name: 'Creative Commons Attribution-ShareAlike 4.0 International',
    url: 'https://creativecommons.org/licenses/by-sa/4.0/',
  },
  {
    id: 'CC BY-NC-SA 4.0',
    name: 'Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International',
    url: 'https://creativecommons.org/licenses/by-nc-sa/4.0/',
  },
]
