const ID_LENGTH = 11

export const getLabelAndIdFromUnsplashFileName = (fileName) => {
  if (!fileName || !fileName.length || typeof fileName !== 'string') {
    throw new Error('Invalid file name'+JSON.stringify(fileName))
  }

  const [name, _] = fileName.split('-unsplash.')
  const creditNameDashed = name.slice(0, -1 * ID_LENGTH - 1)
  const creditName = creditNameDashed.replace(/-/g, ' ')
  const creditId = name.slice(-1 * ID_LENGTH)
  const creditUrl = `https://unsplash.com/photos/${creditId}`

  return {
    creditName,
    creditUrl,
  }
}
