import { useDebug } from "@/context/debug"
import { useOnboarding } from "@/context/onboarding"
import { useUser } from "@/context/user"

export default () => {
  const { isAlphaTester } = useUser()
  const { isDebugging, toggleDebugMode } = useDebug()
  const { resetUserOnboarding } = useOnboarding()

  if (!isAlphaTester) return null

  return (
    <>
      <hr />
      <div style={{ color: "red" }}>
        <button type="button" onClick={resetUserOnboarding}>
          Reset user onboarding
        </button>
        <br />
        <button type="button" onClick={toggleDebugMode}>
          Debug mode is {isDebugging ? "ON" : "off"}
        </button>
      </div>
    </>
  )
}
