import { Link } from "react-router-dom"

import { useUser } from "@/context/user"
import { MainWrapper, TwoColumns } from "@/styles/Layout"
import LayoutSimple from "@/views/_layout/LayoutSimple"

export default ({ children }) => {
  const { isAlphaTester } = useUser()

  return (
    <LayoutSimple>
      <MainWrapper>
        <TwoColumns cols="200px auto" gap="2">
          <nav>
            <h3>Settings</h3>
            <Link to={`/settings/account`}>Account</Link>
            <br />
            <Link
              to={`/settings/profile`}
              style={{
                opacity: !isAlphaTester && "0",
                pointerEvents: !isAlphaTester ? "none" : "auto",
              }}
            >
              Public profile
            </Link>
            <br />
            <Link
              to={`/settings/billing`}
              style={{
                opacity: !isAlphaTester && "0",
                pointerEvents: !isAlphaTester ? "none" : "auto",
              }}
            >
              Payments and billing
            </Link>
          </nav>
          <main>{children}</main>
        </TwoColumns>
      </MainWrapper>
    </LayoutSimple>
  )
}
