import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"

import { useUser } from "@/context/user"
import { supabase } from "@/db/supabase"
import { AvatarUser } from "@/styles/Avatars"
import { MainWrapper } from "@/styles/Layout"
import { logError } from "@/utils/error.js"
import ReportIssue from "@/views/_components/ReportIssue"
import LessonContent from "@/views/lessons/LessonContent"
import ProfileFollow from "@/views/profile/ProfileFollow"

export default () => {
  const { username } = useParams()
  const { userProfile } = useUser()

  const [pageProfile, setPageProfile] = useState(null)
  const [profileLoading, setLoading] = useState(false)
  const [profileError, setError] = useState(null)

  useEffect(() => {
    const getProfile = async () => {
      try {
        console.log(`🏃 triggering db function get_user_profiles_by_username`)
        setLoading(true)
        const { data, error } = await supabase.rpc("get_user_profiles_by_username", {
          username_param: username,
        })
        if (error) throw error
        setError(null)
        setPageProfile(data?.length ? data[0] : null)
        setLoading(false)
      } catch (error) {
        setError(error)
        setPageProfile(null)
        logError("load user profile data", error)
        setLoading(false)
      }
    }
    getProfile()
  }, [username])

  if (profileLoading) return <div>Loading...</div>
  if (profileError || !pageProfile)
    return (
      <div>
        <h1>Profile not found</h1>
        Error: {profileError?.message || "Unknown error"}
      </div>
    )

  return (
    <MainWrapper>
      <AvatarUser
        $width="100px"
        src={pageProfile.avatar_url || "/images/conversation_avatars/noun-profile-1194176.svg"}
      />
      <h1>{pageProfile.username}</h1>
      {pageProfile.first_name && <p style={{ fontSize: "var(--s)" }}>{pageProfile.first_name}</p>}
      {pageProfile.bio && <LessonContent content={pageProfile.bio} />}
      {pageProfile.username === userProfile?.username && <Link to="/settings/profile">edit</Link>}
      <ProfileFollow userProfile={pageProfile} />
      <br />
      <ReportIssue profile={pageProfile} />
    </MainWrapper>
  )
}
