import { Link } from "react-router-dom"

import { useLanguage } from "@/context/language"
import { useUser } from "@/context/user"
import { supabase, useSupabaseQuery } from "@/db/supabase"
import AdminId from "@/views/_components/AdminId"
import { fromNow } from "@/utils/date"
import { logError } from "@/utils/error.js"
import SupabaseAdminLink from "@/views/admin/SupabaseAdminLink"
import ErrorPage from "@/views/errors/ErrorPage"

export default () => {
  const { currentLanguage } = useLanguage()
  const { userId } = useUser()

  const query = supabase
    .from("issues")
    .select(
      "*, created_by(*), profile_id(*), phrase_id(*), lesson_id(*), media_id(*), mnemonic_id(*), quote_id(*), conversation_message_id(*)"
    )
    .eq("language_id", currentLanguage.id)
  const [issues, loading, error] = useSupabaseQuery(query, [currentLanguage.id])

  const setResolved = async (id, status = true) => {
    try {
      const { error } = await supabase
        .from("issues")
        .update({
          resolved_on: status ? new Date() : null,
          resolved_by: status ? userId : null,
        })
        .eq("id", id)
      if (error) throw error
      location.reload()
    } catch (error) {
      logError("mark issue as resolved", error)
    }
  }

  if (error) return <ErrorPage error={error} />
  if (loading) return <div>loading...</div>
  if (!issues || issues.length <= 0) return <div>no issues</div>

  return (
    <>
      <h1>Issues</h1>
      {issues.length} issue{issues.length === 1 ? "" : "s"}
      <hr />
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Model</th>
            <th>Item</th>
            <th>Type</th>
            <th>Comment</th>
            <th>Reporter</th>
            <th>Created</th>
            <th>Updated</th>
            <th>Status</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {issues.map(issue => {
            return (
              <tr key={issue.id}>
                <td>
                  <AdminId>{issue.id}</AdminId>
                </td>
                <td>
                  {issue.profile_id
                    ? "Profile"
                    : issue.phrase_id
                    ? "Phrase"
                    : issue.lesson_id
                    ? "Lesson"
                    : issue.media_id
                    ? "Media"
                    : issue.mnemonic_id
                    ? "Mnemonic"
                    : issue.quote_id
                    ? "Quote"
                    : issue.conversation_message_id
                    ? "Conversation"
                    : "???"}
                </td>
                <td>
                  {issue.profile_id ? (
                    <Link to={`/u/${issue.profile_id.username}`}>{issue.profile_id.username}</Link>
                  ) : issue.phrase_id ? (
                    <Link
                      to={`/${currentLanguage.slug_eng}/phrase/${issue.phrase_id?.random_slug}`}
                    >
                      {issue.phrase_id?.content_l2}
                    </Link>
                  ) : issue.lesson_id ? (
                    <Link
                      to={`/${currentLanguage.slug_eng}/lessons/${issue.lesson_id?.random_slug}`}
                    >
                      {issue.lesson_id?.title_l1}
                    </Link>
                  ) : issue.media_id ? (
                    <>{issue.media_id?.url}</>
                  ) : issue.mnemonic_id ? (
                    <>{issue.mnemonic_id?.remember_method}</>
                  ) : issue.quote_id ? (
                    <>{issue.quote_id?.quote}</>
                  ) : issue.conversation_message_id ? (
                    <>{issue.conversation_message_id?.content}</>
                  ) : (
                    "❌ no item"
                  )}
                </td>
                <td>
                  <span>{issue.issue_type}</span>
                </td>
                <td>
                  <span>{issue.comment}</span>
                </td>
                <td>
                  {issue.created_by?.username ? (
                    <Link to={`/u/${issue.created_by.username}`}>{issue.created_by.username}</Link>
                  ) : (
                    "❌ no username"
                  )}
                </td>
                <td>{fromNow(issue.created_at)}</td>
                <td>{fromNow(issue.updated_at)}</td>
                <td>{issue.resolved_on ? "✔️" : "Open"}</td>
                <td>
                  {issue.resolved_on ? (
                    <button onClick={() => setResolved(issue.id, false)}>Reopen</button>
                  ) : (
                    <button onClick={() => setResolved(issue.id, true)}>Resolve</button>
                  )}
                </td>
                <td>
                  <SupabaseAdminLink table="issues" id={issue.id} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}
