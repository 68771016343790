import { useUser } from "@/context/user"
import { useParams } from "react-router-dom"

import { supabase, useSupabaseQuery } from "@/db/supabase"
import ConversationItemLoaderNoUser from "@/views/conversations/ConversationItemLoaderNoUser"
import ConversationItemLoaderUser from "@/views/conversations/ConversationItemLoaderUser"
import ConversationLoaderSituationOnly from "@/views/conversations/ConversationLoaderSituationOnly"

export default () => {
  const { userLoading } = useUser()
  const { conversationSlug, situationSlug } = useParams()

  const conversationQuery = supabase
    .from("conversations")
    .select()
    .eq("random_slug", conversationSlug)
    .single()
  const [conversation, conversationLoading] = useSupabaseQuery(conversationQuery, [
    conversationSlug,
  ])

  if (userLoading || (conversationSlug && conversationLoading)) return <div>Loading...</div>

  // We used to test for userAuth here, but that was causing a bug where
  // the user would be logged in but viewing someone else's conversation.
  // For now, we just test whether we can access the conversation...
  // ...if we can, assume the user is viewing their own convo.
  // Unless we have a situation slug, in which case show the situation.
  return situationSlug ? (
    <ConversationLoaderSituationOnly />
  ) : conversation ? (
    <ConversationItemLoaderUser />
  ) : (
    <ConversationItemLoaderNoUser />
  )
}
