import ColorThief from "colorthief"
import { Link } from "react-router-dom"

import { useLanguage } from "@/context/language"
import { supabase, useSupabaseQuery } from "@/db/supabase"
import AdminId from "@/views/_components/AdminId"
import Icon from "@/styles/Icon"
import { fromNow } from "@/utils/date"
import { logError } from "@/utils/error"
import AdminEditCell from "@/views/_components/AdminEditCell"
import SupabaseAdminLink from "@/views/admin/SupabaseAdminLink"
import ErrorPage from "@/views/errors/ErrorPage"

// const MIN_DARKNESS = 60
// const MAX_DARKNESS = 100

export default () => {
  const { currentLanguage } = useLanguage()

  const query = supabase
    .from("conversation_situations")
    .select("*, created_by(*), character_id(*)")
    .eq("language_id", currentLanguage.id)
    .order("id", { ascending: true })
  const [situations, loading, error] = useSupabaseQuery(query, [currentLanguage.id])

  if (error) return <ErrorPage error={error} />
  if (loading) return <div>loading...</div>
  if (!situations || situations.length <= 0) return <div>no situations</div>

  const resetColorForSituation = async (id, imageElement) => {
    try {
      // https://lokeshdhakar.com/projects/color-thief/#getting-started
      const colorThief = new ColorThief()
      const colors = colorThief.getPalette(imageElement)
      const colorsSorted = colors.map(c => ({
        color: c,
        lightness: getColorLightness(c),
      }))
      //   .filter(c => c.lightness > MIN_DARKNESS)
      //   .filter(c => c.lightness < MAX_DARKNESS)
      // .sort( (a, b) => a.lightness - b.lightness )
      if (!colorsSorted || !colorsSorted[0]) throw new Error("no colors found")
      const color = colorsSorted[Math.floor(Math.random() * colorsSorted.length)]
      const [r, g, b] = color.color
      const rgbaColor = `rgba(${r},${g},${b},.9)`
      if (!rgbaColor) throw new Error("could not extract rgba color")
      console.log(
        `%c reset color for situation ${id}: ${rgbaColor}`,
        `background: ${rgbaColor}; color: #fff`
      )

      const { error } = await supabase
        .from("conversation_situations")
        .update({ background_color: rgbaColor })
        .eq("id", id)
      if (error) throw error
      // location.reload()
    } catch (error) {
      logError("get dominant image color", error, true)
    }
  }

  // https://awik.io/determine-color-bright-dark-using-javascript/
  // HSP equation from http://alienryderflex.com/hsp.html
  const getColorLightness = ([r, g, b]) => {
    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))
    return hsp
  }

  return (
    <>
      <h1>Situations</h1>
      {situations.length} situation{situations.length === 1 ? "" : "s"}
      <hr />
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Character</th>
            <th>Level</th>
            <th>Topics</th>
            <th>Image</th>
            <th>Color</th>
            <th>AI prompt</th>
            <th>User summary</th>
            <th>User goal</th>
            <th>Updated</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {situations.map(situation => {
            console.log("situation.background_color", situation.background_color)
            return (
              <tr key={situation.id}>
                <td>
                  <AdminId>{situation.id}</AdminId>
                </td>
                <td>
                  <strong>
                    <AdminEditCell
                      id={situation.id}
                      collection="conversation_situations"
                      attribute="name_eng"
                      type="text"
                      value={situation.name_eng}
                    />
                  </strong>
                </td>
                <td>{situation.character_id.name_default}</td>
                <td>
                  <AdminEditCell
                    id={situation.id}
                    collection="conversation_situations"
                    attribute="proficiency_level"
                    type="number"
                    value={situation.proficiency_level}
                  />
                </td>
                <td>{situation.topics.join(", ")}</td>
                <td>
                  <AdminEditCell
                    id={situation.id}
                    collection="conversation_situations"
                    attribute="background_file"
                    type="text"
                    value={situation.background_file}
                  />
                  <br />
                  <img
                    src={`/images/conversation_backgrounds/${situation.background_file}`}
                    style={{ width: "50px" }}
                    id={`situation-image-${situation.id}`}
                  />
                </td>
                <td>
                  <div
                    style={{
                      width: "2rem",
                      height: "1rem",
                      background: situation.background_color,
                      border: "1px solid",
                    }}
                  />
                  <button
                    onClick={() =>
                      resetColorForSituation(
                        situation.id,
                        document.querySelector(`#situation-image-${situation.id}`)
                      )
                    }
                  >
                    <Icon name="refresh" size="small" inline />
                  </button>
                </td>
                <td>
                  <span style={{ fontSize: "var(--s)" }}>
                    <AdminEditCell
                      id={situation.id}
                      collection="conversation_situations"
                      attribute="ai_prompt"
                      type="text"
                      value={situation.ai_prompt}
                    />
                  </span>
                </td>
                <td>
                  <span style={{ fontSize: "var(--s)" }}>
                    <AdminEditCell
                      id={situation.id}
                      collection="conversation_situations"
                      attribute="user_summary_eng"
                      type="text"
                      value={situation.user_summary_eng}
                    />
                  </span>
                </td>
                <td>
                  <span style={{ fontSize: "var(--s)" }}>
                    <AdminEditCell
                      id={situation.id}
                      collection="conversation_situations"
                      attribute="user_goal_eng"
                      type="text"
                      value={situation.user_goal_eng}
                    />
                  </span>
                </td>
                <td>
                  {situation.created_by?.username ? (
                    <Link to={`/u/${situation.created_by.username}`}>
                      {situation.created_by.username}
                    </Link>
                  ) : (
                    "❌ no username"
                  )}
                </td>
                <td>{fromNow(situation.created_at)}</td>
                <td>{fromNow(situation.updated_at)}</td>
                <td>
                  <SupabaseAdminLink table="conversation_situations" id={situation.id} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}
