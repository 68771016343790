import { createTrackingError } from "@/vendor/sentry"

export const logError = (action: string, error: Error, suppressDialog?: boolean) => {
  console.error(`Error trying to ${action}: ${error.message}`, error)

  createTrackingError({ action, error })

  if (!suppressDialog) {
    alert(
      `Sorry, something went wrong. Try again in a minute? \n\n Could not ${action} - ${error.message}`
    )
  }
}
