import { useState } from "react"
import { useNavigate } from "react-router-dom"

import { useLanguage } from "@/context/language"
import { useUser } from "@/context/user"
import { supabase, useSupabaseQuery } from "@/db/supabase"
import { FormHint, FormQuestion } from "@/styles/Form"
import Icon from "@/styles/Icon"
import { OneCol } from "@/styles/Layout"
import { CheckboxGroup, RadioGroup } from "@/views/_components/RadioCheckbox"
import { logError } from "@/utils/error.js"

const MAX_SUGGESTED_LESSONS = 50
const EVERYONE_CAN_SKIP_BECAUSE_WE_ARE_IN_PRIVATE_ALPHA = true

export default ({ closeModal }: { closeModal?: () => {} }) => {
  const navigate = useNavigate()
  const { userId, userProfile, isAlphaTester } = useUser()
  const { currentLanguage } = useLanguage()

  const [step, setStep] = useState(0)

  const [proficiencyLevel, setProficiencyLevel] = useState(null)
  // const [reasonsForLearning, setReasonsForLearning] = useState([])
  const [seriousness, setSeriousness] = useState(null)
  const [groupSize, setGroupSize] = useState("")
  const [chosenLessonIds, setChosenLessonIds] = useState([])
  const [selectedGoalDate, setSelectedGoalDate] = useState(null)
  const [goalTopics, setGoalTopics] = useState([])
  const [saving, setSaving] = useState(false)

  const topicsQuery = supabase.from("topics").select().eq("user_selectable", true)
  const [topicsList, topicsLoading, topicsError] = useSupabaseQuery(topicsQuery)

  const lessonsQuery = supabase
    .from("lessons")
    .select()
    .eq("language_id", currentLanguage.id)
    .order("sort_order", { ascending: true })
  const [lessons] = useSupabaseQuery(lessonsQuery, [currentLanguage.id])

  const firstLesson = lessons?.[0]
  const relevantLessons = lessons?.filter(lesson =>
    lesson.topics?.some(topic => goalTopics.includes(topic))
  )
  const suggestedLessons =
    relevantLessons?.slice(0, MAX_SUGGESTED_LESSONS) ||
    lessons?.slice(0, MAX_SUGGESTED_LESSONS) ||
    []

  async function addUserLanguage(event) {
    event.preventDefault()
    try {
      setSaving(true)
      if (userId) {
        // TODO - better way to calculate here
        const goalVocab = proficiencyLevel > 3 ? 10 * 1000 : 1000
        const topics = []
        // if (reasonsForLearning?.includes('family')) topics.push('family')
        // if (reasonsForLearning?.includes('work'))   topics.push('work', 'technology')
        // if (reasonsForLearning?.includes('travel')) topics.push('travel', 'food')
        // if (reasonsForLearning?.includes('living')) topics.push('travel', 'food', 'culture')
        // if (reasonsForLearning?.includes('school')) topics.push('technology')
        // if (reasonsForLearning?.includes('online'))  topics.push('technology')

        // remove dupes
        // https://stackoverflow.com/questions/9229645/remove-duplicate-values-from-js-array
        // const goalTopics = topics.filter(function(item, pos, self) {
        //   return self.indexOf(item) === pos
        // })
        const goalTopics = topics

        const data = {
          language_id: currentLanguage.id,
          proficiency_level: proficiencyLevel || 0,
          // reasons_for_learning: reasonsForLearning,
          seriousness: seriousness,
          group_size: groupSize,

          goal_date: selectedGoalDate,
          goal_topics: goalTopics,
          goal_vocab: goalVocab,
        }
        const { error: error1 } = await supabase.from("user_languages").insert([data])
        if (error1) throw error1
      }

      const chosenLessons = chosenLessonIds.map(id => ({
        language_id: currentLanguage.id,
        lesson_id: id,
      }))
      const lessonsToSave = [
        { language_id: currentLanguage.id, lesson_id: firstLesson.id },
        ...chosenLessons,
      ]
      if (proficiencyLevel < 2) {
        // if user is a beginner, add all the basic lessons
        lessonsToSave.push(
          ...lessons
            .filter(l => l.proficiencyLevel < 1)
            .map(lesson => ({
              language_id: currentLanguage.id,
              lesson_id: lesson.id,
            }))
        )
      }

      const { error: error2 } = await supabase.from("saved_lessons").insert(lessonsToSave)
      if (error2) throw error2

      closeModal && closeModal()
      navigate(`/${currentLanguage.slug_eng}`)

      // navigation isn't enough because we're already on that page
      // and user_languages, which we use to decide whether to show onboarding or not,
      // is not real-time updated. So instead we force reload the page.
      // TODO - a better way to do this
      location.reload()
    } catch (error) {
      logError("create user language preferences", error)
    } finally {
      setSaving(false)
    }
  }

  async function skipOnboarding(event) {
    event.preventDefault()
    if (!userId) return
    if (
      !window.confirm(
        `Are you sure you want to skip onboarding? If you haven't used Protolang before, we strongly recommend you take the tour.`
      )
    )
      return
    try {
      setSaving(true)

      const userLangData = {
        language_id: currentLanguage.id,
        proficiency_level: 2,
        goal_topics: [],
      }
      const { error: error1 } = await supabase.from("user_languages").insert([userLangData])
      if (error1) throw error1

      navigate(`/${currentLanguage.slug_eng}`)
      location.reload()
    } catch (error) {
      logError("skip onboarding", error)
    } finally {
      setSaving(false)
    }
  }

  const welcomeWord =
    currentLanguage.iso639_1 === "it" && userProfile?.refer_to_as_gender === "male"
      ? "Benvenuto"
      : currentLanguage.iso639_1 === "it"
      ? "Benvenuta"
      : currentLanguage.iso639_1 === "fr" && userProfile?.refer_to_as_gender === "male"
      ? "Bienvenu"
      : currentLanguage.iso639_1 === "fr"
      ? "Bienvenue"
      : "Welcome"

  // const congratulationsWord = (currentLanguage.iso639_1 === 'it' && userProfile?.refer_to_as_gender === 'male') ? 'Bravo' :
  //   (currentLanguage.iso639_1 === 'it') ? 'Brava' :
  //   (currentLanguage.iso639_1 === 'fr') ? 'Bon travail' :
  //   'Congratulations'

  const topicOptions = !topicsList
    ? []
    : topicsList
        .filter(topic => topic.user_selectable)
        .map(topic => ({
          id: topic.name_slug,
          title: topic.title_eng,
          description: topic.description_eng,
        }))

  return (
    <OneCol $cool>
      <form onSubmit={addUserLanguage}>
        {step === 0 ? (
          <>
            <h1>
              {welcomeWord}! You're about to start learning{" "}
              {currentLanguage.name_eng || "your new language"}!
            </h1>

            <p>You're just a couple minutes away from your first lesson.</p>

            <p>Before you start, let's make a learning plan based on your learning goals.</p>

            <FormQuestion>
              <div>
                <label>Do you already speak some {currentLanguage.name_eng}?</label>
                <RadioGroup
                  value={proficiencyLevel}
                  setValue={setProficiencyLevel}
                  options={[
                    {
                      id: 0,
                      title: "None at all",
                    },
                    {
                      id: 1,
                      title: "I only know a couple words",
                    },
                    {
                      id: 2,
                      title: "I can get by in basic situations",
                    },
                    {
                      id: 4,
                      title: "I'm pretty good, but I want to get better",
                    },
                    {
                      id: 6,
                      title: "I'm fluent or almost fluent, but I want to keep practicing",
                    },
                  ]}
                />
              </div>
              <div>
                {(proficiencyLevel || proficiencyLevel === 0) && (
                  <FormHint>
                    {proficiencyLevel === 0 && (
                      <>
                        Great, we'll start <strong>completely from scratch</strong>.
                      </>
                    )}
                    {proficiencyLevel === 1 && (
                      <>
                        Great, we'll set your skill level to <strong>novice</strong>. You'll skip
                        some basic words, but you can always go back and review.
                      </>
                    )}
                    {proficiencyLevel === 2 && (
                      <>
                        Great, we'll set your skill level to <strong>beginner</strong>. You'll be
                        able to practice the words you already know, then jump to new skills.
                      </>
                    )}
                    {proficiencyLevel === 4 && (
                      <>
                        Great, we'll set your skill level to <strong>intermediate</strong>. You'll
                        skip vocab lessons and jump right into having conversations with our AI.
                      </>
                    )}
                    {proficiencyLevel === 6 && (
                      <>
                        Great, we'll set your skill level to <strong>advanced</strong>. You'll skip
                        vocab lessons and jump right into having conversations with our AI.
                      </>
                    )}{" "}
                    You can always change this later.
                  </FormHint>
                )}
              </div>
            </FormQuestion>

            <FormQuestion>
              <div>
                <label>
                  How serious are you about learning {currentLanguage?.name_eng || "this language"}?
                </label>
                <RadioGroup
                  value={seriousness}
                  setValue={setSeriousness}
                  options={[
                    {
                      id: "not_serious",
                      title: "Just playing around for now",
                    },
                    {
                      id: "serious",
                      title: "I'm pretty serious, I really want to learn",
                    },
                  ]}
                />
              </div>
              <div>
                {seriousness && (
                  <FormHint>
                    {seriousness === "not_serious" && <>OK, we'll go easy on you.</>}
                    {seriousness === "serious" && (
                      <>Excellent. We'll make sure you stay on track.</>
                    )}
                  </FormHint>
                )}
              </div>
            </FormQuestion>

            <FormQuestion>
              <div>
                <label>Do you know anyone else learning {currentLanguage.name_eng}?</label>
                <p className="form-label-helper">I.e. friends or travel companions</p>
                <RadioGroup
                  value={groupSize}
                  setValue={setGroupSize}
                  options={[
                    {
                      id: "solo",
                      title: "Nope, just me for now",
                    },
                    {
                      id: "couple",
                      title: "There's one other person",
                    },
                    {
                      id: "small_group",
                      title: "There are a few of us (3-5 people)",
                    },
                    {
                      id: "large_group",
                      title: `I know a lot of people learning ${currentLanguage.name_eng} (6+)`,
                    },
                  ]}
                />
              </div>
              <div>
                {groupSize && (
                  <FormHint>
                    {groupSize === "solo" && (
                      <>Later, we may help you find some practice partners.</>
                    )}
                    {groupSize === "couple" && (
                      <>Great, we'll help you track each others' progress.</>
                    )}
                    {groupSize === "small_group" && (
                      <>Great, we'll help you track each others' progress.</>
                    )}
                    {groupSize === "large_group" && (
                      <>Great, we'll help you track each others' progress.</>
                    )}
                  </FormHint>
                )}
              </div>
            </FormQuestion>

            {/* <FormQuestion>
        <div>
          <label>Why are you learning {currentLanguage?.name_eng || 'this language'}?</label>
          <p className="form-label-helper">We'll use this to suggest some topics for you in the next step.</p>
          <CheckboxGroup
            values={reasonsForLearning}
            setValues={setReasonsForLearning}
            options={[
              {
                id: "friends",
                title: "Friends",
              },
              {
                id: "family",
                title: "Family",
              },
              {
                id: "work",
                title: "Work",
              },
              {
                id: "travel",
                title: `I'm traveling to, or plan to travel to, somewhere that speaks ${currentLanguage.name_eng}`,
              },
              {
                id: "living",
                title: `I'm living, or plan to live, somewhere that speaks ${currentLanguage.name_eng}`,
              },
              {
                id: "school",
                title: "School",
              },
              {
                id: "civil",
                title: "Immigration and citizenship",
              },
              {
                id: "online",
                title: "Meeting and talking to people online",
              },
              {
                id: "fun",
                title: "Just for fun/just curious",
              },
              {
                id: "other",
                title: "Another reason",
              },
            ]}
          />
        </div>
        <div>
          {reasonsForLearning && reasonsForLearning.length > 0 && <FormHint>
            Excellent. We'll makes sure you have the right lessons to help you reach your goal.
          </FormHint>}
        </div>
      </FormQuestion> */}

            {/* {(reasonsForLearning?.includes('travel') || reasonsForLearning?.includes('living')) && <FormQuestion>
        <div>
          <label>If you're traveling, when do you plan to arrive?</label>
          <input
            type="date"
            onChange={e => setSelectedGoalDate(e.target.value)}
          />
        </div>
        <div>
        </div>
      </FormQuestion>} */}

            <p>Next, tell us what kind of lessons you want to focus on.</p>
            <br />

            <button
              type="button"
              className="button"
              disabled={saving}
              onClick={() => {
                setStep(1)
                window.scrollTo(0, 0)
              }}
            >
              Next: Choose topics
            </button>
          </>
        ) : step === 1 ? (
          <>
            <h1>What do you want to talk about in {currentLanguage.name_eng}?</h1>

            <p>
              In the next step, we'll recommend lessons for you based on your answer. You can always
              change your answers later.
            </p>

            <FormQuestion>
              <div>
                <CheckboxGroup
                  // TODO - supported disabled attr on CheckboxGroup
                  // disabled={topicsLoading || topicsError}
                  groupName="topics"
                  values={goalTopics}
                  setValues={setGoalTopics}
                  options={topicOptions}
                />
              </div>
            </FormQuestion>

            <br />
            <p>Next, let's pick some lessons to start with.</p>
            <br />

            <button
              type="button"
              className="button"
              disabled={saving}
              onClick={() => {
                setStep(2)
                window.scrollTo(0, 0)
              }}
            >
              Next: Choose your first lessons
            </button>
          </>
        ) : step === 2 ? (
          <>
            <h1>Pick the content that matters to you</h1>

            <p>These will go into your custom lesson plan.</p>

            <p>
              When you add a lesson to your queue,{" "}
              <strong>
                Protolang will automatically start showing you content from that lesson
              </strong>{" "}
              when you practice.
            </p>

            {/* TODO - show lessons grouped by topic and skill level so it's obvious why we picked them */}

            {/* <hr /> */}
            {!suggestedLessons?.length && (
              <div
                style={{
                  padding: "1rem",
                  border: "1px solid",
                  margin: "0 0 1rem",
                }}
              >
                <p>Whoops, no lessons. Maybe you didn't pick any topics?</p>
                <button
                  type="button"
                  className="button"
                  disabled={saving}
                  onClick={() => {
                    setStep(1)
                    window.scrollTo(0, 0)
                  }}
                >
                  Go back
                </button>
              </div>
            )}
            <div style={{ display: "flex", flexWrap: "wrap", gap: ".5rem" }}>
              {suggestedLessons?.map(lesson => {
                const isChosen = chosenLessonIds.includes(lesson.id)
                return (
                  <button
                    key={lesson.id}
                    className="button button-tertiary"
                    style={{ border: "1px solid" }}
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      if (isChosen) {
                        setChosenLessonIds(chosenLessonIds.filter(id => id !== lesson.id))
                      } else {
                        setChosenLessonIds([...chosenLessonIds, lesson.id])
                      }
                    }}
                  >
                    {lesson.title_l1}
                    <Icon name="add_circle" filled={isChosen} />
                  </button>
                )
              })}
            </div>
            {/* <hr /> */}

            <br />
            <p>All done? You're ready to start learning {currentLanguage.name_eng}!</p>

            {/*
        key prop is necessary apparently to prevent form from submitting on render
        https://stackoverflow.com/a/73726156/1061063
      */}
            <button
              className="button"
              type="submit"
              disabled={saving || !chosenLessonIds.length || chosenLessonIds.length < 3}
              key="submit"
            >
              {saving ? "Here we go..." : `Andiamo! Let's go!`}
            </button>
            {chosenLessonIds?.length < 3 && (
              <p style={{ fontStyle: "italic" }}>Chose at least three topics to get started</p>
            )}
          </>
        ) : (
          "Sorry, something went wrong. Refresh and try again?"
        )}
      </form>

      {(isAlphaTester || EVERYONE_CAN_SKIP_BECAUSE_WE_ARE_IN_PRIVATE_ALPHA) && (
        <>
          <br />
          <hr />
          <button type="button" style={{ fontSize: "var(--s)" }} onClick={skipOnboarding}>
            Skip all onboarding
          </button>
        </>
      )}
    </OneCol>
  )
}
