import { useEffect, useState } from "react"

import { useLanguage } from "@/context/language"
import { useUser } from "@/context/user"
import { supabase, useSupabaseQuery } from "@/db/supabase"
import { logError } from "@/utils/error.js"

export default lessonId => {
  const { currentLanguage } = useLanguage()
  const { userId } = useUser()

  const [saving, setSaving] = useState(false)
  const [isSaved, setIsSaved] = useState(false)

  const savedLessonsQuery = supabase
    .from("saved_lessons")
    .select("*, lesson_id(*)")
    .eq("created_by", userId)
    .eq("language_id", currentLanguage.id)
    .eq("lesson_id", lessonId)
  const [matchingSavedLessons, loading, error] = useSupabaseQuery(savedLessonsQuery, [
    lessonId,
    userId,
  ])

  const savedLesson = matchingSavedLessons?.length > 0 && matchingSavedLessons[0]

  useEffect(() => {
    if (savedLesson) {
      setIsSaved(true)
    }
  }, [savedLesson])

  async function addSaved() {
    if (!userId) {
      alert("You must be logged in to save a lesson")
      return
    }

    try {
      setSaving(true)
      const { error } = await supabase.from("saved_lessons").insert([
        {
          language_id: currentLanguage.id,
          lesson_id: lessonId,
        },
      ])
      if (error) throw error
      setSaving(false)
      setIsSaved(true)
    } catch (error) {
      logError("save lesson", error)
    }
  }

  async function removeSaved() {
    if (!userId) return null
    if (!savedLesson) return null

    try {
      setSaving(true)
      const { error } = await supabase.from("saved_lessons").delete().eq("id", savedLesson.id)
      if (error) throw error
      setSaving(false)
      setIsSaved(false)
    } catch (error) {
      logError("remove saved lesson", error)
    }
  }

  const isLoading = saving || loading
  return [isSaved, isLoading, addSaved, removeSaved]
}
