import styled from "styled-components/macro"

export default ({ text }) => {
  return (
    <ArrowCalloutWrapper>
      <ArrowCalloutText>{text}</ArrowCalloutText>
    </ArrowCalloutWrapper>
  )
}

const ArrowCalloutWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: flex-end;
  height: 0;
`
const ArrowCalloutText = styled.div`
  font-size: var(--s);
  font-weight: 500;
  user-select: none;
  text-align: center;
  transform: rotate(10deg) translate(10%, -100%);
  position: relative;

  &::after {
    content: "↓";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 80%);
  }
`
