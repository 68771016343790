import { Helmet } from "react-helmet-async"

import { MainWrapper, OneCol } from "@/styles/Layout"

export default () => {
  return (
    <MainWrapper>
      <OneCol>
        <Helmet>
          <title>DMCA | Protolang</title>
        </Helmet>
        <h1>DMCA</h1>
        <p>Contact sam@directedworks.com</p>
      </OneCol>
    </MainWrapper>
  )
}
