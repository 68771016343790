import { useSpeechSynthesis } from "@/context/speechSynthesis"
import { useEffect, useId } from "react"

import Icon from "@/styles/Icon"
import Tooltip from "@/styles/Tooltip"

interface SpeakWordParams {
  wordString: string
  disabled?: boolean
  altVoice?: boolean
  autoPlay?: boolean
  children?: React.ReactNode
  rate?: number
  gender?: "male" | "female"
  age?: number
}

export default ({
  wordString,
  disabled: disabledParam,
  altVoice,
  autoPlay,
  children,
  rate,
  gender,
  age,
}: SpeakWordParams) => {
  const {
    speechSynthesisIsSupported,
    speechSynthesisReady,
    speak,
    isSpeaking,
    stopSpeech,
    currentUtteranceId,
    upcomingUtteranceIds,
  } = useSpeechSynthesis()

  const speakId = useId()

  const disabled =
    !speechSynthesisIsSupported || !speechSynthesisReady || disabledParam || !wordString?.length

  useEffect(() => {
    if (autoPlay && !isSpeaking) {
      console.log("🗣️ autoPlay triggering speech", wordString)
      speakWord()
    }
  }, [autoPlay, wordString])

  const speakWord = () => {
    if (disabled) return
    speak(speakId, wordString || "", {
      alt: !!altVoice,
      rate: rate || 0.7,
      gender,
      age,
    })
  }

  const isSpeakingLocal = currentUtteranceId === speakId
  const isUpcoming = upcomingUtteranceIds.includes(speakId)

  return (
    <Tooltip
      label={
        !speechSynthesisIsSupported
          ? "Speech is not supported in your browser"
          : !speechSynthesisReady
          ? "Loading..."
          : isSpeakingLocal
          ? "Click to stop"
          : "Click to listen"
      }
    >
      <button
        type="button"
        className="button button-small button-tertiary"
        onClick={event => {
          event.stopPropagation()
          isSpeakingLocal ? stopSpeech() : speakWord()
        }}
        disabled={disabled}
        style={{
          color: isSpeakingLocal ? "red" : isUpcoming ? "gray" : "var(--text)",
        }}
      >
        {children || (
          <Icon name={isSpeakingLocal ? "stop_circle" : isUpcoming ? "pending" : "play_circle"} />
        )}
      </button>
    </Tooltip>
  )
}
