// https://stackoverflow.com/q/73722206/1061063
export const fromNow = function (dateString: string) {
  if (!dateString?.length) return ""
  const now = Date.now()
  const timestamp = new Date(dateString).getTime()

  const diff = timestamp - now
  const days = diff / (1000 * 60 * 60 * 24)
  const hours = (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  const minutes = (diff % (1000 * 60 * 60)) / (1000 * 60)
  const seconds = (diff % (1000 * 60)) / 1000
  const relative = new Intl.RelativeTimeFormat()

  if (diff > 0) {
    // If the difference is positive, the timestamp is in the future
    if (days >= 1) {
      return `${relative.format(Math.round(days), "day")}`
    }
    if (hours >= 1) {
      return relative.format(Math.round(hours), "hour")
    }
    if (minutes >= 1) {
      return relative.format(Math.round(minutes), "minute")
    }
    if (seconds >= 0) {
      return relative.format(Math.round(seconds), "second")
    }
  }
  if (diff < 0) {
    // If the difference is negative, the timestamp is in the past
    if (Math.abs(days) >= 1) {
      return relative.format(Math.round(days), "day")
    }
    if (Math.abs(hours) >= 1) {
      return relative.format(Math.round(hours), "hour")
    }
    if (Math.abs(minutes) >= 1) {
      return relative.format(Math.round(minutes), "minute")
    }
    if (Math.abs(seconds) >= 0) {
      return relative.format(Math.round(seconds), "second")
    }
  }
  return ""
}

// https://github.com/you-dont-need/You-Dont-Need-Momentjs
export const formatDate = (dateString: string) => {
  if (!dateString?.length) return ""
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(new Date(dateString))
}

export const diffWeeks = (date1: Date, date2: Date) => {
  const diffTime = Math.abs(date2.getTime() - date1.getTime())
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  return Math.floor(diffDays / 7)
}

// https://stackoverflow.com/a/50398144/1061063
export const getDaysArray = function (start, end) {
  for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt))
  }
  return arr
}

export const getStartOfDayForQuery = () => {
  const yourDate = new Date()
  const offset = yourDate.getTimezoneOffset()
  const offsetDate = new Date(yourDate.getTime() - offset * 60 * 1000)
  offsetDate.setHours(0, 0, 0, 0)
  return offsetDate.toISOString().split("T")[0]
}
