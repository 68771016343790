import { useEffect, useId, useState } from "react"
import styled from "styled-components/macro"
// import { Link } from 'react-router-dom'

import { useLanguage } from "@/context/language"
import { useSoundFx } from "@/context/soundEffects"
import { useSpeechRecognition } from "@/context/speechRecognition"
import { useSpeechSynthesis } from "@/context/speechSynthesis"
import { useUser } from "@/context/user"
import { supabase } from "@/db/supabase"
import { PhraseAttempts, Phrases } from "@/types/schema"
import { logError } from "@/utils/error.js"
import { testAnswerString } from "@/utils/string"
import DiffSentences from "@/views/_components/DiffSentences"
import Card from "@/views/practice/Card"
import CardAnswerSpeech from "@/views/practice/card_answer_types/CardAnswerSpeech"
import CardAnswerText from "@/views/practice/card_answer_types/CardAnswerText"

const MAX_TRANSLATION_STRIKES = 1
const MAX_PRONUNCIATION_STRIKES = 3

export default ({
  practiceType = "translation",
  cardQuestionType = "both",
  cardAnswerType = "speech",
  direction = "forward",
  phrases,
  phraseSource = "custom",
  setPhraseToShowInfoAbout = (phrase: Phrases) => {},
  setCardsRemaining = (count: number) => {},
  autoAdvance = false,
  currentPhraseIndex,
  setCurrentPhraseIndex,
}) => {
  const speakId = useId()
  const { userId } = useUser()
  const { startingLanguage, currentLanguage } = useLanguage()
  const { speechSynthesisIsSupported, speechSynthesisReady, speak } = useSpeechSynthesis()
  const { speechRecognitionIsSupported } = useSpeechRecognition()
  const { playSuccess, playTryAgain, playError, soundFxOn, repeatOn } = useSoundFx()

  // waiting, listening, try_again, correct, incorrect
  const [cardState, setCardState] = useState("waiting")
  const [isAutoSwitching, setIsAutoSwitching] = useState(false)
  const [recentAnswer, setRecentAnswer] = useState(null)
  const [strikes, setStrikes] = useState(0)

  const phrase = phrases && phrases[currentPhraseIndex]
  const singleCardMode = phrases && phrases.length === 1

  const trySameOneAgain = () => {
    setStrikes(0)
    setCardState("listening")
    setRecentAnswer(null)
    setPhraseToShowInfoAbout(null)
  }

  useEffect(() => {
    if (cardState === "correct" && soundFxOn) {
      playSuccess()
      speechSynthesisIsSupported && speechSynthesisReady && repeatOn && speak(speakId, recentAnswer)
    }
    if (cardState === "try_again" && soundFxOn) {
      playTryAgain()
    }
    if (cardState === "incorrect" && soundFxOn) {
      playError()
    }
  }, [cardState, soundFxOn])

  useEffect(() => {
    if (!autoAdvance) return
    if (cardState === "correct" || cardState === "incorrect") {
      setIsAutoSwitching(true)
      setTimeout(() => {
        nextPhrase()
      }, 1000)
    }
  }, [cardState])

  const nextPhrase = () => {
    setIsAutoSwitching(false)
    setStrikes(0)
    setCardState("waiting")
    setRecentAnswer(null)
    if (singleCardMode) return
    setPhraseToShowInfoAbout(null)
    setCardsRemaining(phrases.length - currentPhraseIndex - 1)
    setCurrentPhraseIndex(current => {
      if (phrases.length - 1 <= current) {
        return 0
      } else {
        return current + 1
      }
    })
  }

  const submitAnswer = async (answer, options) => {
    console.log("🃏 SUBMITTING ANSWER", answer)
    setRecentAnswer(answer.trim())
    const correct = testAnswerString({ answer, correctAnswers })
    const maxStrikes =
      practiceType === "translation" ? MAX_TRANSLATION_STRIKES : MAX_PRONUNCIATION_STRIKES
    if (!correct && strikes < maxStrikes && !options?.forceSubmit) {
      setStrikes(s => s + 1)
      setCardState("try_again")
      return
    }
    setCardState(correct ? "correct" : "incorrect")

    setPhraseToShowInfoAbout(phrase)

    if (!userId) return

    try {
      const newData: PhraseAttempts = {
        language_id: currentLanguage.id,
        phrase_id: phrase?.id,
        prompt_type: cardQuestionType,
        repeated_only: practiceType === "pronunciation",
        guess: answer.trim(),
        is_correct: correct,
        answer_type: practiceType === "translation" ? cardAnswerType : "speech",
        // perfect_answer, // TODO - return in test algo
        // with_hint, // TODO - detect hints
        second_try: strikes > 0,
      }
      if (practiceType === "translation") {
        newData.direction = direction
      }
      const { error } = await supabase.from("phrase_attempts").insert([newData])
      if (error) throw error
    } catch (error) {
      logError("save your guess", error)
    }
  }

  const hasPhrases = phrases && phrases.length > 0

  const L2Answers = [phrase?.content_l2, ...(phrase?.content_l2_alts || [])]
  const L1Answers = [phrase?.content_l1, ...(phrase?.content_l1_alts || [])]

  let question, correctAnswers, answerLanguage
  if (practiceType === "translation") {
    question = direction === "forward" ? phrase?.content_l1 : phrase?.content_l2
    correctAnswers = direction === "forward" ? L2Answers : L1Answers
    answerLanguage = direction === "forward" ? currentLanguage : startingLanguage
  } else if (practiceType === "pronunciation") {
    question = phrase?.content_l2
    correctAnswers = L2Answers
    answerLanguage = currentLanguage
  }
  const CardAnswerComponent =
    cardAnswerType === "text" || !speechRecognitionIsSupported ? CardAnswerText : CardAnswerSpeech

  return (
    <>
      <DeckWrapper>
        {!hasPhrases && phraseSource === "all" ? (
          <EmptyCardPlaceholder>
            Sorry, no phrases available for this language yet
          </EmptyCardPlaceholder>
        ) : !hasPhrases && phraseSource === "learned" ? (
          <EmptyCardPlaceholder>
            You haven't learned any phrases to practice yet
          </EmptyCardPlaceholder>
        ) : !hasPhrases && phraseSource === "saved" ? (
          <EmptyCardPlaceholder>You haven't saved any phrases yet</EmptyCardPlaceholder>
        ) : !hasPhrases && phraseSource === "problem" ? (
          <EmptyCardPlaceholder>
            Great job--you don't have any problem phrases right now
          </EmptyCardPlaceholder>
        ) : !hasPhrases && phraseSource === "custom" ? (
          <EmptyCardPlaceholder>
            Sorry, there are no phrases available right now
          </EmptyCardPlaceholder>
        ) : !hasPhrases ? (
          <EmptyCardPlaceholder>
            Sorry, no phrases available for this lesson yet
          </EmptyCardPlaceholder>
        ) : (
          phrases.map((phrase, index) => {
            return (
              <Card
                key={`i${index}-p${phrase?.id}`} // id is not enough since phrases can repeat in the same lesson
                phrase={phrase}
                placeInLine={index - currentPhraseIndex}
                isRecentlyDone={currentPhraseIndex - index === 1}
                isDone={index < currentPhraseIndex}
                cardQuestionType={cardQuestionType}
                cardAnswerType={cardAnswerType}
                direction={direction}
                practiceType={practiceType}
              />
            )
          })
        )}
      </DeckWrapper>

      {hasPhrases && cardState === "incorrect" ? (
        <button
          className="button button-full-width"
          autoFocus
          onClick={trySameOneAgain}
          style={{ fontSize: "20px" }}
        >
          Try again
        </button>
      ) : (cardState === "correct" || cardState === "incorrect") && !singleCardMode ? (
        <button
          className="button button-full-width"
          autoFocus
          onClick={nextPhrase}
          disabled={isAutoSwitching}
          style={{ fontSize: "20px" }}
        >
          {isAutoSwitching ? "One moment..." : "Next"}
        </button>
      ) : null}

      {/* TODO - show whether a phrase is in their library, ie phraseScore && phraseScore.num_correct > 0) ? '✅' : '❌' */}
      {hasPhrases && (
        <CardAnswerComponent
          id={phrase?.id}
          direction={direction}
          correctAnswers={correctAnswers}
          disabled={cardState === "correct" || cardState === "incorrect"}
          submitAnswer={submitAnswer}
          language={answerLanguage}
        />
      )}

      {cardState === "try_again" ? (
        <p>
          Not quite, try again? Make sure you're pronouncing each word carefully (go slow if you
          need to).
        </p>
      ) : cardState === "correct" ? (
        <p>You're right!</p>
      ) : cardState === "incorrect" ? (
        <>
          {practiceType === "translation" ? (
            <>
              <p>
                Whoops, not quite. The answer is "{correctAnswers[0]}"{" "}
                {correctAnswers.length > 1 &&
                  `(or ${correctAnswers.map(ca => `"${ca}"`).join(", ")})`}
              </p>
              <DiffSentences correctAnswers={correctAnswers} guess={recentAnswer} />
            </>
          ) : (
            <p>That's not quite it. Let's skip this one for now.</p>
          )}
        </>
      ) : null}
      <div style={{ textAlign: "center" }}>
        {hasPhrases &&
          (cardState === "waiting" || cardState === "try_again" || cardState === "incorrect") &&
          !singleCardMode && (
            <button onClick={nextPhrase} style={{ padding: "1rem", textAlign: "center" }}>
              skip
            </button>
          )}
        {hasPhrases && (cardState === "waiting" || cardState === "try_again") && (
          <button
            onClick={() => submitAnswer("", { forceSubmit: true })}
            style={{ padding: "1rem", textAlign: "center" }}
          >
            show answer
          </button>
        )}
      </div>
    </>
  )
}

const DeckWrapper = styled.div`
  position: relative;
  height: 240px;
  margin: 0 0 2rem;
`
const EmptyCardPlaceholder = styled.div`
  border: 1px dashed;
  background: var(--bg);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`
