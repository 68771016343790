import { useState } from "react"
import { useNavigate } from "react-router-dom"
// import slugify from 'slugify'

import { useLanguage } from "@/context/language"
import { useUser } from "@/context/user"
import { supabase, useSupabaseQuery } from "@/db/supabase"
import { logError } from "@/utils/error.js"

export default () => {
  const { userId } = useUser()
  const { currentLanguage } = useLanguage()
  const navigate = useNavigate()

  const [titleL1, setTitleL1] = useState("")
  const [titleL2, setTitleL2] = useState("")
  const [level, setLevel] = useState(1)
  const [saving, setSaving] = useState(false)

  const lessonCountQuery = supabase.from("lessons").select("*", { count: "exact", head: true })
  const [lessonCount, lessonCountLoading, lessonCountError] = useSupabaseQuery(lessonCountQuery)

  const count = lessonCount?.count || 0

  async function submit(event) {
    event.preventDefault()
    try {
      setSaving(true)
      const { data: newLesson, error } = await supabase
        .from("lessons")
        .insert([
          {
            language_id: currentLanguage.id,
            title_l1: titleL1,
            title_l2: titleL2,
            proficiency_level: level,
            sort_order: count,
            created_by: userId,
          },
        ])
        .select()

      if (error) throw error
      navigate(`/${currentLanguage.slug_eng}/lessons/${newLesson[0].random_slug}/edit`)
    } catch (error) {
      setSaving(false)
      logError("create lesson", error)
    }
  }

  if (!userId) return "You must be logged in to create a lesson"

  return (
    <form onSubmit={submit}>
      <h2>New lesson</h2>

      <label htmlFor="titleL1">Title</label>
      <input
        id="titleL1"
        type="text"
        value={titleL1}
        placeholder=""
        onChange={e => setTitleL1(e.target.value)}
        required
      />

      <label htmlFor="titleL2">{currentLanguage.name_eng} title (optional)</label>
      <input
        id="titleL2"
        type="text"
        value={titleL2}
        placeholder=""
        onChange={e => setTitleL2(e.target.value)}
      />

      <label htmlFor="count">Sort order</label>
      <input id="count" type="number" value={count} disabled />

      <label htmlFor="level">level</label>
      <input
        id="level"
        type="number"
        value={level}
        placeholder="Level"
        onChange={e => setLevel(e.target.value)}
        required
      />
      <br />

      <br />

      <button
        className="button"
        type="submit"
        disabled={saving || lessonCountLoading || lessonCountError}
      >
        {saving ? "Adding..." : "Add lesson"}
      </button>
    </form>
  )
}
