// https://en.wikipedia.org/wiki/Help:IPA/Italian

/* spellchecker: disable */

export default {
  a: {
    symbol: "a",
    examples_ita: "alto, sarà, must",
    examples_eng: "f**a**st (Scottish English)",
    type: "vowel",
    native: true,
    file: "ä - Open_central_unrounded_vowel.ogg",
  },
  e: {
    symbol: "e",
    examples_ita: "vero, perché, liaison",
    examples_eng: "f**a**de",
    type: "vowel",
    native: true,
    file: "e - Close-mid_front_unrounded_vowel.ogg",
  },
  ɛ: {
    symbol: "ɛ",
    examples_ita: "etto, cioè, spread",
    examples_eng: "b**e**d",
    type: "vowel",
    native: true,
    file: "ɛ - Open-mid_front_unrounded_vowel.ogg",
  },
  i: {
    symbol: "i",
    examples_ita: "viso, sì, zia, feed, team, sexy",
    examples_eng: "sk**i**",
    type: "vowel",
    native: true,
    file: "i - Close_front_unrounded_vowel.ogg",
  },
  o: {
    symbol: "o",
    examples_ita: "ombra, otto, show, coach",
    examples_eng: "st**o**ry",
    type: "vowel",
    native: true,
    file: "o - Close-mid_back_rounded_vowel.ogg",
  },
  ɔ: {
    symbol: "ɔ",
    examples_ita: "otto, sarò, Sean",
    examples_eng: "**o**ff",
    type: "vowel",
    native: true,
    file: "ɔ - PR-open-mid_back_rounded_vowel.ogg",
  },
  u: {
    symbol: "u",
    examples_ita: "usi, ragù, tuo, tour",
    examples_eng: "r**u**le",
    type: "vowel",
    native: true,
    file: "u - Close_back_rounded_vowel.ogg",
  },
  ø: {
    symbol: "ø",
    examples_ita: "viveur, goethiano, Churchill]",
    examples_eng: "murder (RP)",
    type: "vowel",
    native: false,
    file: "ø - Close-mid_front_rounded_vowel.ogg",
  },
  y: {
    symbol: "y",
    examples_ita: "parure, brûlé, Führer]",
    examples_eng: "future (Scottish English)",
    type: "vowel",
    native: false,
    file: "y - Close_front_rounded_vowel.ogg",
  },
  b: {
    symbol: "b",
    name: "Voiced bilabial plosive",
    examples_ita: "banca, cibo",
    examples_eng: "a**b**out",
    type: "consonant",
    native: true,
    file: "b - Voiced_bilabial_plosive.ogg",
  },
  d: {
    symbol: "d",
    name: "Voiced alveolar plosive",
    examples_ita: "dove, idra, dado",
    examples_eng: "to**d**ay",
    type: "consonant",
    native: true,
    file: "d - Voiced_alveolar_plosive.ogg",
  },
  dz: {
    symbol: "dz",
    name: "Voiced alveolar sibilant affricate",
    examples_ita: "zaino, azalea, mezzo",
    examples_eng: "da**ds**",
    type: "consonant",
    native: true,
    file: "dz - Voiced_alveolar_sibilant_affricate.ogg",
  },
  dʒ: {
    symbol: "dʒ",
    name: "Voiced postalveolar affricate",
    examples_ita: "gelo, giù, magia, judo, gadget",
    examples_eng: "**j**ob",
    type: "consonant",
    native: true,
    file: "dʒ - Voiced_palato-alveolar_affricate.ogg",
  },
  f: {
    symbol: "f",
    examples_ita: "fatto, cifra, phon",
    examples_eng: "**f**ast",
    type: "consonant",
    native: true,
    file: "f - Voiceless_labio-dental_fricative.ogg",
  },
  ɡ: {
    symbol: "ɡ",
    examples_ita: "gatto, agro, ghetto, glicosio",
    examples_eng: "a**g**ain",
    type: "consonant",
    native: true,
    file: "ɡ - Voiced_velar_plosive_02.ogg",
  },
  j: {
    symbol: "j",
    examples_ita: "ieri, saio, più, Jesi, yacht, news",
    examples_eng: "**y**es",
    type: "consonant",
    native: true,
    file: "j - Palatal_approximant.ogg",
  },
  k: {
    symbol: "k",
    examples_ita: "cosa, acuto, finché, quei, kiwi, koala",
    examples_eng: "s**c**ar",
    type: "consonant",
    native: true,
    file: "k - Voiceless_velar_plosive.ogg",
  },
  l: {
    symbol: "l",
    examples_ita: "lato, tela, glicosio",
    examples_eng: "**l**adder",
    type: "consonant",
    native: true,
    file: "l - Alveolar_lateral_approximant.ogg",
  },
  ʎ: {
    symbol: "ʎ",
    examples_ita: "figli, glielo, maglia",
    examples_eng: "bi**lli**on",
    type: "consonant",
    native: true,
    file: "ʎ - Palatal_lateral_approximant.ogg",
  },
  m: {
    symbol: "m",
    examples_ita: "mano, amare, input",
    examples_eng: "**m**other",
    type: "consonant",
    native: true,
    file: "m - Bilabial_nasal.ogg",
  },
  ɱ: {
    symbol: "ɱ",
    examples_ita: "anfibio, invece",
    examples_eng: "co**m**fort",
    type: "consonant",
    native: true,
    file: "ɱ - Labiodental_nasal.ogg",
  },
  n: {
    symbol: "n",
    examples_ita: "nano, punto, pensare, mangiare",
    examples_eng: "**n**est",
    type: "consonant",
    native: true,
    file: "n - Alveolar_nasal.ogg",
  },
  ŋ: {
    symbol: "ŋ",
    examples_ita: "unghia, anche, dunque",
    examples_eng: "si**ng**",
    type: "consonant",
    native: true,
    file: "ŋ - Velar_nasal.ogg",
  },
  ɲ: {
    symbol: "ɲ",
    examples_ita: "gnocco, ogni",
    examples_eng: "ca**ny**on",
    type: "consonant",
    native: true,
    file: "ɲ - Palatal_nasal.ogg",
  },
  p: {
    symbol: "p",
    examples_ita: "primo, ampio, apertura",
    examples_eng: "s**p**in",
    type: "consonant",
    native: true,
    file: "p - Voiceless_bilabial_plosive.ogg",
  },
  r: {
    symbol: "r",
    examples_ita: "Roma, quattro, morte",
    examples_eng: "(none)",
    type: "consonant",
    native: true,
    file: "r - Alveolar_trill.ogg",
  },
  s: {
    symbol: "s",
    examples_ita: "sano, scusa, presentire, pasto",
    examples_eng: "**s**orry",
    type: "consonant",
    native: true,
    file: "s - Voiceless_alveolar_sibilant.ogg",
  },
  ʃ: {
    symbol: "ʃ",
    examples_ita: "scena, scià, pesci, flash, chic",
    examples_eng: "**sh**oe",
    type: "consonant",
    native: true,
    file: "ʃ - Voiceless_palato-alveolar_sibilant.ogg",
  },
  t: {
    symbol: "t",
    examples_ita: "tranne, mito, altro, thai",
    examples_eng: "s**t**ar",
    type: "consonant",
    native: true,
    file: "t - Voiceless_alveolar_plosive.ogg",
  },
  ts: {
    symbol: "ts",
    examples_ita: "zio, sozzo, marzo",
    examples_eng: "ca**ts**",
    type: "consonant",
    native: true,
    file: "ts - Voiceless_alveolar_sibilant_affricate.ogg",
  },
  tʃ: {
    symbol: "tʃ",
    examples_ita: "certo, ciao, farmacia, chip",
    examples_eng: "**ch**eck",
    type: "consonant",
    native: true,
    file: "tʃ - Voiceless_palato-alveolar_affricate.ogg",
  },
  v: {
    symbol: "v",
    examples_ita: "vado, povero, watt",
    examples_eng: "**v**ent",
    type: "consonant",
    native: true,
    file: "v - Voiced_labio-dental_fricative.ogg",
  },
  w: {
    symbol: "w",
    examples_ita: "uova, guado, qui, week-end",
    examples_eng: "**w**ine",
    type: "consonant",
    native: true,
    file: "w - Voiced_labio-velar_approximant.ogg",
  },
  z: {
    symbol: "z",
    examples_ita: "sbirro, presentare, asma",
    examples_eng: "ama**z**on",
    type: "consonant",
    native: true,
    file: "z - Voiced_alveolar_sibilant.ogg",
  },
  h: {
    symbol: "h",
    examples_ita: "hobby, hertz",
    examples_eng: "house",
    type: "consonant",
    native: false,
    file: "h - Voiceless_glottal_fricative.ogg",
  },
  θ: {
    symbol: "θ",
    examples_ita: "Thatcher, Pérez",
    examples_eng: "thing",
    type: "consonant",
    native: false,
    file: "θ - Voiceless_dental_fricative.ogg",
  },
  x: {
    symbol: "x",
    examples_ita: "jota, Bach, khamsin]",
    examples_eng: "loch (Scottish English)",
    type: "consonant",
    native: false,
    file: "x - Voiceless_velar_fricative.ogg",
  },
  ʒ: {
    symbol: "ʒ",
    examples_ita: "Fuji, garage, casual",
    examples_eng: "vision",
    type: "consonant",
    native: false,
    file: "ʒ - Voiced_palato-alveolar_sibilant.ogg",
  },
}
