import { useEffect, useState } from "react"

import { useLanguage } from "@/context/language"
import { useUser } from "@/context/user"
import { supabase, useSupabaseQuery } from "@/db/supabase"
import Icon from "@/styles/Icon"
import Tooltip from "@/styles/Tooltip"
import { logError } from "@/utils/error.js"

export default ({ wordString }) => {
  const { currentLanguage } = useLanguage()
  const { userId, isAlphaTester } = useUser()

  const [saving, setSaving] = useState(false)
  const [isSaved, setIsSaved] = useState(false)

  const savedWordsQuery = supabase
    .from("saved_words")
    .select()
    .eq("created_by", userId)
    .eq("language_id", currentLanguage.id)
    .eq("content_l2", wordString)
  const [matchingSavedWords, loading, error] = useSupabaseQuery(savedWordsQuery, [
    wordString,
    userId,
  ])

  const savedWord = matchingSavedWords?.length > 0 && matchingSavedWords[0]

  useEffect(() => {
    if (savedWord) {
      setIsSaved(true)
    }
  }, [savedWord])

  async function addSaved() {
    if (!userId) {
      alert("You must be logged in to save a word")
      return
    }

    try {
      setSaving(true)
      const { error } = await supabase.from("saved_words").insert([
        {
          language_id: currentLanguage.id,
          content_l2: wordString,
        },
      ])
      if (error) throw error
      setSaving(false)
      setIsSaved(true)
    } catch (error) {
      logError("save word", error)
    }
  }

  async function removeSaved() {
    if (!userId) {
      return
    }
    if (!savedWord) {
      return
    }

    try {
      setSaving(true)
      const { error } = await supabase.from("saved_words").delete().eq("id", savedWord.id)
      if (error) throw error
      setSaving(false)
      setIsSaved(false)
    } catch (error) {
      logError("remove saved word", error)
    }
  }

  return (
    <Tooltip
      label={
        !isAlphaTester
          ? "Saved words coming soon!"
          : isSaved
          ? "Remove from saved words"
          : "Save word to your custom list"
      }
    >
      <button
        type="button"
        className="button button-small button-tertiary"
        onClick={isSaved ? removeSaved : addSaved}
        disabled={saving || loading || error || !isAlphaTester}
      >
        <Icon name="bookmark" filled={isSaved} />
      </button>
    </Tooltip>
  )
}
