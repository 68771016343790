// import { useState } from 'react'
import { useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components/macro"

import { useLanguage } from "@/context/language"
import { supabase, useSupabaseQuery } from "@/db/supabase"
import AdminId from "@/views/_components/AdminId"
import { fromNow } from "@/utils/date"
import SpeakWord from "@/views/_components/SpeakWord"
import SupabaseAdminLink from "@/views/admin/SupabaseAdminLink"
import ErrorPage from "@/views/errors/ErrorPage"
import PhraseEdit from "@/views/practice/PhraseEdit"
import PhraseNew from "@/views/practice/PhraseNew"

export default () => {
  const { startingLanguage, currentLanguage } = useLanguage()

  const [sortByAttribute, setSortByAttribute] = useState("id")
  const [page, setPage] = useState(0)
  const [numResults, setNumResults] = useState(100)

  const query = supabase
    .from("phrases")
    .select("*, created_by(*)")
    .eq("language_id", currentLanguage.id)
    .order(sortByAttribute, { ascending: true })
  const [phrases, loading, error] = useSupabaseQuery(
    query,
    [currentLanguage.id, sortByAttribute, page, numResults],
    {
      page,
      numResults,
    }
  )

  if (error) return <ErrorPage error={error} />
  if (loading) return <div>loading...</div>
  if (!phrases || phrases.length <= 0) return <div>no phrases</div>

  // const deletePhrase = async (phraseId) => {
  //   if (!window.confirm('Are you sure you want to delete this phrase?')) return
  //   const { error } = await supabase
  //     .from('phrases')
  //     .update({ deleted_at: new Date() })
  //     .eq('id', phraseId)
  //   debugger
  //   if (error) alert(error.message)
  // }

  return (
    <>
      <h1>Phrases</h1>
      <PhraseNew />
      {" · "}
      sort by:{" "}
      <select value={sortByAttribute} onChange={e => setSortByAttribute(e.target.value)}>
        <option value="id">id</option>
        <option value="content_l1">content_l1</option>
        <option value="content_l2">content_l2</option>
        <option value="created_at">created_at</option>
        <option value="updated_at">updated_at</option>
      </select>
      Results per page:
      <select value={numResults} onChange={e => setNumResults(parseInt(e.target.value))}>
        <option value="100">100</option>
        <option value="500">500</option>
      </select>
      <hr />
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th></th>
            <th>{currentLanguage.name_eng}</th>
            <th>{currentLanguage.name_eng} alts</th>
            <th>{startingLanguage.name_eng}</th>
            <th>{startingLanguage.name_eng} alts</th>
            <th>Context</th>
            <th>Created</th>
            <th></th>
            <th>Updated</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {phrases.map(phrase => {
            return (
              <tr key={phrase.id}>
                <td>
                  <AdminId>{phrase.id}</AdminId>
                </td>
                <td>
                  <SpeakWord wordString={phrase.content_l2} />
                </td>
                <td style={{ whiteSpace: "nowrap" }}>
                  <Link to={`/${currentLanguage.slug_eng}/phrase/${phrase.random_slug}`}>
                    {phrase.content_l2}
                  </Link>
                </td>
                <td>{!phrase.content_l2_alts ? "" : phrase.content_l2_alts.join("\n")}</td>
                <td>{phrase.content_l1}</td>
                <td>{!phrase.content_l1_alts ? "" : phrase.content_l1_alts.join("\n")}</td>
                <td>{phrase.context_l1 || ""}</td>
                <td style={{ whiteSpace: "nowrap" }}>
                  {phrase.created_by?.username ? (
                    <Link to={`/u/${phrase.created_by.username}`}>
                      {phrase.created_by.username}
                    </Link>
                  ) : (
                    "❌ no username"
                  )}
                </td>
                <td style={{ whiteSpace: "nowrap" }}>{fromNow(phrase.created_at)}</td>
                <td style={{ whiteSpace: "nowrap" }}>{fromNow(phrase.updated_at)}</td>
                <td style={{ whiteSpace: "nowrap" }}>
                  <PhraseEdit phrase={phrase} />
                </td>
                <td>
                  <SupabaseAdminLink table="phrases" id={phrase.id} />
                </td>
                {/* <td>
                <button onClick={() => deletePhrase(phrase.id)}>
                  🗑️
                </button>
              </td> */}
              </tr>
            )
          })}
        </tbody>
      </table>
      <PaginationWrapper>
        <button className="button" onClick={() => setPage(page - 1)} disabled={page === 0}>
          Prev
        </button>
        <button className="button" onClick={() => setPage(page + 1)}>
          Next
        </button>
      </PaginationWrapper>
    </>
  )
}

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;
  padding: 1rem 0 0;
  border-top: 1px solid;
`
