import { createContext, useContext, useEffect, useState } from "react"

import { useDebug } from "@/context/debug"
import { useLanguage } from "@/context/language"
import { logError } from "@/utils/error"

// HOW THIS WORKS
// When speak is called, instead of speaking it immediately,
// we add the utterance to a queue of utterances that are waiting to be spoken.
// That way, if you call speak multiple times, it will speak each one in order.
// A few notes on why it's like this...
// First, we have separate effects that add to the queue and play from the queue.
// This is because we want to be able to add to the queue without messing up the current utterance.
// We also don't delete utterances from the queue until everything is done playing.
// This is because if we delete it from the queue, the pointer to it will be lost, and it will stop playing.

const Context = createContext({
  speechSynthesisIsSupported: false,
  speechSynthesisReady: false,
  speak: (callerId: string, text: string, options?: SpeakOptions) => {},
  isSpeaking: false,
  stopSpeech: () => {},
  currentUtteranceId: null,
  upcomingUtteranceIds: [],
})

interface Voice {
  lang: string
  default: boolean
  localService: boolean
  name: string
  voiceURI: string
}
interface SpeakOptions {
  volume?: number
  rate?: number
  pitch?: number
  voice?: Voice
  alt?: boolean
  gender?: "male" | "female" | undefined
  age?: number | undefined
}

// TODO - test more devices/browsers and see what the best voice is
const preferredVoices = [
  "Luca (Enhanced)", // Chrome MacOS
  "Alice", // iOS
  "Luca",
]

// scraped from Chrome dev tools, I removed all the non-obvious ones
// lots more voices available, I just need to test them
const maleVoiceNames = ["Daniel", "Diego", "Fred", "Jorge", "Juan", "Luca", "Thomas"]
const femaleVoiceNames = [
  "Alice",
  "Amelie",
  "Anna",
  "Ellen",
  "Fiona",
  "Karen",
  "Joana",
  "Laura",
  "Luciana",
  "Monica",
  "Nora",
  "Paulina",
  "Samantha",
  "Sara",
  "Tessa",
  "Victoria",
]

// based on https://codepen.io/matt-west/pen/DpmMgE
export default ({ children }) => {
  const { currentLanguage } = useLanguage()

  const speechSynthesisIsSupported = "speechSynthesis" in window

  const { isDebugging } = useDebug()
  const [voice, setVoice] = useState<Voice | null>()
  const [voices, setVoices] = useState<Voice[] | null>()
  const [voiceAlt, setVoiceAlt] = useState<Voice | null>()
  const [voiceMale, setVoiceMale] = useState<Voice | null>()
  const [voiceFemale, setVoiceFemale] = useState<Voice | null>()
  const [isSpeaking, setIsSpeaking] = useState(false)
  // TODO - isSpeaking and isPlaying are confusing, rename or combine
  const [isPlaying, setIsPlaying] = useState(false)
  const [utteranceItemQueue, setUtteranceItemQueue] = useState<
    { callerId: string; utterance: SpeechSynthesisUtterance }[]
  >([])
  const [queuePosition, setQueuePosition] = useState(0)

  const loadVoices = () => {
    const voices = speechSynthesis.getVoices()
    const langVoices = voices.filter(voice => voice.lang == currentLanguage.ietf_bcp_47)
    console.log(
      `👄 ${langVoices.length} matching voice(s) detected: `,
      langVoices.map(v => v.name).join(", ")
    )
    if (!langVoices.length) throw new Error("No voice available")
    const maleVoices = langVoices.filter(voice => maleVoiceNames.includes(voice.name))
    const femaleVoices = langVoices.filter(voice => femaleVoiceNames.includes(voice.name))

    const defaultVoice = langVoices.find(lv => preferredVoices.includes(lv.name)) || langVoices[0]
    const altVoice = langVoices[0]
    const maleVoice = maleVoices[0] || defaultVoice
    const femaleVoice = femaleVoices[0] || defaultVoice
    console.log(`👄 Using voices:`, {
      defaultVoice: defaultVoice?.name,
      altVoice: altVoice?.name,
      maleVoice: maleVoice?.name,
      femaleVoice: femaleVoice.name,
    })
    setVoices(langVoices)
    setVoice(defaultVoice)
    setVoiceAlt(altVoice)
    setVoiceMale(maleVoice)
    setVoiceFemale(femaleVoice)
  }

  const onStartedSpeaking = () => {
    console.log("👄 started speaking")
    setIsSpeaking(true)
  }

  const onStoppedSpeaking = () => {
    console.log("👄 stopped speaking")
    setIsSpeaking(false)
    setQueuePosition(queuePosition + 1)
  }

  // speak must be called with a unique ID that the caller component remembers
  // use React.useId() for this
  const speak = (callerId: string, text: string, options?: SpeakOptions) => {
    if (!speechSynthesisIsSupported) return

    if (!voice) {
      logError("speak this phrase", new Error("No voice available"))
      return
    }

    const utterance = new SpeechSynthesisUtterance()

    const maleAgePitch = options?.age > 60 ? 0.8 : options?.age < 20 ? 1.2 : 1
    const femaleAgePitch = options?.age > 60 ? 1 : options?.age < 20 ? 1.6 : 1.4
    const agePitch = options?.gender === "female" ? femaleAgePitch : maleAgePitch

    utterance.text = text
    utterance.volume = options?.volume || 0.75 // 0 to 1
    utterance.rate = options?.rate || 0.7 // 0.1 to 10
    utterance.pitch = options?.pitch || agePitch || 1 // 0 to 2
    utterance.voice =
      options?.gender === "male"
        ? voiceMale
        : options?.gender === "female"
        ? voiceFemale
        : options?.alt
        ? voiceAlt
        : voice

    const utteranceItem = {
      callerId,
      utterance,
    }

    console.log("adding to queue", utteranceItem)
    setUtteranceItemQueue([...utteranceItemQueue, utteranceItem])
    if (!isPlaying) {
      console.log("putting the needle on the record")
      setQueuePosition(0)
      setIsPlaying(true)
    }
  }

  const stopSpeech = () => {
    if (!speechSynthesisIsSupported) return
    window.speechSynthesis.cancel()
    setUtteranceItemQueue([])
    setQueuePosition(0)
    setIsPlaying(false)
  }

  // load voices when the language changes
  // but only if they're ready to be loaded (in Chrome it's async)
  // https://stackoverflow.com/questions/21513706/getting-the-list-of-voices-in-speechsynthesis-web-speech-api
  useEffect(() => {
    if (!currentLanguage?.id) return
    try {
      // firefox doesn't fire the on changed event, so we've got to do it right away
      loadVoices()
    } catch {
      // but chrome will break that way, so we can only listen for the event
      // (which DOES fire on page load when ready)
      window.speechSynthesis.onvoiceschanged = loadVoices
    }
    return () => {
      window.speechSynthesis.onvoiceschanged = null
    }
  }, [currentLanguage?.id])

  // watch for changes to the queue position
  // and play the new utterance when it changes
  useEffect(() => {
    if (!speechSynthesisIsSupported) return

    const utterance = utteranceItemQueue[queuePosition]?.utterance

    // if we hit stop, the utterance will be null
    if (!utterance) {
      isSpeaking && onStoppedSpeaking()
      return
    }

    window.speechSynthesis.speak(utterance)

    utterance.addEventListener("start", onStartedSpeaking)
    utterance.addEventListener("end", onStoppedSpeaking)

    return () => {
      utterance.removeEventListener("start", onStartedSpeaking)
      utterance.removeEventListener("end", onStoppedSpeaking)
    }
  }, [queuePosition, isPlaying])

  // clean up the queue when everything is done playing
  useEffect(() => {
    if (utteranceItemQueue.length && queuePosition > utteranceItemQueue.length - 1) {
      console.log("all done, cleaning up speech queue")
      setUtteranceItemQueue([])
      setQueuePosition(0)
      setIsPlaying(false)
    }
  }, [queuePosition])

  const currentUtteranceId = isPlaying && utteranceItemQueue[queuePosition]?.callerId
  const upcomingUtteranceIds = utteranceItemQueue
    .slice(queuePosition + 1)
    .map(item => item.callerId)

  const exposed = {
    speechSynthesisIsSupported,
    speechSynthesisReady: !!voice,
    speak,
    isSpeaking,
    stopSpeech,
    voices,
    voice,
    setVoice,
    currentUtteranceId,
    upcomingUtteranceIds,
  }

  return (
    <Context.Provider value={exposed}>
      {isDebugging && (
        <div
          style={{
            position: "fixed",
            bottom: "0",
            left: "0",
            zIndex: "9999",
            background: "hsla(30, 80%, 90%, .9)",
            fontSize: "var(--s)",
          }}
        >
          {utteranceItemQueue.map((item, index) => {
            return (
              <div key={index}>
                {item.callerId} · {item.utterance.voice?.name}: "{item.utterance.text.slice(0, 20)}
                ..." {isPlaying && index === queuePosition ? "🔊" : ""}
              </div>
            )
          })}
        </div>
      )}
      {children}
    </Context.Provider>
  )
}

export const useSpeechSynthesis = () => useContext(Context)
