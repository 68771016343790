import { Link } from "react-router-dom"
import styled from "styled-components/macro"

import { useLanguage } from "@/context/language"
import { useUser } from "@/context/user"
import { supabase, useSupabaseQuery } from "@/db/supabase"
import HelpButton from "@/views/_components/HelpButton"
import ErrorComponent from "@/views/errors/ErrorComponent"

export default () => {
  const { userId } = useUser()
  const { currentLanguage } = useLanguage()

  const query = supabase
    .from("user_prompt_type_percentages")
    .select()
    .eq("created_by", userId)
    .eq("language_id", currentLanguage.id)
  const [percentages, loading, error] = useSupabaseQuery(query, [userId])

  if (percentages && !percentages.find(p => p.prompt_type === "text")) {
    percentages.push({ prompt_type: "text", percentage: 0 })
  }
  if (percentages && !percentages.find(p => p.prompt_type === "audio")) {
    percentages.push({ prompt_type: "audio", percentage: 0 })
  }
  if (percentages && !percentages.find(p => p.prompt_type === "both")) {
    percentages.push({ prompt_type: "both", percentage: 0 })
  }

  if (error) return <ErrorComponent error={error} />
  if (loading) return <p>Loading...</p>

  return (
    <div>
      <h4>
        Input types{" "}
        <HelpButton helpText="How often you read vs listen to a language. Beginners should try to listen as much as possible." />
      </h4>
      {!userId ? (
        <p>
          <Link to={`/login?redirectTo=${encodeURIComponent(window.location.pathname)}`}>
            Log in
          </Link>{" "}
          to track progress in your most important topics
        </p>
      ) : (
        <ContentTypesWrapper>
          {percentages.map(({ prompt_type, percentage: percentageRaw }, index) => {
            const percentage = Math.round(percentageRaw)
            return (
              <ContentType key={index}>
                <PieChart percentage={percentage} />
                <div>
                  {/* <LegendBox color="black" /> */}
                  {prompt_type === "text" ? "Text" : prompt_type === "audio" ? "Audio" : "Both"}(
                  {percentage}%)
                </div>
              </ContentType>
            )
          })}
        </ContentTypesWrapper>
      )}
    </div>
  )
}

const ContentTypesWrapper = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: auto;
`
const ContentType = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`
const PieChart = styled.div`
  width: 24px;
  height: 24px;
  border: 1px solid;
  border-radius: 50%;
  background-image: ${props =>
    `conic-gradient(var(--bg) ${100 - props.percentage}%, var(--text) 0)`};
`
const LegendBox = styled.div`
  display: inline-block;
  width: 0.66rem;
  height: 0.66rem;
  background: ${props => props.color};
  border: 1px solid;
  margin-right: 0.5rem;
`
