import { useDebounce } from "use-debounce"

import { useLanguage } from "@/context/language"
import Definable from "@/views/_components/Definable"
import SpeakWord from "@/views/_components/SpeakWord"

export default props => {
  const { currentLanguage } = useLanguage()

  const initialL2 = props[`${currentLanguage.iso639_3}`]
  const [word_l2_dirty] = useDebounce(initialL2, 500, { leading: true })
  const word_l2 = word_l2_dirty?.replaceAll("*", "").replaceAll("_", "")

  return (
    <span style={{ display: "inline-flex" }}>
      <span style={{ fontWeight: "600" }}>
        <Definable wordString={word_l2} />
      </span>
      <SpeakWord wordString={word_l2} />
    </span>
  )
}
