import { useEffect } from "react"

import { logError } from "@/utils/error"

export default ({ error }) => {
  useEffect(() => {
    logError("load this component", error, true)
  }, [error])

  return (
    <div>
      <h3>Whoops, something went wrong</h3>
      <p>Sorry about that! We're looking into it.</p>
      <p>
        <button onClick={() => location.reload()}>Refresh this page</button>
      </p>
    </div>
  )
}
