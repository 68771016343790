import { Link } from "react-router-dom"

import { useLanguage } from "@/context/language"
import { useUser } from "@/context/user"
import { supabase, useSupabaseRealtimeQuery } from "@/db/supabase"
import { ProgressBar, ProgressBarWrapper } from "@/styles/ProgressBar"
import { getStartOfDayForQuery } from "@/utils/date"

const DAILY_ATTEMPT_GOAL = 20

export default () => {
  const { userId } = useUser()
  const { currentLanguage } = useLanguage()

  // TODO - only return a count
  const query = supabase
    .from("phrase_attempts")
    .select()
    .eq("created_by", userId)
    .eq("language_id", currentLanguage.id)
    .gte("created_at", getStartOfDayForQuery())

  const [attemptsToday, loading, error] = useSupabaseRealtimeQuery(
    query,
    [userId],
    {},
    {
      event: "INSERT",
      schema: "public",
      table: "phrase_attempts",
      filter: `created_by=eq.${userId}`,
    }
  )

  const attemptsTodayNum = attemptsToday?.length
  const percentToday = attemptsTodayNum && attemptsTodayNum / DAILY_ATTEMPT_GOAL

  return (
    <div>
      {error ? (
        error.message
      ) : !userId ? (
        <>
          0/{DAILY_ATTEMPT_GOAL} today
          <ProgressBarWrapper disabled>
            <ProgressBar progress={0} />
          </ProgressBarWrapper>
          <Link to="/signup">Create an account</Link> to see your daily activity
        </>
      ) : loading ? (
        <>
          .../{DAILY_ATTEMPT_GOAL} today
          <ProgressBarWrapper disabled>
            <ProgressBar progress={0} />
          </ProgressBarWrapper>
        </>
      ) : (
        <>
          {attemptsTodayNum}/{DAILY_ATTEMPT_GOAL} today
          <ProgressBarWrapper disabled={!percentToday}>
            <ProgressBar progress={percentToday} />
          </ProgressBarWrapper>
        </>
      )}
    </div>
  )
}
