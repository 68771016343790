import useHover from "@/hooks/useHover"
import { useEffect, useRef } from "react"
import styled from "styled-components/macro"

export default ({ soundInfo }) => {
  const audioRef = useRef(new Audio(`/sounds/ipa/${soundInfo.file}`))
  const [hoverRef, isHovered] = useHover()

  useEffect(() => {
    if (isHovered) {
      audioRef.current.play()
    } else {
      audioRef.current.pause()
      audioRef.current.currentTime = 0
    }
  }, [isHovered])

  return <SoundWrapper ref={hoverRef}>{soundInfo?.symbol || ""}</SoundWrapper>
}

export const SoundWrapper = styled.span`
  cursor: pointer;
  border-radius: 3px;
  background: var(--badge);
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #ddd;
  }
`
