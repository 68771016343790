import { useState } from "react"
import ReactTextareaAutosize from "react-textarea-autosize"

import { useLanguage } from "@/context/language"
import { useUser } from "@/context/user"
import { supabase } from "@/db/supabase"
import Icon from "@/styles/Icon"
import Modal from "@/styles/Modal"
import { logError } from "@/utils/error.js"
import TranslateHelper from "@/views/_components/TranslateHelper.js"

interface PhraseNewParams {
  l1Word?: string
  l2Word?: string
  l1Alts?: string[]
  l2Alts?: string[]
  l1Context?: string
}

export default ({ l1Word, l2Word, l1Alts, l2Alts, l1Context }: PhraseNewParams) => {
  const { userId } = useUser()
  const { startingLanguage, currentLanguage } = useLanguage()

  const [phraseCreatorIsOpen, setPhraseCreatorIsOpen] = useState(false)
  const [contentL2, setContentL2] = useState(l2Word || "")
  const [contentL1, setContentL1] = useState(l1Word || "")
  const [L2Alts, setL2Alts] = useState(l2Alts || [])
  const [L1Alts, setL1Alts] = useState(l1Alts || [])
  const [contextL1, setContextL1] = useState(l1Context || "")
  const [saving, setSaving] = useState(false)

  // ********************************************************************************** //
  // ********************************************************************************** //
  // KEEP THIS IN SYNC WITH THE PHRASE EDIT AND IMPORT FUNCTIONS THAT DO THE SAME THING //
  // ********************************************************************************** //
  // ********************************************************************************** //

  const slugifyPhraseContent = ({ content_l1, content_l1_alts, content_l2, content_l2_alts }) => {
    const L1_alts_lower = content_l1_alts ? content_l1_alts.map(alt => alt.toLowerCase()) : []
    const L2_alts_lower = content_l2_alts ? content_l2_alts.map(alt => alt.toLowerCase()) : []
    return {
      slugs_l1: [content_l1.toLowerCase(), ...L1_alts_lower],
      slugs_l2: [content_l2.toLowerCase(), ...L2_alts_lower],
    }
  }

  async function submit(event) {
    event.preventDefault()
    event.stopPropagation()

    try {
      setSaving(true)
      const { slugs_l1, slugs_l2 } = slugifyPhraseContent({
        content_l1: contentL1,
        content_l1_alts: L1Alts,
        content_l2: contentL2,
        content_l2_alts: L2Alts,
      })
      const { error } = await supabase
        .from("phrases")
        .insert([
          {
            language_id: currentLanguage.id,
            content_l2: contentL2,
            content_l1: contentL1,
            content_l2_alts: L2Alts,
            content_l1_alts: L1Alts,
            context_l1: contextL1,
            slugs_l1,
            slugs_l2,
          },
        ])
        .select()
      if (error) throw error
      // TODO - make sure other pages have reactivity on so they see the update
      setPhraseCreatorIsOpen(false)
    } catch (error) {
      setSaving(false)
      logError("create phrase", error)
    }
  }

  const updateAlt = (lang, { index, value }) => {
    const originalArray = lang === "lang_L2" ? L2Alts : L1Alts
    const updateFunction = lang === "lang_L2" ? setL2Alts : setL1Alts
    const newArray = [...originalArray]
    newArray[index] = value
    updateFunction(newArray)
  }

  const withoutArticlesL2 = contentL2
    .replace("il ", "")
    .replace("lo ", "")
    .replace("l'", "")
    .replace("la ", "")
    .replace("i ", "")
    .replace("gli ", "")
    .replace("le ", "")
    .replace("un ", "")
    .replace("uno ", "")
    .replace("una ", "")

  const withoutArticleL1 = contentL1.replace("the ", "").replace("a ", "")

  if (!userId) return null

  return (
    <>
      <button
        type="button"
        className="button button-small"
        onClick={() => setPhraseCreatorIsOpen(true)}
      >
        add
      </button>

      <Modal
        title="New phrase"
        isOpen={phraseCreatorIsOpen}
        onClose={() => setPhraseCreatorIsOpen(false)}
        width="460px"
      >
        <form onSubmit={submit}>
          <label htmlFor="contentL2">{currentLanguage.name_eng}</label>
          <ReactTextareaAutosize
            style={{ height: 50 }}
            id="contentL2"
            value={contentL2}
            placeholder=""
            minRows={1}
            onChange={e => setContentL2(e.target.value)}
            required
          />
          <TranslateHelper contentL1={contentL1} onNewTranslation={setContentL2} />

          {L2Alts?.map((alt, index) => {
            return (
              <div key={index} style={{ display: "flex" }}>
                <ReactTextareaAutosize
                  style={{ height: 50 }}
                  value={alt}
                  minRows={1}
                  onChange={e => updateAlt("lang_L2", { index, value: e.target.value })}
                />
                {/* https://stackoverflow.com/a/47024021 */}
                <button
                  type="button"
                  className="button button-tertiary"
                  onClick={() => setL2Alts([...L2Alts.slice(0, index), ...L2Alts.slice(index + 1)])}
                >
                  <Icon name="delete" />
                </button>
              </div>
            )
          })}
          <button
            type="button"
            className="button button-small"
            onClick={() => setL2Alts([...L2Alts, ""])}
          >
            Add alternate
          </button>
          <button
            type="button"
            className="button button-small"
            onClick={() => setL2Alts([...L2Alts, withoutArticlesL2])}
          >
            Add w/o articles
          </button>

          <p style={{ fontSize: "var(--s)" }}>
            Please add definite pronouns to nouns (i.e., use "il conto", not "conto"). The system
            will still automatically accept "conto" when students run a flashcard.
          </p>

          <hr />

          <label htmlFor="contentL1">{startingLanguage.name_eng}</label>
          <ReactTextareaAutosize
            style={{ height: 50 }}
            id="contentL1"
            value={contentL1}
            placeholder=""
            minRows={1}
            onChange={e => setContentL1(e.target.value)}
            required
          />
          <TranslateHelper contentL2={contentL2} onNewTranslation={setContentL1} />

          {L1Alts?.map((alt, index) => {
            return (
              <div key={index} style={{ display: "flex" }}>
                <ReactTextareaAutosize
                  style={{ height: 50 }}
                  value={alt}
                  minRows={1}
                  onChange={e => updateAlt("lang_L1", { index, value: e.target.value })}
                />
                {/* https://stackoverflow.com/a/47024021 */}
                <button
                  type="button"
                  className="button button-tertiary"
                  onClick={() => setL1Alts([...L1Alts.slice(0, index), ...L1Alts.slice(index + 1)])}
                >
                  <Icon name="delete" />
                </button>
              </div>
            )
          })}
          <button
            type="button"
            className="button button-small"
            onClick={() => setL1Alts([...L1Alts, ""])}
          >
            Add alternate
          </button>
          <button
            type="button"
            className="button button-small"
            onClick={() => setL1Alts([...L1Alts, withoutArticleL1])}
          >
            Add w/o article
          </button>

          <p style={{ fontSize: "var(--s)" }}>
            No need to add alternatives for contractions ("I'll" vs "I will") or pronouns ("he",
            "she", "it", "you", "you all"); these are handled by the system automatically.
          </p>

          <hr />

          <label htmlFor="contextL1">Context</label>
          <p style={{ fontSize: "var(--s)" }}>
            When is this used? Any other notes? (Use {startingLanguage.name_eng})
          </p>
          <ReactTextareaAutosize
            style={{ height: 50 }}
            id="contextL1"
            value={contextL1}
            placeholder=""
            minRows={1}
            onChange={e => setContextL1(e.target.value)}
          />

          <hr />

          <button className="button" type="submit" disabled={saving}>
            {saving ? "Adding..." : "Add phrase"}
          </button>
        </form>
      </Modal>
    </>
  )
}
