import { saveAs } from "file-saver"
import JSZip from "jszip"
import { useState } from "react"
import yaml from "yaml"

import { supabase, useSupabaseQuery } from "@/db/supabase"

const lessonReadmeContent = `# Protolang lessons

This is a collection of lessons from [Protolang.com](https://protolang.com).

Generated ${new Date().toString()}

See individual lessons for license information. Many lessons use Creative Commons licenses; others are NOT licensed for reuse.
`

export default () => {
  // ready, working, done
  const [downloadingState, setDownloadingState] = useState("ready")

  const languagesQuery = supabase.from("languages").select()
  const [languages, languagesLoading, languagesError] = useSupabaseQuery(languagesQuery)

  const downloadAll = async () => {
    setDownloadingState("working")

    // get lessons
    console.log("⬇️ getting lessons")
    const { data: lessons, error } = await supabase
      .from("lessons")
      .select()
      .order("language_id, proficiency_level, sort_order", { ascending: true })

    if (error) {
      alert("Sorry, could not download lessons right now. Try again later?")
      console.error(error)
      return
    }

    // generate object of markdown files
    console.log("⬇️ generating lessonFiles")
    const lessonFiles = lessons.map((lesson, index) => {
      const language = languages.find(lang => lang.id === lesson.language_id)
      const languageSlug = language.slug_eng
      const { proficiency_level, title_l1, random_slug } = lesson
      const metadata = { language: languageSlug, proficiency_level, title_l1 }
      metadata.license = lesson.license
      if (lesson.title_l2) {
        metadata.title_l2 = lesson.title_l2
      }
      if (lesson.description_l1) {
        metadata.description_l1 = lesson.description_l1
      }
      if (lesson.description_l2) {
        metadata.description_l2 = lesson.description_l2
      }
      if (lesson.topics) {
        metadata.topics = lesson.topics
      }
      if (lesson.vocab_l1) {
        metadata.vocab = lesson.vocab_l1
      }
      if (lesson.key_phrases_l1) {
        metadata.key_phrases = lesson.key_phrases_l1
      }
      if (lesson.key_phrases_l2) {
        metadata.key_phrases = lesson.key_phrases_l2
      }
      if (lesson.attributions) {
        metadata.attributions = lesson.attributions
      }
      metadata.link = `https://protolang.com/${languageSlug}/lessons/${random_slug}`
      const metadataYaml = yaml.stringify(metadata, { sortMapEntries: true })
      const frontmatter = `---\n${metadataYaml}---\n`

      const cleanedTitle = title_l1.replace(/\//g, "-")
      const indexPadded = index.toString().padStart(6, "0")
      return {
        name: `${indexPadded} - ${cleanedTitle || "[No title]"}.md`,
        content: frontmatter + (lesson.content_l1 || ""),
      }
    })

    lessonFiles.push({
      name: "README.md",
      content: lessonReadmeContent,
    })

    // make zip file
    console.log("⬇️ zipping lessonFiles", lessonFiles)
    const zip = new JSZip()
    lessonFiles.forEach(file => zip.file(file.name, file.content))
    const zipFile = await zip.generateAsync({ type: "blob" })

    // save file
    console.log("⬇️ saving lessonFiles")
    saveAs(zipFile, `Protolang lessons.zip`)

    setDownloadingState("done")
  }

  if (languagesLoading) return <p>Loading...</p>
  if (languagesError) return <p>Error: {languagesError.message}</p>

  return (
    <button
      disabled={downloadingState === "working" || downloadingState === "done"}
      onClick={downloadAll}
    >
      {downloadingState === "ready"
        ? "Download all lessons"
        : downloadingState === "working"
        ? "Downloading..."
        : downloadingState === "done"
        ? "Done!"
        : "Error"}
    </button>
  )
}
