// Please avoid coding non-English words directly into UI code!
// They should go here instead.
// Eventually this should be replaced with some sort of translation library,
// ie react i18n or similar

export default {
  italian: {
    pronouns: {
      first_person_singular: 'Io',
      second_person_singular: 'Tu',
      third_person_singular: 'Lui/Lei',
      first_person_plural: 'Noi',
      second_person_plural: 'Voi',
      third_person_plural: 'Loro',
    },
    media_links: {
      music: 'https://open.spotify.com/playlist/37i9dQZEVXbIQnj7RRhdSX',
      podcasts: 'https://chartable.com/charts/itunes/it-all-podcasts-podcasts',
      radio: 'https://www.raiplaysound.it/radio1',
      live_news: 'https://www.rainews.it/notiziari/rainews24',
    },
    welcome: {
      male: 'Benvenuto',
      female: 'Benvenuta',
      phrase: 'Siamo entusiasti di averti qui',
    },
    friend: {
      male: 'amico',
      female: 'amica',
    },
    lets_go: 'Andiamo',
  },
  french: {
    pronouns: {
      first_person_singular: 'Je',
      second_person_singular: 'Tu',
      third_person_singular: 'Il/Elle',
      first_person_plural: 'Nous',
      second_person_plural: 'Vous',
      third_person_plural: 'Ils/Elles',
    },
    media_links: {
      music: 'https://open.spotify.com/playlist/37i9dQZEVXbIPWwFssbupI',
      podcasts: 'https://chartable.com/charts/itunes/fr-all-podcasts-podcasts',
      radio: 'https://www.franceinter.fr/direct',
      live_news: 'https://www.france24.com/fr/direct',
    },
    welcome: {
      male: 'Bienvenue',
      female: 'Bienvenue',
      phrase: 'Nous sommes ravis de vous avoir ici',
    },
    friend: {
      male: 'ami',
      female: 'amie',
    },
    lets_go: 'Allons-y',
  },
}
