import { useEffect, useState } from "react"
import { Link, useSearchParams } from "react-router-dom"

import { useLanguage } from "@/context/language"
import { useSpeechRecognition } from "@/context/speechRecognition"
import { useUser } from "@/context/user"
import { supabase } from "@/db/supabase"
import { BreadcrumbItem, BreadcrumbSeparator, BreadcrumbWrapper } from "@/styles/Breadcrumbs"
import { MainWrapper, TwoColumns } from "@/styles/Layout"
import { CheckboxGroup } from "@/views/_components/RadioCheckbox"
import ToggleGroup from "@/styles/ToggleGroup"
import Tooltip from "@/styles/Tooltip"
import { logError } from "@/utils/error"
import DailyProgress from "@/views/_components/DailyProgress"
import AnswerPhrase from "@/views/practice/AnswerPhrase"
import CardDeck from "@/views/practice/CardDeck"

export default ({ lessons, savedWords, phraseScores, phraseSource = "all" }) => {
  const { userId } = useUser()
  const { startingLanguage, currentLanguage } = useLanguage()
  const [searchParams] = useSearchParams() || {}
  const { speechRecognitionIsSupported } = useSpeechRecognition

  const [phraseList, setPhraseList] = useState([])
  const [phrasesLoading, setPhrasesLoading] = useState(false)
  const [phrasesError, setPhrasesError] = useState(null)
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0)
  const [cardsRemaining, setCardsRemaining] = useState(0)
  const [autoAdvance, setAutoAdvance] = useState(false)

  const [cardQuestionType, setCardQuestionType] = useState("both")
  const [cardAnswerType, setCardAnswerType] = useState("speech")
  const [direction, setDirection] = useState("forward")
  const [practiceType, setPracticeType] = useState(searchParams.get("type") || "translation") // pronunciation, translation
  const [phraseToShowInfoAbout, setPhraseToShowInfoAbout] = useState(null)

  const addMorePhrases = async ({ clearFirst }) => {
    console.log(
      `🃏 Adding more phrases to the lesson with source "${phraseSource}", clear ${!!clearFirst}`
    )
    // if (clearFirst) {
    //   setPhraseList([])
    // }
    try {
      setPhrasesLoading(true)
      let functionName, functionArguments
      if (phraseSource === "all") {
        functionName = "get_phrases_from_all"
        functionArguments = { lang_id: currentLanguage.id }
      } else if (phraseSource === "learned") {
        functionName = "get_phrases_by_slugs"
        functionArguments = {
          lang_id: currentLanguage.id,
          phrase_slugs_l2: phraseScores?.map(phraseScore => phraseScore.phrase_id?.content_l2),
          phrase_slugs_l1: phraseScores?.map(phraseScore => phraseScore.phrase_id?.content_l1),
        }
      } else if (phraseSource === "saved") {
        functionName = "get_phrases_by_slugs"
        const savedWordsSlugs = savedWords?.map(savedWord => savedWord.content_l2)
        functionArguments = {
          lang_id: currentLanguage.id,
          phrase_slugs_l2: savedWordsSlugs,
          phrase_slugs_l1: [],
        }
      } else if (phraseSource === "problem") {
        functionName = "get_phrases_by_slugs"
        const problemPhrases = phraseScores?.filter(phraseScore => phraseScore.score < 0.5)
        functionArguments = {
          lang_id: currentLanguage.id,
          phrase_slugs_l2: problemPhrases?.map(phraseScore => phraseScore.phrase_id?.content_l2),
          phrase_slugs_l1: problemPhrases?.map(phraseScore => phraseScore.phrase_id?.content_l1),
        }
      } else {
        functionName = "get_phrases_by_slugs"
        if (!lessons) return console.error("no lessons yet...")
        const lesson = lessons.find(lesson => lesson.random_slug === phraseSource)
        functionArguments = {
          lang_id: currentLanguage.id,
          phrase_slugs_l2: lesson?.phrase_slugs_l2,
          phrase_slugs_l1: lesson?.phrase_slugs_l1,
        }
      }
      console.log(`🏃 triggering db function ${functionName}`)
      const { data, error } = await supabase.rpc(functionName, functionArguments)
      if (error) throw error
      const newData = data || []
      if (clearFirst) {
        setPhraseList(newData)
      } else {
        setPhraseList([...phraseList, ...newData])
      }
      setPhrasesLoading(false)
    } catch (error) {
      setPhrasesError(error)
      setPhrasesLoading(false)
      logError("fetching phrases", error)
    }
  }

  useEffect(() => {
    console.log(`🃏 Phrase source updated to "${phraseSource}"`)
    setCurrentPhraseIndex(0)
    addMorePhrases({ clearFirst: true })
  }, [phraseSource])

  useEffect(() => {
    if (cardsRemaining < 3) addMorePhrases({ clearFirst: false })
  }, [cardsRemaining])

  const isNormalLesson = !["all", "learned", "saved", "problem"].includes(phraseSource)

  return (
    <MainWrapper>
      <TwoColumns cols="2fr 1fr">
        <div>
          <BreadcrumbWrapper>
            <BreadcrumbItem to={`/${currentLanguage.slug_eng}`}>Home</BreadcrumbItem>
            <BreadcrumbSeparator />
            {phraseSource}
            {/* <select value={phraseSource} onChange={e => navigate(`/${currentLanguage.slug_eng}/practice/${e.target.value}`)}>
            <option value="all">All lessons</option>
            <optgroup label="Keep learning">
              <option value="learned">Learned</option>
              <option value="saved">Saved</option>
              <option value="problem">Problem phrases</option>
            </optgroup>
            <optgroup label="Lessons">
              { lessons?.map(lesson => <option key={lesson.random_slug} value={lesson.random_slug}>{lesson.title_l1}</option>) }
            </optgroup>
          </select> */}
          </BreadcrumbWrapper>
          <br />

          <CardDeck
            practiceType={practiceType}
            cardQuestionType={cardQuestionType}
            cardAnswerType={practiceType === "translation" ? cardAnswerType : "speech"}
            direction={practiceType === "translation" ? direction : null}
            phrases={phraseList}
            phraseSource={phraseSource}
            setPhraseToShowInfoAbout={setPhraseToShowInfoAbout}
            setCardsRemaining={setCardsRemaining}
            autoAdvance={autoAdvance}
            currentPhraseIndex={currentPhraseIndex}
            setCurrentPhraseIndex={setCurrentPhraseIndex}
          />

          {phrasesLoading ? "..." : phrasesError ? "Error loading phrases" : null}

          {/* TODO - maybe refactor this out so we're not getting it from some buried component */}
          {phraseToShowInfoAbout ? <AnswerPhrase phrase={phraseToShowInfoAbout} /> : ""}
        </div>

        <div>
          <ToggleGroup
            label="Practice type"
            items={[
              { label: "Translate", value: "translation" },
              { label: "Pronunciation", value: "pronunciation" },
            ]}
            value={practiceType}
            setValue={setPracticeType}
          />
          {practiceType === "translation" && (
            <>
              <select
                value={direction}
                onChange={e => setDirection(e.target.value)}
                style={{ width: "100%" }}
              >
                <option value="forward">
                  {startingLanguage.name_eng} to {currentLanguage.name_eng}
                </option>
                <option value="reverse">
                  {currentLanguage.name_eng} to {startingLanguage.name_eng}
                </option>
              </select>
              <br />
            </>
          )}
          {(practiceType === "pronunciation" || direction !== "forward") && (
            <>
              <select
                value={cardQuestionType}
                onChange={e => setCardQuestionType(e.target.value)}
                style={{ width: "100%" }}
              >
                <option value="both">Text and audio prompts</option>
                <option value="text">Text prompts only</option>
                <option value="audio">Audio prompts only</option>
              </select>
              <br />
            </>
          )}
          {practiceType === "translation" && (
            <>
              <Tooltip
                label={
                  !speechRecognitionIsSupported &&
                  "Your browser doesn't support speech recognition yet. Try Chrome?"
                }
              >
                <select
                  value={!speechRecognitionIsSupported ? "text" : cardAnswerType}
                  onChange={e => setCardAnswerType(e.target.value)}
                  style={{ width: "100%" }}
                  disabled={!speechRecognitionIsSupported}
                >
                  <option value="speech">Answer by speaking</option>
                  <option value="text">Answer with text</option>
                </select>
              </Tooltip>
            </>
          )}
          <hr />
          <CheckboxGroup
            groupName="Options"
            options={[{ title: "Auto-advance", id: "autoAdvance" }]}
            values={[autoAdvance && "autoAdvance"]}
            setValues={values => {
              setAutoAdvance(!!values.includes("autoAdvance"))
            }}
          />
          <hr />
          <DailyProgress />
          {userId && (
            <p style={{ fontSize: "var(--s)" }}>
              <Link to={`/${currentLanguage.slug_eng}/attempts`}>Practice history</Link>
            </p>
          )}
          <hr />
          {isNormalLesson && (
            <Link to={`/${currentLanguage.slug_eng}/lessons/${phraseSource}`}>View lesson</Link>
          )}
        </div>
      </TwoColumns>
    </MainWrapper>
  )
}
