import { useEffect, useState } from "react"

import { useLanguage } from "@/context/language"
import { supabase } from "@/db/supabase"
import { logError } from "@/utils/error"
import ReportIssue from "@/views/_components/ReportIssue"
import TV from "@/views/media/TV"

export default () => {
  const { currentLanguage } = useLanguage()

  const [videos, setVideos] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [videoIndex, setVideoIndex] = useState(0)

  useEffect(() => {
    const getVideos = async () => {
      try {
        console.log(`🏃 triggering db function get_random_videos`)
        setLoading(true)
        const { data, error } = await supabase.rpc("get_random_videos", {
          lang_id: currentLanguage.id,
        })
        if (error) throw error
        setError(null)
        setVideos(data || [])
        setLoading(false)
      } catch (error) {
        setError(error)
        setVideos([])
        setLoading(false)
        logError("get random videos", error)
      }
    }
    getVideos()
  }, [currentLanguage.id])

  const nextVideo = () => {
    if (videoIndex >= videos.length - 1) {
      setVideoIndex(0)
    } else {
      setVideoIndex(videoIndex + 1)
    }
  }
  const prevVideo = () => {
    if (videoIndex <= 0) {
      setVideoIndex(videos.length - 1)
    } else {
      setVideoIndex(videoIndex - 1)
    }
  }

  const currentVideo = videos && videos[videoIndex]

  return (
    <>
      {error ? (
        error.message
      ) : loading ? (
        "loading..."
      ) : !videos || videos.length <= 0 ? (
        "no videos"
      ) : (
        <>
          <TV youtubeUrl={currentVideo?.url} onNext={nextVideo} onPrev={prevVideo} />
          <ReportIssue media={currentVideo} />
        </>
      )}
    </>
  )
}
