import Icon from "@/styles/Icon"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import ReactTextareaAutosize from "react-textarea-autosize"
import styled from "styled-components/macro"

import Licenses from "@/consts/licenses"
import { useLanguage } from "@/context/language"
import { useUser } from "@/context/user"
import { supabase, useSupabaseQuery } from "@/db/supabase"
import { BreadcrumbItem, BreadcrumbSeparator, BreadcrumbWrapper } from "@/styles/Breadcrumbs"
import { CheckboxGroup } from "@/views/_components/RadioCheckbox"
import ToggleGroup from "@/styles/ToggleGroup"
import { logError } from "@/utils/error.js"
import { parsePhrasesFromLessonContent } from "@/utils/parseMarkdown"
import LessonContent from "@/views/lessons/LessonContent"
import LessonPhraseList from "@/views/lessons/LessonPhraseList"
import EditorOnboarding from "@/views/onboarding/EditorOnboarding.js"

// TODO - users can publish lessons!

export default () => {
  const { userId, userProfile, isAdmin } = useUser()
  const { lessonSlug } = useParams()
  const { currentLanguage } = useLanguage()

  const random_slug = lessonSlug.split("-").pop()

  // lesson attrs
  const [titleL1, setTitleL1] = useState("")
  const [titleL2, setTitleL2] = useState("")
  const [descriptionL1, setDescriptionL1] = useState("")
  const [descriptionL2, setDescriptionL2] = useState("")
  const [level, setLevel] = useState(0)
  const [sortOrder, setSortOrder] = useState(0)
  const [topics, setTopics] = useState([])
  const [attributions, setAttributions] = useState([])
  const [content, setContent] = useState("")
  const [vocab, setVocab] = useState("")
  const [keyPhrases, setKeyPhrases] = useState("")
  const [license, setLicense] = useState(Licenses[0].id)

  // form state
  const [currentTab, setCurrentTab] = useState("info")
  const [saving, setSaving] = useState(false)
  const navigate = useNavigate()

  const lessonQuery = supabase.from("lessons").select().eq("random_slug", random_slug).single()
  const [lesson, lessonLoading, lessonError] = useSupabaseQuery(lessonQuery, [random_slug])

  const topicsQuery = supabase.from("topics").select()
  const [topicsList, topicsLoading, topicsError] = useSupabaseQuery(topicsQuery, [random_slug])

  // all this crap should go away when we start using a backend
  useEffect(() => {
    if (lesson) {
      setTitleL1(lesson.title_l1 || "")
      setTitleL2(lesson.title_l2 || "")
      setDescriptionL1(lesson.description_l1 || "")
      setDescriptionL2(lesson.description_l2 || "")
      setLevel(lesson.proficiency_level || 0)
      setSortOrder(lesson.sort_order || 0)
      setTopics(lesson?.topics || [])
      setAttributions(lesson?.attributions || [])
      setContent(lesson.content_l1 || "")
      setVocab(lesson.vocab_l1 || "")
      setKeyPhrases(lesson.key_phrases_l2 || "")
      setLicense(lesson?.license || Licenses[0].id)
    }
  }, [lesson])

  const submit = async event => {
    event.preventDefault()

    setSaving(true)
    if (!isAdmin && !lesson.created_by === userId) {
      throw new Error("You do not have permission to edit this lesson")
    }
    try {
      const { phrase_slugs_l2, phrase_slugs_l1, num_phrases } = await parsePhrasesFromLessonContent(
        content
      )
      const {
        phrase_slugs_l2: vocab_slugs_l2,
        phrase_slugs_l1: vocab_slugs_l1,
        num_phrases: vocab_num_phrases,
      } = await parsePhrasesFromLessonContent(vocab)

      const { error } = await supabase
        .from("lessons")
        .update({
          title_l1: titleL1,
          title_l2: titleL2,
          description_l1: descriptionL1,
          description_l2: descriptionL2,
          proficiency_level: level,
          sort_order: sortOrder,
          content_l1: content,
          vocab_l1: vocab,
          key_phrases_l2: keyPhrases,
          phrase_slugs_l2: [...phrase_slugs_l2, ...vocab_slugs_l2],
          phrase_slugs_l1: [...phrase_slugs_l1, ...vocab_slugs_l1],
          num_phrases: num_phrases + vocab_num_phrases,
          topics,
          attributions,
          license,
        })
        .eq("id", lesson.id)
      if (error) throw error

      navigate(`/${currentLanguage.slug_eng}/lessons/${random_slug}`)
    } catch (error) {
      logError("update lesson", error)
    }
    setSaving(false)
  }

  if (lessonLoading || topicsLoading) {
    return "Loading..."
  }

  if (!lesson || lessonError || topicsError) {
    return "Error - could not load lesson " + (topicsError?.message || lessonError?.message)
  }

  if (!userProfile?.has_agreed_to_editor_terms || !userProfile.username) return <EditorOnboarding />

  return (
    <>
      <BreadcrumbWrapper>
        <BreadcrumbItem to={`/${currentLanguage.slug_eng}`}>Home</BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem to={`/${currentLanguage.slug_eng}/lessons/${random_slug}`}>
          {lesson?.title_l1}
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        Edit
      </BreadcrumbWrapper>

      <h1>Edit lesson: {lesson.title_l1}</h1>
      <hr />

      <form onSubmit={submit}>
        <LessonThreeColumnLayout>
          <div>
            <StickySidebarWrapper>
              <ToggleGroup
                $vertical
                label="Lesson tab"
                items={[
                  { value: "info", label: "Info" },
                  { value: "content", label: "Content" },
                  { value: "vocab", label: "Vocab" },
                  { value: "preview", label: "Preview" },
                ]}
                value={currentTab}
                setValue={setCurrentTab}
              />
            </StickySidebarWrapper>
          </div>
          <div>
            {currentTab === "info" ? (
              <>
                {lesson && (isAdmin || lesson.createdBy === userId) && (
                  <>
                    <label htmlFor="titleL1">Title</label>
                    <input
                      id="titleL1"
                      type="text"
                      value={titleL1}
                      placeholder=""
                      onChange={e => setTitleL1(e.target.value)}
                      required
                    />

                    <label htmlFor="titleL2">{currentLanguage.name_eng} title (optional)</label>
                    <input
                      id="titleL2"
                      type="text"
                      value={titleL2}
                      placeholder=""
                      onChange={e => setTitleL2(e.target.value)}
                    />

                    <label htmlFor="descriptionL1">Description</label>
                    <ReactTextareaAutosize
                      id="descriptionL1"
                      type="text"
                      value={descriptionL1}
                      placeholder=""
                      onChange={e => setDescriptionL1(e.target.value)}
                      required
                    />

                    <label htmlFor="descriptionL2">
                      {currentLanguage.name_eng} description (optional)
                    </label>
                    <ReactTextareaAutosize
                      id="descriptionL2"
                      type="text"
                      value={descriptionL2}
                      placeholder=""
                      onChange={e => setDescriptionL2(e.target.value)}
                    />

                    <label htmlFor="keyPhrases">Key phrases (optional)</label>
                    <input
                      id="keyPhrases"
                      type="text"
                      value={keyPhrases}
                      placeholder=""
                      onChange={e => setKeyPhrases(e.target.value)}
                    />

                    <label htmlFor="sortOrder">Sort order</label>
                    <input
                      id="sortOrder"
                      type="number"
                      value={sortOrder}
                      placeholder="Sort order"
                      onChange={e => setSortOrder(e.target.value)}
                      required
                    />

                    <label htmlFor="level">Level</label>
                    <input
                      id="level"
                      type="number"
                      value={level}
                      min="0"
                      max="7"
                      placeholder="Level"
                      onChange={e => setLevel(e.target.value)}
                      required
                    />
                    <br />
                  </>
                )}

                <hr />

                <label>License</label>
                <select
                  value={license?.id}
                  onChange={e => setLicense(e.target.value)}
                  required
                  disabled
                >
                  {Licenses.map(license => {
                    return (
                      <option key={license.id} value={license.id}>
                        {license.name}
                      </option>
                    )
                  })}
                </select>

                <label>Attribution</label>
                {attributions?.map((alt, index) => {
                  return (
                    <div key={index} style={{ display: "flex" }}>
                      <ReactTextareaAutosize
                        style={{ height: "50px" }}
                        type="text"
                        value={alt}
                        minRows={1}
                        onChange={e => {
                          const newArray = [...attributions]
                          newArray[index] = e.target.value
                          setAttributions(newArray)
                        }}
                      />
                      {/* https://stackoverflow.com/a/47024021 */}
                      <button
                        type="button"
                        className="button button-tertiary"
                        onClick={() =>
                          setAttributions([
                            ...attributions.slice(0, index),
                            ...attributions.slice(index + 1),
                          ])
                        }
                      >
                        <Icon name="delete" />
                      </button>
                    </div>
                  )
                })}
                <button type="button" onClick={() => setAttributions([...attributions, ""])}>
                  Add attribution
                </button>

                <br />
                <br />

                <label>Topics</label>
                <CheckboxGroup
                  groupName="topics"
                  values={topics}
                  setValues={setTopics}
                  options={
                    !topicsList
                      ? []
                      : topicsList.map(topic => ({
                          id: topic.name_slug,
                          title: topic.title_eng,
                        }))
                  }
                />
              </>
            ) : currentTab === "content" ? (
              <>
                <EditTextarea
                  id="content"
                  type="text"
                  value={content}
                  placeholder=""
                  minRows={20}
                  onChange={e => setContent(e.target.value)}
                  required
                />
                <a href="/support#formatting-help" target="_blank">
                  Formatting help
                </a>
              </>
            ) : currentTab === "vocab" ? (
              <>
                <EditTextarea
                  id="vocab"
                  type="text"
                  value={vocab}
                  placeholder=""
                  minRows={20}
                  onChange={e => setVocab(e.target.value)}
                  required
                />
                <a href="/support#formatting-help" target="_blank">
                  Formatting help
                </a>
              </>
            ) : currentTab === "preview" ? (
              <>
                <div
                  style={{
                    padding: ".5rem 1rem",
                    background: "var(--badge)",
                    margin: "0 0 1rem",
                  }}
                >
                  This is how your lesson will look when published
                </div>
                <LessonContent content={content} />
                <br />
                <hr />
                <br />
                <h2 style={{ margin: 0 }}>Phrases</h2>
                <hr />
                <LessonPhraseList lesson={lesson} />
              </>
            ) : null}
          </div>
          <div>
            <StickySidebarWrapper>
              <button className="button button-full-width" type="submit" disabled={saving}>
                {saving ? "Saving..." : "Save lesson"}
              </button>
            </StickySidebarWrapper>
          </div>
        </LessonThreeColumnLayout>
      </form>
    </>
  )
}

const EditTextarea = styled(ReactTextareaAutosize)`
  font-family: monospace;
  width: 100%;
`
const StickySidebarWrapper = styled.div`
  position: sticky;
  top: 2rem;
  z-index: 200;
`
const LessonThreeColumnLayout = styled.div`
  display: grid;
  grid-template-columns: 200px auto 200px;
  grid-gap: 4rem;
  /* margin-bottom: 2rem; */
`
