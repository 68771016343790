// utils for testing whether spoken or written answers are correct

export const testAnswerString = ({answer, correctAnswers }) => {
  if (!correctAnswers) return false
  if (correctAnswers.length === 0) return false
  return correctAnswers.map(normalizeString).includes(normalizeString(answer))
}

export const testPartialAnswerString = ({answer, correctAnswers }) => {
  if (!correctAnswers) return false
  if (correctAnswers.length === 0) return false
  return !!correctAnswers.map(normalizeString).find(correctAnswer => {
    return correctAnswer.startsWith(normalizeString(answer))
  })
}

// removes accents
export const normalizeString = string => {
  if (!string) return ''
  if (typeof string !== 'string') return ''
  // https://stackoverflow.com/a/37511463/1061063
  const normalized = string.normalize("NFKD").replace(/[\u0300-\u036f]/g, "")
  return removePunctuation(normalized)
}

// doesn't remove accents
export const removePunctuation = string => {
  if (!string) return ''
  // https://stackoverflow.com/questions/4328500/how-can-i-strip-all-punctuation-from-a-string-in-javascript-using-regex
  return string.replace(/[.,#!?$%&;:{}="'\-_`~()\/\^\*]/g,"")
    .trim()
    .toLowerCase()
}

// https://en.wikipedia.org/wiki/Wikipedia:List_of_English_contractions
// TODO - move to content.ts
const equivalentsEng = [

  // contractions
  [` am`, `'m`],
  [` are`, `'re`],
  [` had`, `'d`],
  [` has`, `'s`],
  [` have`, `'ve`],
  [` is`, `'s`],
  [` not`, `n't`],
  [` will`, `'ll`],
  [` would`, `'d`],

  // numbers
  [`one`, `1`],
  [`two`, `2`],
  [`three`, `3`],
  [`four`, `4`],
  [`five`, `5`],
  [`six`, `6`],
  [`seven`, `7`],
  [`eight`, `8`],
  [`nine`, `9`],
  [`zero`, `0`],
  // TODO - all numbers (how?)

  // times of day
  [`midnight`, `12:00 am`],
  [`noon`, `12:00 pm`],
  [` o'clock`, `:00`],

  // pronouns
  [`he`, `she`],
  [`he`, `it`],
  [`she`, `it`],
  [`you all`, `you`],
  [`you all`, `y'all`],

  // math
  [`plus`, `+`],
  [`minus`, `-`],
  [`times`, `*`],
  [`divided by`, `/`],
  [`equals`, `=`],
]

const singleDirectionEng = [
  // allow verbs without the "to"
  [`to `, ` `],
]

// TODO - move to content.ts
const equivalentsIta = []
const singleDirectionIta = [
  [`il `, ` `],
  [`la `, ` `],
  [`lo `, ` `],
  [`i `, ` `],
  [`gli `, ` `],
  [`le `, ` `],
  [`un `, ` `],
  [`uno `, ` `],
  [`una `, ` `],
  [`un'`, ``],
  [`l'`, ``],
]

// TODO - a version that can handle multiple combinations of the same word
// e.g. `one plus one` => [`1 + one`, `1 plus one`, `one + 1`, `one plus 1`, `1 + 1`, `1 plus 1`]
export const getAltsWithContractions = (phrase, language) => {
  // TODO - add more languages

  if (!phrase) return []

  // these work both ways
  // for ex, he => she, she => he both work
  const equivalentPairs = (language.iso639_3 === 'ita') ? equivalentsIta : equivalentsEng
  const equivalentAlts = equivalentPairs.reduce((acc, [word, equivalent]) => {
    if (phrase.includes(word)) acc.push(phrase.replaceAll(word, equivalent))
    if (phrase.includes(equivalent)) acc.push(phrase.replaceAll(equivalent, word).trim())
    return acc
  }, [])

  // these only work one way
  // For ex we replace "to " with " " as in `to go` => `go`
  // but cannot replace " " with "to " everywhere
  const singleDirectionPairs = (language.iso639_3 === 'ita') ? singleDirectionIta : singleDirectionEng
  const singleDirectionAlts = singleDirectionPairs.reduce((acc, [word, equivalent]) => {
    if (phrase.includes(word)) acc.push(phrase.replaceAll(word, equivalent).trim())
    return acc
  }, [])
  return [phrase, ...equivalentAlts, ...singleDirectionAlts]
}
