import { Link } from "react-router-dom"
import styled from "styled-components/macro"

import { useUser } from "@/context/user"
import { supabase, useSupabaseQuery } from "@/db/supabase"
import Tooltip from "@/styles/Tooltip"
import { formatDate, getDaysArray } from "@/utils/date"

const getColor = attempts => {
  return attempts >= 1000
    ? "hsl(0, 0%, 0%)"
    : attempts >= 500
    ? "hsl(0, 0%, 20%)"
    : attempts >= 75
    ? "hsl(0, 0%, 40%)"
    : attempts >= 10
    ? "hsl(0, 0%, 60%)"
    : attempts >= 1
    ? "hsl(0, 0%, 80%)"
    : "hsl(0, 0%, 100%)"
}

export default () => {
  const { userId } = useUser()

  // https://stackoverflow.com/a/29052008/1061063
  const dateOneYearAgo = new Date(new Date().setFullYear(new Date().getFullYear() - 1))

  // TODO - offset for day of the week
  const daysArray = getDaysArray(dateOneYearAgo, new Date()).map(d => formatDate(d))

  // get activity data
  const query = supabase.from("heatmap_days").select().eq("created_by", userId)
  const [dataDays, loading, error] = useSupabaseQuery(query, [userId])

  // merge data with empty array
  const days = !userId
    ? daysArray
    : daysArray.map(ed => {
        const dataDay = dataDays?.find(dd => dd.date === ed.date)
        return {
          ...ed,
          attempts: dataDay?.count || 0,
        }
      })

  return (
    <div>
      <h4>Practice history</h4>
      {!userId ? (
        <>
          <Link to="/signup">Create an account</Link> to see your daily activity
        </>
      ) : error ? (
        error.message
      ) : loading ? (
        "loading..."
      ) : (
        <>
          <HeatmapWrapper>
            {!userId && (
              <HeatmapEmptyStateWrapper>
                <HeatmapEmptyStateMessage>
                  <Link to="/signup">Create an account</Link> to see your daily activity
                </HeatmapEmptyStateMessage>
              </HeatmapEmptyStateWrapper>
            )}
            {days.map(day => {
              const background = getColor(day.attempts)
              return (
                <Tooltip key={day.date} label={day.date}>
                  <HeatmapCell key={day.date} size={7} background={background} />
                </Tooltip>
              )
            })}
          </HeatmapWrapper>
        </>
      )}
    </div>
  )
}

const HeatmapWrapper = styled.div`
  position: relative;
  border: 1px solid;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(7, max-content);
  justify-content: start;
  width: fit-content;
  max-width: 90vw;
  overflow-x: auto;
`
const HeatmapCell = styled.div`
  width: ${p => (p.size ? `${p.size}px` : `5px`)};
  height: ${p => (p.size ? `${p.size}px` : `5px`)};
  border-style: solid;
  border-width: 0 1px 1px 0;
  background: ${p => p.background || "var(--bg)"};
`
const HeatmapEmptyStateWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: grid;
  align-content: center;
  justify-content: center;
`
const HeatmapEmptyStateMessage = styled.div`
  border: 1px solid;
  background: var(--bg);
  padding: 0.25rem 0.5rem;
`
